import { GamePool } from "./GamePool";

// GamePool with some helpful additional detail
export class GamePoolWithDetail extends GamePool {
    public numWagerTokens: number;
    public homeTokenSupply: number;
    public awayTokenSupply: number;
    public tieTokenSupply: number;
    public homeTokenDecimals: number;
    public awayTokenDecimals: number;
    public tieTokenDecimals: number;
    public homeTokenPrice: number;
    public awayTokenPrice: number;
    public tieTokenPrice: number;
    public userHomeTokenBalance: number;
    public userAwayTokenBalance: number;
    public userTieTokenBalance: number;
    public homeWagerTokenBalance: number;
    public awayWagerTokenBalance: number;
    public tieWagerTokenBalance: number;

    constructor(
        numWagerTokens: number = 0
        , homeTokenSupply: number = 0
        , awayTokenSupply: number = 0
        , tieTokenSupply: number = 0
        , homeTokenDecimals: number = 0
        , awayTokenDecimals: number = 0
        , tieTokenDecimals: number = 0
        , homeTokenPrice: number = 0
        , awayTokenPrice: number = 0
        , tieTokenPrice: number = 0
        , userHomeTokenBalance: number = 0
        , userAwayTokenBalance: number = 0
        , userTieTokenBalance: number = 0
        , homeWagerTokenBalance: number = 0
        , awayWagerTokenBalance: number = 0
        , tieWagerTokenBalance: number = 0
    ) {
        super();
        this.numWagerTokens = numWagerTokens;
        this.homeTokenSupply = homeTokenSupply;
        this.awayTokenSupply = awayTokenSupply;
        this.tieTokenSupply = tieTokenSupply;
        this.homeTokenDecimals = homeTokenDecimals;
        this.awayTokenDecimals = awayTokenDecimals;
        this.tieTokenDecimals = tieTokenDecimals;
        this.homeTokenPrice = homeTokenPrice;
        this.awayTokenPrice = awayTokenPrice;
        this.tieTokenPrice = tieTokenPrice;
        this.userHomeTokenBalance = userHomeTokenBalance;
        this.userAwayTokenBalance = userAwayTokenBalance;
        this.userTieTokenBalance = userTieTokenBalance;
        this.homeWagerTokenBalance = homeWagerTokenBalance;
        this.awayWagerTokenBalance = awayWagerTokenBalance;
        this.tieWagerTokenBalance = tieWagerTokenBalance;
    }
}