import React from 'react';
import images from '../static/images';
import { useHistory } from 'react-router';
import { IState } from '../store/interfaces/state';
import * as actions from '../store/actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { GamePoolWithDetail } from '../models/GamePoolWithDetail';
import { SwapObject } from '../models/SwapObject';
import { TokenObject } from '../models/TokenObject';
import { Outcome } from '../models/Outcome';
import { ADDRESS_TO_TOKEN_MAP, TOKEN_MAP } from '../constants';
import { getTeamImg } from '../utils/utils';

// shows each of your holdings
const MyPositionsPage = (props: any) => {

    const data: IState = useSelector((state: any) => state.data);
    const positions: any[] = [];
    const dispatch = useDispatch();
    const history = useHistory();
    const [ userName, setUserName ] = React.useState('');

	// get a list of the user's positions
    for (let i = 0; i < data.gamePools.length; i++) {
        const g: GamePoolWithDetail = data.gamePools[i];
		const payouts = data.userPayouts.filter(x => x.gameId == g.gameId)
        if (g.userAwayTokenBalance > 0) {
			const p = payouts.filter(x => x.teamToken == g.awayTeamToken);
            positions.push({
                'token': `${g.awayTeam}.${g.sport}.${g.week}`
                , 'sport': g.sport
                , 'wagerToken': ADDRESS_TO_TOKEN_MAP[g.wagerToken]
                , 'amount': g.userAwayTokenBalance
                , 'value': g.userAwayTokenBalance * ( g.awayTokenValue === null || g.awayTokenValue === undefined ? g.awayTokenPrice : g.awayTokenValue)
                , 'gamePool': g
                , 'outcome': Outcome.Away
                , 'team': g.awayTeam
                , 'balance': g.userAwayTokenBalance
                , 'teamName': g.awayTeamName
				, 'payoutAmount': p.length ? p[0].wagerTokenAmount : 0
				, 'payoutTx': p.length ? p[0].txId : ''
            })
        }
        if (g.userHomeTokenBalance > 0) {
			const p = payouts.filter(x => x.teamToken == g.homeTeamToken);
            positions.push({
                'token': `${g.homeTeam}.${g.sport}.${g.week}`
                , 'sport': g.sport
                , 'wagerToken': ADDRESS_TO_TOKEN_MAP[g.wagerToken]
                , 'amount': g.userHomeTokenBalance
                , 'value': g.userHomeTokenBalance * ( g.homeTokenValue === null || g.homeTokenValue === undefined ? g.homeTokenPrice : g.homeTokenValue)
                , 'gamePool': g
                , 'outcome': Outcome.Home
                , 'team': g.homeTeam
                , 'balance': g.userHomeTokenBalance
                , 'teamName': g.homeTeamName
				, 'payoutAmount': p.length ? p[0].wagerTokenAmount : 0
				, 'payoutTx': p.length ? p[0].txId : ''
            })
        }
        if (g.userTieTokenBalance > 0) {
			const p = payouts.filter(x => x.teamToken == g.tieTeamToken);
            positions.push({
                'token': `DRAW.${g.awayTeam}-${g.homeTeam}.${g.sport}.${g.week}`
                , 'sport': g.sport
                , 'wagerToken': ADDRESS_TO_TOKEN_MAP[g.wagerToken]
                , 'amount': g.userTieTokenBalance
                , 'value': g.userTieTokenBalance * ( g.tieTokenValue === null || g.tieTokenValue === undefined ? g.tieTokenPrice : g.tieTokenValue)
                , 'gamePool': g
                , 'outcome': Outcome.Tie
                , 'team': 'DRAW'
                , 'teamName': ''
                , 'balance': g.userTieTokenBalance
				, 'payoutAmount': p.length ? p[0].wagerTokenAmount : 0
				, 'payoutTx': p.length ? p[0].txId : ''
            })
        }
    }

	// create a list to display
    const positionList = positions.sort((a, b) => b.value - a.value).map( (a: any, i: number) => {
		const src = getTeamImg(a.sport, a.team)
        return(
            <tr key={i} className={`border-b border-th-bkg-3 bg-[rgba(255,255,255,0.03)] ${i % 2 ? 'odd' : 'even'}`}>
                <td className='px-4 py-3.5 whitespace-nowrap text-th-fgd-1'>
					<div className='row'>
						<div className='col token-img' style={{'display': 'inline-block'}}>
							<img src={ images[src] } alt='team-img' />
						</div>
						<div className='col' style={{'paddingLeft': '10px', 'display': 'inline-block'}}>
							{/* <div className='middle'> */}
								{/* <div className='inner'> */}
									{a.token}
								{/* </div> */}
							{/* </div> */}
						</div>
					</div>
                </td>
                {/* <td className='px-4 py-3.5 whitespace-nowrap text-th-fgd-1'>
					<div className='row'>
						<div className='col' style={{'paddingLeft': '10px', 'display': 'inline-block'}}>
							{a.teamName}
						</div>
					</div>
                </td> */}
                <td className='px-4 py-3.5 whitespace-nowrap text-th-fgd-1'>{a.amount.toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</td>
                <td className='px-4 py-3.5 whitespace-nowrap text-th-fgd-1'>
					<img style={{'maxHeight':'26px', 'maxWidth':'26px', 'marginRight':'8px', 'marginBottom':'4px'}} className='img-small' src={images[a.wagerToken]} />
					{a.value.toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})}
				</td>
                <td style={{'width':'100px'}} className='px-4 py-3.5 no-padding text-th-fgd-1'>
                    <button className='props-button sell-button' onClick={() => {
                        dispatch(actions.setSwapFromToken(a.token));
						const tokenName = a.token;
						const g = a.gamePool;
						const t = new TokenObject( tokenName, false, g, a.outcome, a.team, g.wagerToken, a.balance );
						const s = new SwapObject(g, a.outcome, false, [t], t);
						// const swapObject = new SwapObject();
                        dispatch(actions.setSwapObject(s));
                        history.push('/trade');
                    }}>
                        Sell
                    </button>
                </td>
                <td className='px-4 py-3.5 whitespace-nowrap text-th-fgd-1'>{a.payoutAmount ? a.payoutAmount.toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : '' }</td>
                <td className='px-4 py-3.5 whitespace-nowrap text-th-fgd-1'>
					{
						a.payoutTx ? 
						<a href={`https://solana.fm/tx/${a.txId}`} target='_'>
							{a.payoutTx.substring(0, 8)}...
						</a>
						: null
					}
				</td>
            </tr>
        )
    })

	// some good ol' fashioned rendering!
	
    return(
        <div className="my-positions-page">
			<div className='row padding-1-0'>
				{/* <div className='col-3'>
				</div>
				<div className='col-3'>
					<div className='row'>
						<div>
							<img src={images[`PBT`]}></img>
						</div>
						<div className='token-name'>Props Beta Token: {data.userPropsBalance.toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</div>
					</div>
				</div>
				<div className='col-3'>
					<div className='row'>
						<div>
							<img src={images[`PROPS`]}></img>
						</div>
						<div className='token-name'>Props Token: {data.userBonusBalance.toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</div>
					</div>
				</div>
				<div className='col-3'>
				</div> */}
			</div>
            <div className="my-positions-panel panel-outer">
				<div className="my-positions-panel-inner panel-inner">
					<div className='pb-4 text-th-fgd-1 text-lg'>
						My Positions
					</div>
					<table className='positions-table min-w-full divide-y divide-th-bkg-2'>
						<thead>
							<tr className='text-th-fgd-3'>
								<th className='px-4 pb-2 text-left font-normal'>Token</th>
								{/* <th className='px-4 pb-2 text-left font-normal'>Name</th> */}
								<th className='px-4 pb-2 text-left font-normal'>Amount</th>
								<th className='px-4 pb-2 text-left font-normal'>Value</th>
								<th className='px-4 no-padding pb-2 text-left font-normal'></th>
								<th className='px-4 pb-2 text-left font-normal'>Auto Payout Amt</th>
								<th className='px-4 pb-2 text-left font-normal'>Auto Payout Tx</th>
							</tr>
						</thead>
						<tbody>
							{ positionList }
						</tbody>
					</table>
				</div>
			</div>
        </div>
    )
};

export default MyPositionsPage;
