import { FEE_PCT, PRICE_MULT } from '../constants';
import { web3 } from '@project-serum/anchor';
import { account, util, WalletI } from 'easy-spl';
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { GamePool } from '../models/GamePool';

export const getCurrentTimestamp = () => {
	return( Date.now() / 1000);
}

export const getTeamImg = (sport: string, teamAbbr: string) => {
	const l = teamAbbr.length;
	const img = teamAbbr == 'DRAW' ? 'DRAW_TOKEN'
	: ['Y00ts-Reveal-No','Y00ts-Reveal-Yes'].includes( teamAbbr ) ? sport+'_'+teamAbbr.replaceAll('-', '_').replaceAll("'", '').replaceAll(' ', '').replaceAll(".", '_').toUpperCase()
	: teamAbbr.slice(l-2, l).toUpperCase() == 'NO' ? 'NO'
	: teamAbbr.slice(l-3, l).toUpperCase() == 'YES' ? 'YES'
	: teamAbbr.toUpperCase().includes('-UNDER-') ? 'UNDER'
	: teamAbbr.toUpperCase().includes('-OVER-') ? 'OVER'
	: teamAbbr.includes('-Minus') ? sport+'_'+teamAbbr.split('-Minus')[0]
	: teamAbbr.includes('-Plus') ? sport+'_'+teamAbbr.split('-Plus')[0]
	: sport+'_'+teamAbbr.replaceAll('-', '_').replaceAll("'", '').replaceAll(' ', '').replaceAll(".", '_').toUpperCase();
	return(img);
}

// create an associated token account
export const createAssociatedTokenAccountSendUnsigned = async (
    conn: web3.Connection,
    mint: web3.PublicKey,
    owner: web3.PublicKey,
    wallet: WalletI
) => {
    const address = await Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, mint, owner, true);
    if (await account.exists(conn, address)) {
        return address
    }
    const instruction = Token.createAssociatedTokenAccountInstruction(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        mint,
        address,
        owner,
        wallet.publicKey
    )
    const tx = await util.wrapInstructions(conn, [ instruction ], wallet.publicKey );
    const signed_tx = await wallet.signTransaction(tx);
    await util.sendAndConfirm(conn, signed_tx)
    return(address);
}

export function calcEsimatedPropsNeeded(mxFrom: number, mxTo: number, buySupply: number, otherSupply1: number, otherSupply2: number, buyProps: number, otherProps1: number, otherProps2: number, feePct: number) {
	let price_1 = buyProps / (buyProps + otherProps1);
    let buyVal = buySupply * price_1;
    let otherVal = otherSupply1 * (1 - price_1);
	let remProps = mxTo * price_1
    price_1 = (buyVal + remProps) / (buyVal + remProps + otherVal);
    buyVal = buySupply * price_1;
    otherVal = otherSupply1 * (1 - price_1);
	remProps = mxTo * price_1
    let price_2 = (buyVal + remProps) / (buyVal + remProps + otherVal);
	let props = mxTo * price_2 * (1 + FEE_PCT);
	let tokens = calcEsimatedAmount(props, buySupply, otherSupply1, 0, buyProps, otherProps1, 0, feePct)[0];
	for (let i = 0; i < 4; i++) {
		props = props * mxTo / tokens;
		tokens = calcEsimatedAmount(props, buySupply, otherSupply1, 0, buyProps, otherProps1, 0, feePct)[0];
	}
	props = props * mxTo / tokens;
	tokens = calcEsimatedAmount(props, buySupply, otherSupply1, 0, buyProps, otherProps1, 0, feePct)[0];
	if (props > mxFrom) {
		props *= mxFrom / props
		for (let i = 0; i < 4; i++) {
			tokens = calcEsimatedAmount(props, buySupply, otherSupply1, 0, buyProps, otherProps1, 0, feePct)[0];
			props = props * Math.min(1, mxFrom / props);
			// props = props * targetTokenAmount / tokens;
		}
		tokens = calcEsimatedAmount(props, buySupply, otherSupply1, 0, buyProps, otherProps1, 0, feePct)[0];
		props = props * Math.min(1, mxFrom / props);
	}
	props = Math.floor(props * 100000) / 100000;
	// console.log(`targetTokenAmount = ${mxFrom}`);
	// console.log(`props = ${props}`);
	// console.log(`mxTo = ${mxTo}`);
	// console.log(`tokens = ${tokens}`);
	
	
	return(props);

}

// for a given amount of props, how many team tokens do they get?
export function calcEsimatedAmount(propsAmount: number, buy_supply: number, other_supply_1: number, other_supply_2: number, buy_props: number, other_props_1: number, other_props_2: number, feePct: number) {

    const fees = propsAmount * feePct;
    const remProps = propsAmount - fees;

    const old_price = calcPriceNew(buy_supply, other_supply_1, other_supply_2, buy_props, other_props_1, other_props_2)
	// console.log(`old_price = ${old_price}`);

	const old_tokens = remProps / old_price;

	const new_buy_props = buy_props + remProps
	const new_buy_supply = buy_supply + old_tokens;
    const new_price = calcPriceNew(new_buy_supply, other_supply_1, other_supply_2, new_buy_props, other_props_1, other_props_2)

	const effectivePrice = (old_price + new_price) / 2.0;

    const adjNumTokens = remProps / effectivePrice;
	// console.log(`effectivePrice = ${effectivePrice}`);
	// console.log(`adjNumTokens = ${adjNumTokens}`);
	
    const slippage = (effectivePrice / old_price) - 1;
    return([adjNumTokens, fees, slippage, effectivePrice]);
}

export function calcEsimatedAmountOg(propsAmount: number, buySupply: number, otherSupply1: number, otherSupply2: number, buyProps: number, otherProps1: number, otherProps2: number, feePct: number) {
	console.log(`---calcEsimatedAmountOg---`);
	
    const fees = propsAmount * feePct;
    const remProps = propsAmount - fees;
	const otherSupply = otherSupply1 + otherSupply2;
	const otherProps = otherProps1 + otherProps2;

    const origPrice = calcPrice(buySupply, otherSupply, 0, buyProps, otherProps, 0)
	console.log(`origPrice = ${origPrice}`);

	// calculate raw price
    const price_1 = buyProps / (buyProps + otherProps);
    const buyVal = buySupply * price_1;
    const otherVal = otherSupply * (1 - price_1);
	// calculate new raw price
    const price_2 = (buyVal + remProps) / (buyVal + remProps + otherVal);
	console.log(`price_1 = ${price_1}`);
	console.log(`price_2 = ${price_2}`);

	// calculate buy amount based on new raw price
    const numBuyTokens = remProps / price_2;
    const newBuySupply = buySupply + numBuyTokens;
	console.log(`numBuyTokens = ${numBuyTokens}`);
	console.log(`newBuySupply = ${newBuySupply}`);
	console.log(`price_new_1 = ${1 - price_2}`);

	// some crazy calculations
    const pct_1 = 1.0 / (newBuySupply * price_2);
    const pct_2 = 1.0 / (otherSupply * (1 - price_2));
    const ratio_1 = pct_1 / (pct_1 + pct_2);
    const effectivePrice = 1 - ratio_1;

    const adjNumTokens = remProps / effectivePrice;
    const slippage = (effectivePrice / origPrice) - 1;

	console.log(`calcEsimatedAmountOg adjNumTokens = ${adjNumTokens}`);
	
	
    return([adjNumTokens, fees, slippage, effectivePrice]);
}

// helper function to calculate the price of a token
export function calcRatio(buySupply: number, otherSupply: number, buyProps: number, otherProps: number) {
    const price_1 = buyProps / (buyProps + otherProps);
    const buyVal = buySupply * price_1;
    const otherVal = otherSupply * (1 - price_1);
    return(buyVal / (buyVal + otherVal));
}

// calculate the price of a token
export function calcPrice(buySupply: number, otherSupply1: number, otherSupply2: number, buyProps: number, otherProps1: number, otherProps2: number) {
	if (otherProps1 + otherProps2 == 0) {
		return(1)
	}
	if (buyProps == 0) {
		return(0)
	}
	if ((otherSupply2 + otherProps2) && (otherSupply1 + otherProps1)) {
		return( PRICE_MULT * calcPriceTie(buySupply, otherSupply1, otherSupply2, buyProps, otherProps1, otherProps2));
	}
	otherSupply1 = Math.max(otherSupply1, otherSupply2);
	otherProps1 = Math.max(otherProps1, otherProps2);
    const price = calcRatio(buySupply, otherSupply1, buyProps, otherProps1);
    const pct_a = 1 / (buySupply * price);
    const pct_b = 1 / (otherSupply1 * (1 - price));
    const odds = pct_b / (pct_a + pct_b);
    return(PRICE_MULT * odds);
}

export function calcPriceNew(buy_supply: number, other_supply_1: number, other_supply_2: number, buy_depth: number, other_depth_1: number, other_depth_2: number) {
	// initial raw price
	const tot_wager_tokens = buy_depth + other_depth_1 + other_depth_2;
    const raw_price_0 = buy_depth / tot_wager_tokens;
    const raw_price_1 = other_depth_1 / tot_wager_tokens;
    const raw_price_2 = other_depth_2 / tot_wager_tokens;

	// initial value
	const buy_val = buy_supply * raw_price_0;
    const other_val_1 = other_supply_1 * raw_price_1;
    const other_val_2 = other_supply_2 * raw_price_2;
    const other_val = other_val_1 + other_val_2;

	// new price with no supply change
	const tot_val = buy_val + other_val;
	const price_0 = buy_val / tot_val;
    const price_1 = other_val_1 / tot_val;
    const price_2 = other_val_2 / tot_val;

	// calculate amounts
	const price = price_0 / (price_0 + price_1 + price_2);
    return(price);
}

// helper function to calculate the price of a token
export function calcRatioTie(buySupply: number, otherSupply1: number, otherSupply2: number, buyProps: number, otherProps1: number, otherProps2: number) {
    const price_0 = buyProps / (buyProps + otherProps1 + otherProps2);
    const price_1 = otherProps1 / (buyProps + otherProps1 + otherProps2);
    const price_2 = otherProps2 / (buyProps + otherProps1 + otherProps2);
    const buyVal = buySupply * price_0;
    const otherVal = (otherSupply1 * price_1) + (otherSupply2 * price_2);
    return(buyVal / (buyVal + otherVal));
}

// calculate the price of a token
export function calcPriceTie(buySupply: number, otherSupply1: number, otherSupply2: number, buyProps: number, otherProps1: number, otherProps2: number) {
	if (otherProps1 + otherProps2 == 0) {
		return(1)
	}
	if (buyProps == 0) {
		return(0)
	}

    const price_0 = buyProps / (buyProps + otherProps1 + otherProps2);
    const price_1 = otherProps1 / (buyProps + otherProps1 + otherProps2);
    const price_2 = otherProps2 / (buyProps + otherProps1 + otherProps2);
    const buyVal = buySupply * price_0;
	
    // const price = calcRatioTie(buySupply, otherSupply1, otherSupply2, buyProps, otherProps1, otherProps2);
    const pct_a = 1 / (buySupply * price_0);
    const pct_b = 1 / ((otherSupply1 * price_1) + (otherSupply2 * price_2));
    const odds = pct_b / (pct_a + pct_b);
    return(odds);
}

// calculate the implied win probability of a team
export function calcOdds(buySupply: number, otherSupply1: number, otherSupply2: number, buyProps: number, otherProps1: number, otherProps2: number) {
    const usd = buyProps + otherProps1 + otherProps2;
    const buyWinAmt = usd / buySupply;
    const buyPrice = calcPrice(buySupply, otherSupply1, otherSupply2, buyProps, otherProps1, otherProps2);
    const buyImpliedOdds = buyPrice / buyWinAmt;
    return(buyImpliedOdds);
}


// for a given amount of team tokens, how many props do they get?
export function calcEsimatedPROPSAmount(amount: number, buy_supply: number, other_supply_1: number, other_supply_2: number, buy_props: number, other_props_1: number, other_props_2: number, numBonus: number, feePct: number) {
	if (!amount) {
		return([0, 0, 0, 0]);
	}
	// console.log(`calcEsimatedPROPSAmount`);
    const fees = amount * feePct;
    const rem = amount - fees;

    let old_price = calcPriceNew(buy_supply, other_supply_1, other_supply_2, buy_props, other_props_1, other_props_2);

	const old_tokens = rem * old_price;


	// initial raw price
	const new_buy_props = buy_props - old_tokens
	const new_buy_supply = buy_supply - amount;
    const new_price = calcPriceNew(new_buy_supply, other_supply_1, other_supply_2, new_buy_props, other_props_1, other_props_2)	

    const effectivePrice = (new_price + old_price) / 2.0;

	const estAmount = rem * effectivePrice;
    // let price2 = calcPrice(buy_supply - estAmount, other_supply_1, other_supply_2, buy_props - amount, other_props_1, other_props_2);
    let slippage = (old_price / effectivePrice) - 1;
    const price = amount / estAmount;
	const estBonus = other_props_1 > 0 ? 0 : numBonus * amount / buy_supply;

	// console.log(`estBonus = ${estBonus}. buyProps = ${buyProps}. otherProps = ${otherProps}. numBonus = ${numBonus}. amount = ${amount}. buySupply = ${buySupply}. `);
	
	// const estBonus = 0;
	// console.log({
	// 	'price':price
	// 	, 'buySupply':buySupply
	// 	, 'otherSupply':otherSupply
	// 	, 'buyProps':buyProps
	// 	, 'otherProps':otherProps
	// 	, 'numBonus':numBonus
	// 	, 'estAmount':estAmount
	// 	, 'price2':price2
	// 	, 'estBonus':estBonus
	// 	, 'amount':amount
	// });
    return([estAmount, fees * price, slippage, price, estBonus]);
}

// simple helper function to create a token name
export function convertToTokenName(team: string, week: string, sport: string) {
    return(`${team}.${sport}.${week}`);
}

// simple helper function to create a token name
export function getOpposingToken(team: string, week: string, sport: string, gamePools: GamePool[]) {
	const games = gamePools.filter( x => x.week == week && x.sport == sport && (x.homeTeam == team || x.awayTeam == team) )
	if (games.length == 0) {
		return '';
	}
	const g = games[0];
	const otherTeam = g.homeTeam == team ? g.awayTeam : g.homeTeam;
    return(`${otherTeam}.${sport}.${week}`);
}

// convert from unix timestamp to pretty date
export function timeConverter(timestamp: number){
    const a = new Date(timestamp * 1000);
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    const month = months[a.getMonth()];
    const date = a.getDate();
    const hour = a.getHours();
    const displayHour = ((hour + 11) % 12) + 1
    const m = a.getMinutes();
    const min = m > 0 && m < 10 ? `0${m.toString()}` : m.toString().padEnd(2, '0');
    const ampm = hour >= 12 ? 'pm' : 'am';
    const day = a.getDay()
    // const days = [ 'Sun','Mon','Tue','Wed','Thu','Fri','Sat' ]
    const days = [ 'Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday' ]
    const time = days[day] + ', ' + ordinal_suffix_of(date) + ' ' + month + ' '  + displayHour + ':' + min + ampm;
    return time;
}

// convert from unix timestamp to pretty date
export function timeConverterShort(timestamp: number){
    const a = new Date(timestamp * 1000);
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    const month = months[a.getMonth()];
    const date = a.getDate();
    const hour = a.getHours();
    const displayHour = ((hour + 11) % 12) + 1
    const m = a.getMinutes();
    const min = m > 0 && m < 10 ? `0${m.toString()}` : m.toString().padEnd(2, '0');
    const ampm = hour >= 12 ? 'pm' : 'am';
    const day = a.getDay()
    // const days = [ 'Sun','Mon','Tue','Wed','Thu','Fri','Sat' ]
    const days = [ 'Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday' ]
    const time = (a.getMonth() + 1).toString() + '/' + (a.getDate()).toString() + ' ' + displayHour + ':' + min + ampm;
    return time;
}

// add suffix to end of a number
export function ordinal_suffix_of(i: number) {
    const j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + 'st';
    }
    if (j === 2 && k !== 12) {
        return i + 'nd';
    }
    if (j === 3 && k !== 13) {
        return i + 'rd';
    }
    return i + 'th';
}
