import { combineReducers } from "redux";
import { SwapObject } from "../../models/SwapObject";
import { Trade } from "../../models/Trade";
import { IState } from "../interfaces/state";
import { SET_ADDRESS, SET_GAME_POOLS, SET_LEADERBOARD, SET_SWAP_FROM_TOKEN, SET_SWAP_TO_TOKEN, SET_USER_TOKENS, SET_USER_PROPS_BALANCE, SET_USER_BONUS_BALANCE, SET_USER, SET_SPORT, SET_SWAP_OBJECT, SET_ODDS, SET_TRADES, SET_USER_TRADES, SET_LOADING_POOLS, SET_USER_PAYOUTS } from "../types/types";

export const INITIAL_STATE: IState = {
    address: null,
    user: null,
    swapFromToken: '',
    sport: 'ALL',
    swapToToken: '',
    leaderboard: [],
    userTokens: [],
    userPropsBalance: 0,
    userBonusBalance: 0,
    gamePools: [],
    swapObject: null,
    odds: 'Percentage',
    trades: [],
    userTrades: [],
    userPayouts: [],
    loading: true,
};

const reducer = (state: IState = INITIAL_STATE, action: any): IState => {
    // console.log(`action = ${action.type}`);
    // console.log(action.data);
    
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.data
            }
        case SET_ADDRESS:
            return {
                ...state,
                address: action.data
            }
        case SET_SPORT:
            return {
                ...state,
                sport: action.data
            }
        case SET_SWAP_FROM_TOKEN:
            return {
                ...state,
                swapFromToken: action.data
            }
        case SET_SWAP_OBJECT:
            return {
                ...state,
                swapObject: action.data
            }
        case SET_SWAP_TO_TOKEN:
            return {
                ...state,
                swapToToken: action.data
            }
        case SET_GAME_POOLS:
            return {
                ...state,
                gamePools: action.data
            }
        case SET_USER_PROPS_BALANCE:
            return {
                ...state,
                userPropsBalance: action.data
            }
        case SET_USER_BONUS_BALANCE:
            return {
                ...state,
                userBonusBalance: action.data
            }
        case SET_LEADERBOARD:
            return {
                ...state,
                leaderboard: action.data
            }
        case SET_USER_TOKENS:
            return {
                ...state,
                userTokens: action.data
            }
        case SET_USER_TRADES:
            return {
                ...state,
                userTrades: action.data
            }
        case SET_USER_PAYOUTS:
            return {
                ...state,
                userPayouts: action.data
            }
        case SET_ODDS:
            return {
                ...state,
                odds: action.data
            }
        case SET_TRADES:
            return {
                ...state,
                trades: action.data
            }
        case SET_LOADING_POOLS:
            return {
                ...state,
                loading: action.data
            }
        default: return state;
    }
}

const rootReducer = combineReducers({
    data: reducer,
})
export default rootReducer;