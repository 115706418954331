import './App.css';
import './wallet.css';
import axios from 'axios';
import { useEffect } from 'react';
import { Routes } from './routes';
import { Mint, Wallet } from 'easy-spl';
import { calcEsimatedAmount, calcEsimatedPROPSAmount, calcPrice, calcPriceNew, getCurrentTimestamp } from './utils/utils';
// @ts-ignore
import * as BufferLayout from 'buffer-layout';
import 'react-toastify/dist/ReactToastify.css';
import { IState } from './store/interfaces/state';
import * as actions from './store/actions/actions';
import { BN, Provider } from '@project-serum/anchor';
import { useDispatch, useSelector } from 'react-redux';
import { ADMIN_ADDRESSES, BACKEND_URL, DECIMALS, isDev, NETWORK, PBT_MINT, PROPS_MINT, SOL_ADDRESS, USDH_MINT } from './constants';
import { getPhantomWallet } from '@solana/wallet-adapter-wallets';
import { GamePoolWithDetail } from './models/GamePoolWithDetail';
import { AccountInfo, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl, Connection, ConnectionConfig, LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import { getMultipleAccounts } from '@project-serum/anchor/dist/cjs/utils/rpc';
import { useWallet, WalletProvider, ConnectionProvider } from '@solana/wallet-adapter-react';
import { User } from './models/User';
import ReactGA from 'react-ga4';


const wallets = [ getPhantomWallet() ]
ReactGA.initialize("G-98X049Q8L7");
ReactGA.send("pageview");

const opts: any = {
	preflightCommitment: 'committed'
};

export type AccountInfoPartial = Pick<
	AccountInfo,
	'address' | 'amount' | 'mint' | 'owner'
>;

function parseTokenAccountData(data: Buffer): AccountInfoPartial {
	// @ts-ignore
	let { mint, owner, amount } = ACCOUNT_LAYOUT.decode(data);
	// @ts-ignore
	return {
		mint: new PublicKey(mint),
		owner: new PublicKey(owner),
		amount: new BN(amount),
	};
}


function parsePoolAccountData(data: Buffer) {
	// @ts-ignore
	// console.log(`parsePoolAccountData`);
	try {
		let {
			pool_owner
			, home_team_token
			, away_team_token
			, wager_token
			, home_pool_team_token_account
			, away_pool_team_token_account
			, protocol_home_team_token_account
			, protocol_away_team_token_account
			, home_pool_wager_token_account
			, away_pool_wager_token_account
			, protocol_wager_token_account
			, fee_receiver_wager_token_account
			, fee_rate
			, game_start_ts
			, pool_wager_token_cap
			, user_team_token_cap
			, is_game_completed
			, game_completed
			, home_nonce
			, away_nonce
		} = POOL_ACCOUNT_LAYOUT.decode(data);
		// console.log(`pool_owner`);
		// console.log(new PublicKey(pool_owner).toString());
		// console.log(new PublicKey(home_team_token).toString());
		// console.log(new PublicKey(away_team_token).toString());
		// console.log(new PublicKey(wager_token).toString());
		// console.log(new PublicKey(wager_token).toString());
		
		return {
			pool_owner: new PublicKey(pool_owner).toString(),
			home_team_token: new PublicKey(home_team_token).toString(),
			away_team_token: new PublicKey(away_team_token).toString(),
			wager_token: new PublicKey(wager_token).toString(),
			home_pool_team_token_account: new PublicKey(home_pool_team_token_account).toString(),
			away_pool_team_token_account: new PublicKey(away_pool_team_token_account).toString(),
			protocol_home_team_token_account: new PublicKey(protocol_home_team_token_account).toString(),
			protocol_away_team_token_account: new PublicKey(protocol_away_team_token_account).toString(),
			home_pool_wager_token_account: new PublicKey(home_pool_wager_token_account).toString(),
			away_pool_wager_token_account: new PublicKey(away_pool_wager_token_account).toString(),
			protocol_wager_token_account: new PublicKey(protocol_wager_token_account).toString(),
			fee_receiver_wager_token_account: new PublicKey(fee_receiver_wager_token_account).toString(),
			// fee_rate: new BN(fee_rate).toNumber(),
			// game_start_ts: game_start_ts,
			game_start_ts: new BN(game_start_ts).toNumber(),
			// pool_wager_token_cap: new BN(pool_wager_token_cap).toNumber(),
			// user_team_token_cap: new BN(user_team_token_cap).toNumber(),
			// is_game_completed: new BN(is_game_completed).toNumber(),
			// game_completed: new BN(game_completed).toNumber(),
			home_nonce: new BN(home_nonce).toNumber(),
			away_nonce: new BN(away_nonce).toNumber(),
		}
	}
	catch {
		let {
			pool_owner
			, home_team_token
			, away_team_token
			, tie_team_token
			, wager_token
			, home_pool_team_token_account
			, away_pool_team_token_account
			, tie_pool_team_token_account
			, protocol_home_team_token_account
			, protocol_away_team_token_account
			, protocol_tie_team_token_account
			, home_pool_wager_token_account
			, away_pool_wager_token_account
			, tie_pool_wager_token_account
			, protocol_wager_token_account
			, fee_receiver_wager_token_account
			, fee_rate
			, game_start_ts
			, pool_wager_token_cap
			, user_team_token_cap
			, is_game_completed
			, game_completed
			, home_nonce
			, away_nonce
			, tie_nonce
		} = TIE_POOL_ACCOUNT_LAYOUT.decode(data);
		// console.log(`pool_owner`);
		// console.log(new PublicKey(pool_owner).toString());
		// console.log(new PublicKey(home_team_token).toString());
		// console.log(new PublicKey(away_team_token).toString());
		// console.log(new PublicKey(wager_token).toString());
		// console.log(new PublicKey(wager_token).toString());
		
		return {
			pool_owner: new PublicKey(pool_owner).toString(),
			home_team_token: new PublicKey(home_team_token).toString(),
			away_team_token: new PublicKey(away_team_token).toString(),
			wager_token: new PublicKey(wager_token).toString(),
			home_pool_team_token_account: new PublicKey(home_pool_team_token_account).toString(),
			away_pool_team_token_account: new PublicKey(away_pool_team_token_account).toString(),
			protocol_home_team_token_account: new PublicKey(protocol_home_team_token_account).toString(),
			protocol_away_team_token_account: new PublicKey(protocol_away_team_token_account).toString(),
			home_pool_wager_token_account: new PublicKey(home_pool_wager_token_account).toString(),
			away_pool_wager_token_account: new PublicKey(away_pool_wager_token_account).toString(),
			protocol_wager_token_account: new PublicKey(protocol_wager_token_account).toString(),
			fee_receiver_wager_token_account: new PublicKey(fee_receiver_wager_token_account).toString(),
			// fee_rate: new BN(fee_rate).toNumber(),
			// game_start_ts: game_start_ts,
			game_start_ts: new BN(game_start_ts).toNumber(),
			// pool_wager_token_cap: new BN(pool_wager_token_cap).toNumber(),
			// user_team_token_cap: new BN(user_team_token_cap).toNumber(),
			// is_game_completed: new BN(is_game_completed).toNumber(),
			// game_completed: new BN(game_completed).toNumber(),
			home_nonce: new BN(home_nonce).toNumber(),
			away_nonce: new BN(away_nonce).toNumber(),

		}
	}
}

const ACCOUNT_LAYOUT = BufferLayout.struct([
	BufferLayout.blob(32, 'mint'),
	BufferLayout.blob(32, 'owner'),
	BufferLayout.nu64('amount'),
	BufferLayout.blob(93),
]);

const POOL_ACCOUNT_LAYOUT = BufferLayout.struct([
	BufferLayout.blob(8),
	BufferLayout.blob(32, 'pool_owner'),
	BufferLayout.blob(32, 'home_team_token'),
	BufferLayout.blob(32, 'away_team_token'),
	BufferLayout.blob(32, 'wager_token'),
	BufferLayout.blob(32, 'home_pool_team_token_account'),
	BufferLayout.blob(32, 'away_pool_team_token_account'),
	BufferLayout.blob(32, 'protocol_home_team_token_account'),
	BufferLayout.blob(32, 'protocol_away_team_token_account'),
	BufferLayout.blob(32, 'home_pool_wager_token_account'),
	BufferLayout.blob(32, 'away_pool_wager_token_account'),
	BufferLayout.blob(32, 'protocol_wager_token_account'),
	BufferLayout.blob(32, 'fee_receiver_wager_token_account'),
	BufferLayout.nu64('fee_rate'),
	BufferLayout.nu64('game_start_ts'),
	BufferLayout.nu64('pool_wager_token_cap'),
	BufferLayout.nu64('user_team_token_cap'),
	BufferLayout.blob(8, 'is_game_completed'),
	BufferLayout.blob(8, 'game_completed'),
	BufferLayout.u8('home_nonce'),
	BufferLayout.u8('away_nonce'),
]);

const TIE_POOL_ACCOUNT_LAYOUT = BufferLayout.struct([
	BufferLayout.blob(8),
	BufferLayout.blob(32, 'pool_owner'),
	BufferLayout.blob(32, 'home_team_token'),
	BufferLayout.blob(32, 'away_team_token'),
	BufferLayout.blob(32, 'tie_team_token'),
	BufferLayout.blob(32, 'wager_token'),
	BufferLayout.blob(32, 'home_pool_team_token_account'),
	BufferLayout.blob(32, 'away_pool_team_token_account'),
	BufferLayout.blob(32, 'tie_pool_team_token_account'),
	BufferLayout.blob(32, 'protocol_home_team_token_account'),
	BufferLayout.blob(32, 'protocol_away_team_token_account'),
	BufferLayout.blob(32, 'protocol_tie_team_token_account'),
	BufferLayout.blob(32, 'home_pool_wager_token_account'),
	BufferLayout.blob(32, 'away_pool_wager_token_account'),
	BufferLayout.blob(32, 'tie_pool_wager_token_account'),
	BufferLayout.blob(32, 'protocol_wager_token_account'),
	BufferLayout.blob(32, 'fee_receiver_wager_token_account'),
	BufferLayout.nu64('fee_rate'),
	BufferLayout.nu64('game_start_ts'),
	BufferLayout.nu64('pool_wager_token_cap'),
	BufferLayout.nu64('user_team_token_cap'),
	BufferLayout.blob(8, 'is_game_completed'),
	BufferLayout.blob(8, 'game_completed'),
	BufferLayout.u8('home_nonce'),
	BufferLayout.u8('away_nonce'),
	BufferLayout.u8('tie_nonce'),
]);

const MINT_LAYOUT = BufferLayout.struct([
	BufferLayout.blob(36),
	BufferLayout.blob(8, 'supply'),
	BufferLayout.u8('decimals'),
	BufferLayout.u8('initialized'),
	BufferLayout.blob(36),
]);

interface MintInfo {
	decimals: number;
	initialized: boolean;
	supply: BN;
  }

function parseTokenMintData(data: any): MintInfo {
	let { decimals, initialized, supply } = MINT_LAYOUT.decode(data);
	return {
		decimals,
		initialized: !!initialized,
		supply: new BN(supply, 10, 'le'),
	};
}

function getOwnedAccountsFilters(publicKey: PublicKey) {
	return [
		{
			memcmp: {
				// @ts-ignore
				offset: ACCOUNT_LAYOUT.offsetOf('owner'),
				bytes: publicKey.toBase58(),
			},
		},
		{
			dataSize: ACCOUNT_LAYOUT.span,
		},
	];
}

const config: ConnectionConfig = {
	/** Optional commitment level */
	commitment: 'confirmed',
	/** Optional Disable retring calls when server responds with HTTP 429 (Too Many Requests) */
	disableRetryOnRateLimit: false,
	/** time to allow for the server to initially process a transaction (in milliseconds) */
	confirmTransactionInitialTimeout: 150000
};

// @ts-ignore
const App = () => {
	
	const dispatch = useDispatch();

	useEffect(() => {
		ReactGA.send("pageview");
	  }, []);
	// const amt = calcEsimatedAmount(1, 300, 300, 300, 60, 90, 150, 0);
	// const amt2 = calcEsimatedPROPSAmount(amt[0], 300 + amt[0], 300, 300, 60 + 1, 90, 150, 0, 0);
	// console.log(`amt = ${amt}. amt2 = ${amt2}`);
	


	const walletContext: any = useWallet();
	
	const connection = new Connection(NETWORK, config);

	const provider = new Provider(connection, walletContext, config);
	const userAccount: Wallet = new Wallet(connection, provider.wallet);
	const address = userAccount.publicKey;
	const data: IState = useSelector((state: any) => state.data);

	const loadUser = async (address: string) => {
		if(!address) {
			dispatch(actions.setUser(null));
		}
		let response = await axios({
			method: 'post',
			url: BACKEND_URL+'/api/user/getUser',
			data: {'address': address}
		});
		let user = response.data;
		if (!user) {
			user = new User();
		}
		dispatch(actions.setUser(user));
	}

	const loadUserPayouts = async (address: string) => {
		console.log(`loadUserPayouts = ${address}`);
		if(!address) {
			dispatch(actions.setUserPayouts([]));
		} else {
			let response = await axios({
				method: 'post',
				url: BACKEND_URL+'/api/user/getUserPayouts',
				data: {'address': address}
			});
			let data = response.data;
			console.log(`response`);
			console.log(data);
			dispatch(actions.setUserPayouts(data));
		}
	}

	const loadRecentTrades = async () => {
		let response = await axios({
			method: 'get',
			url: BACKEND_URL+'/api/trade/recent'
		});
		let trades = response.data;
		console.log(`loadRecentTrades`);
		console.log(trades);
		dispatch(actions.setTrades(trades));
	}

	const loadPools = async (address: string) => {
		// console.log(`loadPools`);
		const t0 = new Date().getTime() / 1000.0
		
		const connection = new Connection(NETWORK, config);

		let pools = await axios({
			method: 'get',
			url: BACKEND_URL+'/api/gamePool',
		});
		let gamePools: GamePoolWithDetail[] = pools.data;
		// gamePools = gamePools.filter(x => x.gameId == '08-25-22-GNB-at-KAN');
		// console.log(`gamePools`);
		// console.log(gamePools);
		
		// let gamePools: GamePoolWithDetail[] = pools.data.filter(x => x.);
		
		gamePools = gamePools.filter(x => x.hidden === false || isDev || ADMIN_ADDRESSES.includes(address) );
		gamePools = gamePools.filter(x => x.gameStartTime > 1657843200);
		gamePools = gamePools.filter(x => x.gameStartTime > (new Date().getTime() / 1000.0) - (60 * 60 * 24 * 21));
		

		// setPools(initPools);

		let tokens: PublicKey[] = []
		const propsAccounts: PublicKey[] = []
		const poolAccounts: PublicKey[] = []
		for (let i = 0; i < gamePools.length; i++) {
			const p: GamePoolWithDetail = gamePools[i];
			tokens.push( new PublicKey(p.homeTeamToken) );
			tokens.push( new PublicKey(p.awayTeamToken) );
			// if ( ['9z8wUzUJ6N3MUwW6Q5HiFZhR53iF1EZwoRu4aALBCgWL','9SFyB8AWkXjR5YDps3fwPkMBr3koHoU5ovTxUYeBoGm'].includes( p.poolAccount)) {
				poolAccounts.push( new PublicKey(p.poolAccount) );
			// }
			if (p.tieTeamToken) tokens.push( new PublicKey(p.tieTeamToken) );
			// bonusAccounts.push( new PublicKey(p.poolBonusAccount) );
			if (p.wagerToken == SOL_ADDRESS.toString()) {
				propsAccounts.push( new PublicKey(p.homePoolAccount) );
				propsAccounts.push( new PublicKey(p.awayPoolAccount) );
				if (p.tieTeamToken) propsAccounts.push( new PublicKey(p.tiePoolAccount) );
			} else {
				propsAccounts.push( new PublicKey(p.homePoolWagerTokenAccount) );
				propsAccounts.push( new PublicKey(p.awayPoolWagerTokenAccount) );
				if (p.tieTeamToken) propsAccounts.push( new PublicKey(p.tiePoolWagerTokenAccount) );
			}
		}
		// console.log(`poolAccounts`);
		// console.log(poolAccounts);
		
		// if (isDev) {
		// 	const l = Math.max(0, tokens.length - 10);
		// 	tokens = tokens.slice(l);
		// }
		// console.log(`propsAccounts`);
		// console.log(propsAccounts.map(x => x.toString()));
		// console.log(`propsAccounts`);
		// console.log(propsAccounts);
		// console.log(`178: ${(new Date().getTime() / 1000.0) - t0} seconds`);
		

		/****************************/
		/*     Get Token Supply     */
		/****************************/
		
		const tokenInfos = await getMultipleAccounts(connection, tokens);
		const propsAccountInfos = await getMultipleAccounts(connection, propsAccounts);
		const poolAccountInfos = await getMultipleAccounts(connection, poolAccounts);
		// console.log(`poolAccountInfos`);
		// console.log(poolAccountInfos);
		

		const propsAccountsParsed: any[] = propsAccountInfos.filter(x => x !== null).map((x: any) => {
			try {
				// console.log(`x`);
				// console.log(x);
				try {
					const a = parseTokenAccountData(x.account.data);
					// console.log(`a:`);
					// console.log(a);
					
					if (a.owner) {
						const mint = a.mint;
						// const decimals = await new Mint(connection, mint).getDecimals();
						const decimals = mint.toString() == USDH_MINT.toString() ? 6 : DECIMALS;
						return({
							'address': x.publicKey.toString()
							// @ts-ignore
							, 'amount': a.amount.toNumber() / Math.pow(10, decimals)
							, 'owner': a.owner.toString()
						})
					}
				} catch {}
				return({
					'address': x.publicKey.toString()
					, 'amount': x.account.lamports / Math.pow(10, DECIMALS)
					, 'owner': x.publicKey.toString()
				})
			} catch (err) {
				console.log(`Error`);
				console.log(err);				
			}
		}).filter( x => x !== undefined);
		// console.log(`propsAccountsParsed`);
		// console.log(propsAccountsParsed);

		const poolAccountsParsed: any[] = poolAccountInfos.filter(x => x !== null).map((x: any) => {
			try {
				// console.log(`x`);
				// console.log(x);
				try {
					const a = parsePoolAccountData(x.account.data);
					return(a);
				} catch (err) {
					console.log(`Error`);
					console.log(err);					
				}
				return(x);
			} catch (err) {
				console.log(`Error`);
				console.log(err);				
			}
		}).filter( x => x !== undefined);
		console.log(`poolAccountsParsed`);
		console.log(poolAccountsParsed);
		
		
		// const tokenInfosParsed: any[] = [];
		const tokenInfosParsed: any[] = tokenInfos.filter(x => x !== null).map((x: any, i: number) => {
			const a = parseTokenMintData(x.account.data);
			return({
				'address': x.publicKey.toString()
				, 'decimals': a.decimals
				, 'supply': a.supply.toNumber() / Math.pow(10, a.decimals)
			})
		}).filter( x => x !== undefined);
		// console.log(`tokenInfosParsed`);
		// console.log(tokenInfosParsed);
		// console.log(`App.tsx gamePools`);
		// console.log(gamePools);	
		for (let i = 0; i < gamePools.length; i++) {
			
			const g = gamePools[i];		
			// console.log(g);	
			
			const filler = {
				'supply': 0
				, 'amount': 0
				, 'decimals': DECIMALS
			}
			const h = tokenInfosParsed.filter(x => x.address === g.homeTeamToken);
			const a = tokenInfosParsed.filter(x => x.address === g.awayTeamToken);
			const t = g.tiePoolAccount && g.tiePoolAccount != '' ? tokenInfosParsed.filter(x => x.address === g.tieTeamToken) : [filler];
			const hp = propsAccountsParsed.filter(x => x.owner === g.homePoolAccount);
			const ap = propsAccountsParsed.filter(x => x.owner === g.awayPoolAccount);
			const tp = g.tiePoolAccount && g.tiePoolAccount != '' ? propsAccountsParsed.filter(x => x.owner === g.tiePoolAccount) : [filler];
			// console.log(`a = ${a.length}`);
			// console.log(`h = ${h.length}`);
			// console.log(`t = ${t.length}`);
			// console.log(`hp = ${hp.length}`);
			// console.log(`ap = ${ap.length}`);
			// console.log(`tp = ${tp.length}`);
			
			
			if (a.length && h.length && t.length && hp.length && ap.length && tp.length) {

				const homeTokenPrice = calcPriceNew( h[0].supply, a[0].supply, t[0].supply, hp[0].amount, ap[0].amount, tp[0].amount );
				const awayTokenPrice = calcPriceNew( a[0].supply, h[0].supply, t[0].supply, ap[0].amount, hp[0].amount, tp[0].amount );
				const tieTokenPrice = calcPriceNew( t[0].supply, h[0].supply, a[0].supply, tp[0].amount, ap[0].amount, hp[0].amount );

				gamePools[i].tieTokenDecimals = t[0].decimals;
				gamePools[i].awayTokenDecimals = a[0].decimals;
				gamePools[i].homeTokenDecimals = h[0].decimals;
				gamePools[i].tieTokenSupply = t[0].supply;
				gamePools[i].awayTokenSupply = a[0].supply;
				gamePools[i].homeTokenSupply = h[0].supply;
				gamePools[i].tieTokenPrice = tieTokenPrice;
				gamePools[i].awayTokenPrice = awayTokenPrice;
				gamePools[i].homeTokenPrice = homeTokenPrice;
				gamePools[i].homeWagerTokenBalance = hp[0].amount;
				gamePools[i].awayWagerTokenBalance = ap[0].amount;
				gamePools[i].tieWagerTokenBalance = tp[0].amount;
				// gamePools[i].numBonus = b[0].amount;
				
			}
			if (!address) {
				gamePools[i].userAwayTokenBalance = 0;
				gamePools[i].userHomeTokenBalance = 0;
				gamePools[i].userTieTokenBalance = 0;
			}
		}
		// console.log(`259: ${(new Date().getTime() / 1000.0) - t0} seconds`);
		
		if (address) {
			loadUserTokens(address, gamePools);
		} else {
			dispatch(actions.setUserPropsBalance(0));
			dispatch(actions.setUserBonusBalance(0));
			dispatch(actions.setGamePools(gamePools));
		}
		dispatch(actions.setLoadingPools(false));
		console.log('loading is', data.loading);
	}

	const loadLeaderboard = async () => {
		let leaderboard = await axios({
			method: 'get',
			url: BACKEND_URL+'/api/leaderboard',
		});
		console.log(`leaderboard`);
		console.log(leaderboard);
		
		dispatch(actions.setLeaderboard(leaderboard.data));
	}

	const loadUserTrades = async (address: string) => {
		if (address) {
			let userTrades = await axios({
				method: 'post',
				url: BACKEND_URL+'/api/trade/userTrades',
				data: {'address': address}
			});
			dispatch(actions.setUserTrades(userTrades.data));
		}
		else {
			dispatch(actions.setUserTrades([]));
		}
	}

	const loadUserTokens = async (address: string, gamePools: GamePoolWithDetail[]) => {
		
		if (!address) {
			// dispatch(actions.setUserTokens([]));
			dispatch(actions.setUserPropsBalance(0));
			dispatch(actions.setUserBonusBalance(0));
			return;
		}
		const addressPubkey = new PublicKey(address);
		// const pbt = new PublicKey('GqV6UM2iWu1X6TZRyj39J3Bzf8ofZuG7owmfwA3cjUqe');
		// const props = new PublicKey('FtPjwYdRjYxcELz3b7JgdcXhqgbWaVF2vvo9L7avJx5S');
		const pbt = new PublicKey(PBT_MINT);
		const props = new PublicKey(PROPS_MINT);
		const propsMint: Mint = new Mint(connection, SOL_ADDRESS);
		// const propsMint: Mint = new Mint(connection, pbt);
		const bonusMint: Mint = new Mint(connection, props);
		// const balance = await propsMint.getBalance( addressPubkey );
		
		const userPropsBalance = address ? await connection.getBalance(new PublicKey(address)) / LAMPORTS_PER_SOL : 0;
		
		const userBonusBalance = address ? await bonusMint.getBalance( addressPubkey ) : 0;
		dispatch(actions.setUserPropsBalance(userPropsBalance));
		dispatch(actions.setUserBonusBalance(userBonusBalance));

		let filters = getOwnedAccountsFilters(addressPubkey);
		let response = await connection.getProgramAccounts(TOKEN_PROGRAM_ID, {
			commitment: connection.commitment,
			filters
		});
		const userTokenAccountsParsed: any[] = response.filter(x => x !== null).map((x: any) => {
			try {
				const a = parseTokenAccountData(x.account.data);
				// console.log('a');
				// console.log(a);
				
	
				return({
					'address': x.publicKey ? x.publicKey.toString() : ''
					// @ts-ignore
					, 'amount': a.amount.toNumber()
					, 'owner': a.owner ? a.owner.toString() : ''
					, 'mint': a.mint ? a.mint.toString() : ''
				})
			}
			catch {
				return({
					'address': x.publicKey ? x.publicKey.toString() : ''
					, 'amount': 0
					, 'owner': ''
					, 'mint': ''
				})
			}
		});
		
		for (let i = 0; i < gamePools.length; i++) {
			const g = gamePools[i];
			const h = userTokenAccountsParsed.filter( x => x.mint === g.homeTeamToken );
			if (h.length) {
				// console.log('490');
				const decimals = h[0].mint ? await new Mint(connection, new PublicKey(h[0].mint)).getDecimals() : DECIMALS;
				// console.log(`${h[0].mint} decimals = ${decimals}`);
				gamePools[i].userHomeTokenBalance = h[0].amount / Math.pow(10, decimals );
				// console.log('493');
			} else {
				gamePools[i].userHomeTokenBalance = 0;
			}
			const a = userTokenAccountsParsed.filter( x => x.mint === g.awayTeamToken );
			if (a.length) {
				// console.log('490b');
				const decimals = a[0].mint ? await new Mint(connection, new PublicKey(a[0].mint)).getDecimals() : DECIMALS;
				// const decimals = await new Mint(connection, a[0].mint).getDecimals();
				gamePools[i].userAwayTokenBalance = a[0].amount / Math.pow(10, decimals);
				// console.log('490c');
			} else {
				gamePools[i].userAwayTokenBalance = 0;
			}
			const t = userTokenAccountsParsed.filter( x => x.mint === g.tieTeamToken );
			if (t.length) {
				// console.log('490d');
				const decimals = t[0].mint ? await new Mint(connection, new PublicKey(t[0].mint)).getDecimals() : DECIMALS;
				// const decimals = await new Mint(connection, t[0].mint).getDecimals();
				gamePools[i].userTieTokenBalance = t[0].amount / Math.pow(10, decimals);
				// console.log('490e');
			} else {
				gamePools[i].userTieTokenBalance = 0;
			}
		}
		dispatch(actions.setGamePools(gamePools));
		
	}

	useEffect(() => {
		// loadPools( address ? address.toBase58() : '');
		loadLeaderboard();
		loadRecentTrades();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const useAddress = address ? address.toBase58() : '';
	if (useAddress !== data.address) {
		loadPools(useAddress);
		loadUser(useAddress);
		loadUserPayouts(useAddress);
		dispatch(actions.setAddress(useAddress));
		loadUserTrades(useAddress);
	}


	return (
		<div className='App text-center'>
			<Routes />
		</div>
	);
}
const AppWithProvider = () => (
	<ConnectionProvider endpoint={NETWORK}>
		<WalletProvider wallets={wallets} autoConnect>
			<WalletModalProvider>
				<App/>
			</WalletModalProvider>
		</WalletProvider>
	</ConnectionProvider>
)



export default AppWithProvider;


