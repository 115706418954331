import GamePoolCard from './GamePoolCard';
import PoolCard from './mobile/PoolCard';
import { GamePoolWithDetail } from '../models/GamePoolWithDetail';
import { useSelector } from 'react-redux';
import { IState as IState2 }  from '../store/interfaces/state';
import { isMobile } from '../constants';



interface IProps {
    gamePool: GamePoolWithDetail,
    address: string,
    idl: any
}

// the container for all the GamePoolCards
const GamePoolRow = (props: IProps) => {
    const data: IState2 = useSelector((state: any) => state.data)
    return (
        <>
        <div className="col-12 col-xl-6 padding-1">
			{!isMobile ? <GamePoolCard key={props.gamePool.gameId} gamePool={props.gamePool} address={props.address} data={data}/>
            : <PoolCard pool={props.gamePool} odds={data.odds} />}
        </div>
        </>
    );
};

export default GamePoolRow;
