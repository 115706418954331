// a team token held by the user
export class User {
    public address: string;
    public userName: string;
    public userAvatarUrl: string;
    public userOdds: string;

    constructor(
        address: string = ''
        , userName: string = ''
        , userAvatarUrl: string = ''
        , userOdds: string = ''
    ) {
        this.address = address;
        this.userName = userName;
        this.userAvatarUrl = userAvatarUrl;
        this.userOdds = userOdds;
    }
}