import { Outcome } from '../models/Outcome';
import { GamePoolWithDetail } from '../models/GamePoolWithDetail';



// gamePool, outcome, isToTeamToken
export interface ITrade {
    txId: string;
    gameId: string;
    address: string;
    // userName: string;
    // userAvatarUrl: string;
    timestamp: number;
    // token: string;
    teamAbbr: string;
    teamName: string;
    sport: string;
    week: string;
    teamTokenAmount: number;
    teamTokenAddress: string;
    wagerTokenAmount: number;
    action: string;
    wagerTokenAddress: string;
	payoutAmount: number | null;
	payoutTx: string | null;
}

export class Trade implements ITrade {
    txId: string;
    gameId: string;
    address: string;
    timestamp: number;
    teamAbbr: string;
    teamName: string;
    sport: string;
    week: string;
    teamTokenAmount: number;
    teamTokenAddress: string;
    wagerTokenAmount: number;
    action: string;
    wagerTokenAddress: string;
	payoutAmount: number | null;
	payoutTx: string | null;

	constructor(
        txId: string
        , gameId: string
        , address: string
        , timestamp: number
        , teamAbbr: string
        , teamName: string
        , sport: string
        , week: string
        , teamTokenAmount: number
        , teamTokenAddress: string
        , wagerTokenAmount: number
        , action: string
        , wagerTokenAddress: string
        , payoutAmount: number | null = null
        , payoutTx: string | null = null
    ) {
        this.txId = txId;
        this.gameId = gameId;
        this.address = address;
        this.timestamp = timestamp;
        this.teamAbbr = teamAbbr;
        this.teamName = teamName;
        this.sport = sport;
        this.week = week;
        this.teamTokenAmount = teamTokenAmount;
        this.teamTokenAddress = teamTokenAddress;
        this.wagerTokenAmount = wagerTokenAmount;
        this.action = action;
        this.wagerTokenAddress = wagerTokenAddress;
        this.teamTokenAddress = teamTokenAddress;
        this.payoutAmount = payoutAmount;
        this.payoutTx = payoutTx;
    }

}
