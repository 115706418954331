import { Component, PropsWithChildren, useEffect, useMemo, useRef, RefObject } from "react";
import { BoltIcon, ArchiveBoxIcon, ArrowsRightLeftIcon, TicketIcon } from '@heroicons/react/24/solid'
import { useOutsideAlerter } from '../../utils/OutsideAlerter';
import { NavLink, useHistory, useLocation } from 'react-router-dom';






interface NavigationProps {
    close: Function;
    isOpen: Boolean;
}

const Navigation = ({ close, isOpen }: NavigationProps) => {

    const location = useLocation();

    useEffect(() => {
        close()
    }, [location.pathname])

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, close);


    const NavItem = ({ children, loc, name }: PropsWithChildren<{ loc: string, name: string }>) => {

        const location = useLocation();

        return <NavLink to={loc}>
            <div className={` tw-flex hover:tw-cursor-pointer tw-flex-row tw-justify-center md:tw-justify-start  tw-items-center ${(loc===location.pathname) ? " tw-text-black1 tw-bg-base-200 tw-rounded-lg tw-p-2" : "tw-text-white1 tw-p-2 "} `}>
                {children}
                <p className="tw-ml-5 tw-font-title">{name}</p>
            </div>
        </NavLink>
    }

    if (isOpen===false) return <></> 
    return <div>
        <div className={`tw-flex tw-flex-col tw-space-y-5 tw-p-5 tw-bg-base-300 tw-w-full
         md:tw-w-auto md:tw-min-h-full tw-absolute tw-z-40
         md:tw-relative tw-transition-all tw-duration-300 md:tw-top-0 ${isOpen ? " tw-top-[98px] " : " -tw-top-[50vh]  md:tw-block "} `} ref={wrapperRef}>
            <NavItem name="Games" loc="/games" >
                <BoltIcon className="tw-h-10" />
            </NavItem>
            <NavItem name="Trade" loc="/trade" >
                <ArrowsRightLeftIcon className="tw-h-10" />
            </NavItem>
            <NavItem name="Positions" loc="/my-positions" >
                <TicketIcon className="tw-h-10" />
            </NavItem>
            <NavItem name="My Trades" loc="/my-trades" >
                <ArchiveBoxIcon className="tw-h-10" />
            </NavItem>
        </div>

    </div>
}

export default Navigation;