import axios from 'axios';
import idl from '../idl.json';
import images from '../static/images';
import { account, Mint, Wallet } from 'easy-spl';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { FaChevronDown } from 'react-icons/fa';
import * as anchor from '@project-serum/anchor';
import { IoSearchOutline } from 'react-icons/io5';
import { IState } from '../store/interfaces/state';
import React, { useEffect, useState } from 'react';
import { swapTeamTokensRpc, swapTeamTokensRpcNew, swapTeamTokensTiePoolRpc, swapTeamTokensTiePoolWithFeeRpc, swapTeamTokensWithFeeRpc } from '../api/exchange';
// import { swapTeamTokensRpc, swapTeamTokensRpcNew } from '../api/exchange';
import * as actions from '../store/actions/actions';
import { DUST_MINT, MAX_TOKENS, NETWORK, PBT_MINT, PROGRAM_ID, PROPS_MINT, SOL_ADDRESS, TOKEN_MAP, ADDRESS_TO_TOKEN_MAP, isMobile, MSOL_MINT, USDH_MINT, BACKEND_URL, ADMIN_ADDRESSES, FEE_DECIMALS, FEE_PCT, FEE_ADDRESS } from '../constants';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useWallet } from '@solana/wallet-adapter-react';
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { payOutTeamTokensRpc, payOutTeamTokensTiePoolRpc } from '../api/payOutTeamTokens';
// import { payOutTeamTokensRpc } from '../api/payOutTeamTokens';
import { Connection, PublicKey, clusterApiUrl, ConnectionConfig, Keypair, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { calcEsimatedAmount, calcEsimatedAmountOg, calcEsimatedPROPSAmount, calcEsimatedPropsNeeded, calcOdds, calcPrice, convertToTokenName, createAssociatedTokenAccountSendUnsigned, getCurrentTimestamp, getOpposingToken, getTeamImg } from '../utils/utils';
import { Outcome } from '../models/Outcome';
import { GamePoolWithDetail } from '../models/GamePoolWithDetail';
import { SwapObject } from '../models/SwapObject';
import { TokenObject } from '../models/TokenObject';
import { Trade } from '../models/Trade';
import RecentTradesPanel from './RecentTradesPanel';

function useForceUpdate() {
    let [value, setState] = useState(true);
    return () => setState(!value);
}

// const USE_TOKENS = [ 'SOL','mSOL' ]
const USE_TOKENS = [ 'SOL','mSOL','USDH' ]

const isRealToken = (token: string) => {
	return(USE_TOKENS.includes(token));
}


// gamePool, outcome, isToTeamToken
// interface ITokenObject {
// 	tokenString: string;
// 	isRealToken: boolean;
// 	gamePool: GamePoolWithDetail | null;
// 	outcome: Outcome | null;
// }

// class TokenObject implements ITokenObject {
// 	tokenString: string;
// 	isRealToken: boolean;
// 	gamePool: GamePoolWithDetail;
// 	outcome: Outcome;
// 	team: string;
// 	requiredTokenString: string;
// 	userBalance: number;

// 	constructor(
//         tokenString: string = 'SOL'
//         , isRealToken: boolean = true
//         , gamePool: GamePoolWithDetail = new GamePoolWithDetail()
//         , outcome: Outcome = Outcome.Home
//         , team: string = 'SOL'
//         , requiredTokenString: string = 'SOL'
//         , userBalance: number = 0
//     ) {
//         this.tokenString = tokenString;
//         this.isRealToken = isRealToken;
//         this.gamePool = gamePool;
//         this.outcome = outcome;
//         this.team = team;
//         this.requiredTokenString = requiredTokenString;
//         this.userBalance = userBalance;
//     }
// }

// gamePool, outcome, isToTeamToken
// interface ISwapObject {
//     gamePool: GamePoolWithDetail;
//     outcome: Outcome;
//     isToTeamToken: boolean | null;
//     tokens: TokenObject[];
//     selectedToken: TokenObject;
// }



// swap tokens!
const SwapPage = (props: any) => {
	
    const dispatch = useDispatch();
    const data: IState = useSelector((state: any) => state.data);
	
	const initBalance: any = {}
	const initSwapObject = new SwapObject();
    
    const [ errorText, setErrorText ] = React.useState('');
    const [ toAmountText, setToAmountText ] = React.useState('');
    const [ isSwapping, setIsSwapping ] = React.useState(false);
    const [ show, setShow ] = React.useState(false);
    const [ toShow, setToShow ] = React.useState(false);
    const [ fromAmountText, setFromAmountText ] = React.useState('');
    const [ toModalSearchText, setToModalSearchText ] = React.useState('');
    const [ fromModalSearchText, setFromModalSearchText ] = React.useState('');
    const [ userTokenBalances, setUserTokenBalances ] = React.useState(initBalance);
    const [ swapObject, setSwapObject ] = React.useState(initSwapObject);

    // const [fromToken, setFromToken] = React.useState( new TokenObject() );
    // const [toToken, setToToken] = React.useState( new TokenObject() );

	const walletContext: any = useWallet();
	const config: ConnectionConfig = {
        commitment: 'confirmed',
        disableRetryOnRateLimit: false,
        confirmTransactionInitialTimeout: 150000
    };
	const connection = new Connection(NETWORK, config);

	let forceUpdate = useForceUpdate();

	const getSpecificUserTokenBalances = (token: string) => {
		// console.log(`getSpecificUserTokenBalances token = ${token}`);
		// console.log(userTokenBalances);
		if(!TOKEN_MAP.hasOwnProperty(token)) {
			console.log(`not in TOKEN_MAP`);
			return(0);
		}
		const t = TOKEN_MAP[token];
		// console.log(`getSpecificUserTokenBalances token = ${token}. t = ${t}`);

		
		try {
			if(userTokenBalances.hasOwnProperty(t)) {
				return(userTokenBalances[t]);
			}
			return(0);
		} catch (error) {
			return(0);
		}
	}

    const swapTeamTokensNew = async (
        gamePool: GamePoolWithDetail,
        outcome: Outcome,
		amount: number,
        isBuy: boolean,
        isPlayed: boolean,
        estAmount: number
    ) => {
        try {
			let n_errors = 0;
            setIsSwapping(true);
            toast.info("Transaction has been sent", {
            	position: toast.POSITION.BOTTOM_LEFT
            });

            const provider = new anchor.Provider(connection, walletContext, config);
            // const provider = new anchor.Provider(connection, walletContext, anchor.Provider.defaultOptions());

            const programId = new anchor.web3.PublicKey(PROGRAM_ID);
            const anchorIdl: any = idl;
            const program = new anchor.Program(
                anchorIdl,
                programId,
                provider,
            );

            if (!provider.wallet!.publicKey!) {
                alert("Phantom Wallet not connected!");
                return;
            }

			const wagerToken = new PublicKey(gamePool.wagerToken);
			const wagerTokenMint: Mint = new Mint(connection, wagerToken);

			const userAccount: Wallet = new Wallet(connection, provider.wallet);
			const userAuthority = userAccount.publicKey;
			
            const userWagerTokenAccount: PublicKey = await createAssociatedTokenAccountSendUnsigned(
                connection
                , wagerToken
                , userAccount.publicKey
                , userAccount
            );
            const userTeamTokenAccount: PublicKey = await createAssociatedTokenAccountSendUnsigned(
                connection
                , outcome == Outcome.Home ? new PublicKey(gamePool.homeTeamToken) : outcome == Outcome.Away ? new PublicKey(gamePool.awayTeamToken) :  new PublicKey(gamePool.tieTeamToken)
                , userAccount.publicKey
                , userAccount
            );
            const homeTeamToken = new PublicKey(gamePool.homeTeamToken);
            const awayTeamToken = new PublicKey(gamePool.awayTeamToken);
			// let tieTeamToken, tieMint: Mint = new Mint(connection, new PublicKey(''));
			let tieTeamToken, tieMint: Mint;

			if (gamePool.tieTeamToken) {
				tieTeamToken = new PublicKey(gamePool.tieTeamToken);
				tieMint = new Mint(connection, tieTeamToken );
			}

            const homeMint: Mint = new Mint(connection, homeTeamToken );
            const awayMint: Mint = new Mint(connection, awayTeamToken );

			
			// @ts-ignore
            const decimals = isBuy ? await wagerTokenMint.getDecimals() : (outcome == Outcome.Home ? await homeMint.getDecimals() : outcome == Outcome.Away ? await awayMint.getDecimals() : await tieMint.getDecimals());
			// console.log(`decimals: = ${decimals}`);			
            const amountWithDecimals = amount * Math.pow(10, decimals);
			// console.log(`amountWithDecimals: = ${amountWithDecimals}`);			
			const token = outcome == Outcome.Home ? homeTeamToken : outcome == Outcome.Away ? awayTeamToken : tieTeamToken;


			const feeReceiverAccount2 = new PublicKey(FEE_ADDRESS);

			const wagerTokenPublicKey = new PublicKey(gamePool.wagerToken);
			let feeReceiverWagerTokenAccount2 = await Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, wagerTokenPublicKey, feeReceiverAccount2, true);

			let takingFees = FEE_PCT > 0;
			console.log(`takingFees = ${takingFees}`);

			// This is a check: if the token account doesn't exist, then we can't take fees
			if (!(await account.exists(connection, feeReceiverWagerTokenAccount2))) {
				feeReceiverWagerTokenAccount2 = feeReceiverAccount2
				takingFees = false;
				console.log(`Wager token account does not exist for ${feeReceiverAccount2.toString()}`);
			}

			const feeRate = FEE_PCT * Math.pow(10, FEE_DECIMALS); // 2% fee for each trade
			const pctToFeeReciever1 = 0.50 * Math.pow(10, FEE_DECIMALS); // 50/50 split
			console.log(`feeRate = ${feeRate}`);
			console.log(`pctToFeeReciever1 = ${pctToFeeReciever1}`);

			if (takingFees) {
				if (gamePool.tieTeamToken) {
					if (token !== undefined && tieTeamToken !== undefined) {
						if (isPlayed) {
							console.log(`payOutTeamTokensTiePoolRpc`);
							await payOutTeamTokensTiePoolRpc(
								program,
								amountWithDecimals,
			
								new PublicKey(gamePool.poolAccount),
								provider.wallet.publicKey,
								new PublicKey(gamePool.homePoolAccount),
								new PublicKey(gamePool.awayPoolAccount),
								new PublicKey(gamePool.tiePoolAccount),
								token,
								homeTeamToken,
								awayTeamToken,
								tieTeamToken,
								new PublicKey(gamePool.wagerToken),
								userTeamTokenAccount,
								new PublicKey(gamePool.homePoolWagerTokenAccount),
								new PublicKey(gamePool.awayPoolWagerTokenAccount),
								new PublicKey(gamePool.tiePoolWagerTokenAccount),
								userWagerTokenAccount,
								new PublicKey(gamePool.feeReceiverWagerTokenAccount),
								new PublicKey(gamePool.signer),
							);
						} else {
			
							console.log(`swapTeamTokensTiePoolWithFeeRpc`);
							// console.log(gamePool);
							
							const txId = await swapTeamTokensTiePoolWithFeeRpc(
								program,
								new PublicKey(gamePool.poolAccount),
								// propsKeypair,
								userAuthority,
								new PublicKey(gamePool.homePoolAccount),
								new PublicKey(gamePool.awayPoolAccount),
								new PublicKey(gamePool.tiePoolAccount),
								
								token,
								new PublicKey(gamePool.homeTeamToken),
								new PublicKey(gamePool.awayTeamToken),
								new PublicKey(gamePool.tieTeamToken),
								new PublicKey(gamePool.wagerToken),
			
								userTeamTokenAccount,
			
								new PublicKey(gamePool.homePoolWagerTokenAccount),
								new PublicKey(gamePool.awayPoolWagerTokenAccount),
								new PublicKey(gamePool.tiePoolWagerTokenAccount),
								userWagerTokenAccount,
								new PublicKey(gamePool.feeReceiverWagerTokenAccount),
								feeReceiverWagerTokenAccount2,
								new PublicKey(gamePool.signer),
								feeReceiverAccount2,
					
								amountWithDecimals,
								isBuy,
								feeRate,
								pctToFeeReciever1,
							);
	
							// console.log(`txId`);
							// console.log(txId);
							if ( (!ADMIN_ADDRESSES.includes(userAuthority.toString())) ) {
								const trade = new Trade(
									txId
									, gamePool.gameId
									, userAuthority.toString()
									, Math.round(new Date().getTime() / 1000)
									, outcome == Outcome.Home ? gamePool.homeTeam : outcome == Outcome.Away ? gamePool.awayTeam : 'DRAW'
									, outcome == Outcome.Home ? gamePool.homeTeamName : outcome == Outcome.Away ? gamePool.awayTeamName : `${gamePool.homeTeam} vs ${gamePool.awayTeam} Draw`
									, gamePool.sport
									, gamePool.week
									, isBuy ? estAmount : amount
									, outcome == Outcome.Home ? gamePool.homeTeamToken : outcome == Outcome.Away ? gamePool.awayTeamToken : gamePool.tieTeamToken
									, isBuy ? amount : estAmount
									, isBuy ? 'Buy' : 'Sell'
									, gamePool.wagerToken
								)
			
								console.log(`addTrade`);
								console.log(trade);
								let response = await axios({
									method: 'post',
									url: BACKEND_URL+'/api/trade/addTrade',
									data: trade
								});
								console.log(`addTrade response`);
								console.log(response);
							}
	
							// console.log(`txId`);
							// console.log(txId);
							if (!txId) {
								n_errors += 1;
							}
						}
					}
				} else {
					if (isPlayed) {
						console.log(`payOutTeamTokensRpc`);
						const val = await payOutTeamTokensRpc(
							program,
							amountWithDecimals,
		
							new PublicKey(gamePool.poolAccount),
							provider.wallet.publicKey,
							new PublicKey(gamePool.homePoolAccount),
							new PublicKey(gamePool.awayPoolAccount),
							outcome == Outcome.Home ? homeTeamToken : awayTeamToken,
							homeTeamToken,
							awayTeamToken,
							new PublicKey(gamePool.wagerToken),
							userTeamTokenAccount,
							new PublicKey(gamePool.homePoolWagerTokenAccount),
							new PublicKey(gamePool.awayPoolWagerTokenAccount),
							userWagerTokenAccount,
							new PublicKey(gamePool.feeReceiverWagerTokenAccount),
							new PublicKey(gamePool.signer),
						);
						// console.log(`val`);
						// console.log(val);
						
					} else {
		
						console.log(`swapTeamTokensWithFeeRpc`);
						const txId = await swapTeamTokensWithFeeRpc(
							program,
							new PublicKey(gamePool.poolAccount),
							// propsKeypair,
							userAuthority,
							new PublicKey(gamePool.homePoolAccount),
							new PublicKey(gamePool.awayPoolAccount),
							
							outcome == Outcome.Home ? new PublicKey(gamePool.homeTeamToken) : new PublicKey(gamePool.awayTeamToken),
							new PublicKey(gamePool.homeTeamToken),
							new PublicKey(gamePool.awayTeamToken),
							new PublicKey(gamePool.wagerToken),
		
							userTeamTokenAccount,
		
							new PublicKey(gamePool.homePoolWagerTokenAccount),
							new PublicKey(gamePool.awayPoolWagerTokenAccount),
							userWagerTokenAccount,

							// fee receivers
							new PublicKey(gamePool.feeReceiverWagerTokenAccount),
							feeReceiverWagerTokenAccount2,
							new PublicKey(gamePool.signer),
							feeReceiverAccount2,

							amountWithDecimals,
							isBuy,
							feeRate,
							pctToFeeReciever1
						);
						// console.log(`txId`);
						// console.log(txId);
						if ( (!ADMIN_ADDRESSES.includes(userAuthority.toString())) ) {
							const trade = new Trade(
								txId
								, gamePool.gameId
								, userAuthority.toString()
								, Math.round(new Date().getTime() / 1000)
								, outcome == Outcome.Home ? gamePool.homeTeam : gamePool.awayTeam
								, outcome == Outcome.Home ? gamePool.homeTeamName : gamePool.awayTeamName
								, gamePool.sport
								, gamePool.week
								, isBuy ? estAmount : amount
								, outcome == Outcome.Home ? gamePool.homeTeamToken : outcome == Outcome.Away ? gamePool.awayTeamToken : gamePool.tieTeamToken
								, isBuy ? amount : estAmount
								, isBuy ? 'Buy' : 'Sell'
								, gamePool.wagerToken
							)
		
							console.log(`addTrade`);
							console.log(trade);
							let response = await axios({
								method: 'post',
								url: BACKEND_URL+'/api/trade/addTrade',
								data: trade
							});
							console.log(`addTrade response`);
							console.log(response);
						}
						
						if (!txId) {
							n_errors += 1;
						}
					}
				}

			} else {

				if (gamePool.tieTeamToken) {
					if (token !== undefined && tieTeamToken !== undefined) {
						if (isPlayed) {
							console.log(`payOutTeamTokensTiePoolRpc`);
							await payOutTeamTokensTiePoolRpc(
								program,
								amountWithDecimals,
			
								new PublicKey(gamePool.poolAccount),
								provider.wallet.publicKey,
								new PublicKey(gamePool.homePoolAccount),
								new PublicKey(gamePool.awayPoolAccount),
								new PublicKey(gamePool.tiePoolAccount),
								token,
								homeTeamToken,
								awayTeamToken,
								tieTeamToken,
								new PublicKey(gamePool.wagerToken),
								userTeamTokenAccount,
								new PublicKey(gamePool.homePoolWagerTokenAccount),
								new PublicKey(gamePool.awayPoolWagerTokenAccount),
								new PublicKey(gamePool.tiePoolWagerTokenAccount),
								userWagerTokenAccount,
								new PublicKey(gamePool.feeReceiverWagerTokenAccount),
								new PublicKey(gamePool.signer),
							);
						} else {
			
							console.log(`swapTeamTokensTiePoolRpc`);
							console.log(gamePool);
							
							const txId = await swapTeamTokensTiePoolRpc(
								program,
								new PublicKey(gamePool.poolAccount),
								// propsKeypair,
								userAuthority,
								new PublicKey(gamePool.homePoolAccount),
								new PublicKey(gamePool.awayPoolAccount),
								new PublicKey(gamePool.tiePoolAccount),
								
								token,
								new PublicKey(gamePool.homeTeamToken),
								new PublicKey(gamePool.awayTeamToken),
								new PublicKey(gamePool.tieTeamToken),
								new PublicKey(gamePool.wagerToken),
			
								userTeamTokenAccount,
			
								new PublicKey(gamePool.homePoolWagerTokenAccount),
								new PublicKey(gamePool.awayPoolWagerTokenAccount),
								new PublicKey(gamePool.tiePoolWagerTokenAccount),
								userWagerTokenAccount,
								new PublicKey(gamePool.feeReceiverWagerTokenAccount),
								new PublicKey(gamePool.signer),
					
								amountWithDecimals,
								isBuy
							);
	
							// console.log(`txId`);
							// console.log(txId);
							if ( (!ADMIN_ADDRESSES.includes(userAuthority.toString())) ) {
								const trade = new Trade(
									txId
									, gamePool.gameId
									, userAuthority.toString()
									, Math.round(new Date().getTime() / 1000)
									, outcome == Outcome.Home ? gamePool.homeTeam : outcome == Outcome.Away ? gamePool.awayTeam : 'DRAW'
									, outcome == Outcome.Home ? gamePool.homeTeamName : outcome == Outcome.Away ? gamePool.awayTeamName : `${gamePool.homeTeam} vs ${gamePool.awayTeam} Draw`
									, gamePool.sport
									, gamePool.week
									, isBuy ? estAmount : amount
									, outcome == Outcome.Home ? gamePool.homeTeamToken : outcome == Outcome.Away ? gamePool.awayTeamToken : gamePool.tieTeamToken
									, isBuy ? amount : estAmount
									, isBuy ? 'Buy' : 'Sell'
									, gamePool.wagerToken
								)
			
								console.log(`addTrade`);
								console.log(trade);
								let response = await axios({
									method: 'post',
									url: BACKEND_URL+'/api/trade/addTrade',
									data: trade
								});
								console.log(`addTrade response`);
								console.log(response);
							}
	
							// console.log(`txId`);
							// console.log(txId);
							if (!txId) {
								n_errors += 1;
							}
						}
					}
				} else {
					if (isPlayed) {
						console.log(`payOutTeamTokensRpc`);
						const val = await payOutTeamTokensRpc(
							program,
							amountWithDecimals,
		
							new PublicKey(gamePool.poolAccount),
							provider.wallet.publicKey,
							new PublicKey(gamePool.homePoolAccount),
							new PublicKey(gamePool.awayPoolAccount),
							outcome == Outcome.Home ? homeTeamToken : awayTeamToken,
							homeTeamToken,
							awayTeamToken,
							new PublicKey(gamePool.wagerToken),
							userTeamTokenAccount,
							new PublicKey(gamePool.homePoolWagerTokenAccount),
							new PublicKey(gamePool.awayPoolWagerTokenAccount),
							userWagerTokenAccount,
							new PublicKey(gamePool.feeReceiverWagerTokenAccount),
							new PublicKey(gamePool.signer),
						);
						// console.log(`val`);
						// console.log(val);
						
					} else {
		
						console.log(`swapTeamTokensRpcNew`);
						const txId = await swapTeamTokensRpcNew(
							program,
							new PublicKey(gamePool.poolAccount),
							// propsKeypair,
							userAuthority,
							new PublicKey(gamePool.homePoolAccount),
							new PublicKey(gamePool.awayPoolAccount),
							
							outcome == Outcome.Home ? new PublicKey(gamePool.homeTeamToken) : new PublicKey(gamePool.awayTeamToken),
							new PublicKey(gamePool.homeTeamToken),
							new PublicKey(gamePool.awayTeamToken),
							new PublicKey(gamePool.wagerToken),
		
							userTeamTokenAccount,
		
							new PublicKey(gamePool.homePoolWagerTokenAccount),
							new PublicKey(gamePool.awayPoolWagerTokenAccount),
							userWagerTokenAccount,
							new PublicKey(gamePool.feeReceiverWagerTokenAccount),
							new PublicKey(gamePool.signer),
				
							amountWithDecimals,
							isBuy
						);
						// console.log(`txId`);
						// console.log(txId);
						if ( (!ADMIN_ADDRESSES.includes(userAuthority.toString())) ) {
							// const trade = new Trade(
							// 	txId
							// 	, gamePool.gameId
							// 	, userAuthority.toString()
							// 	, Math.round(new Date().getTime() / 1000)
							// 	, outcome == Outcome.Home ? gamePool.homeTeam : gamePool.awayTeam
							// 	, outcome == Outcome.Home ? gamePool.homeTeamName : gamePool.awayTeamName
							// 	, gamePool.sport
							// 	, gamePool.week
							// 	, isBuy ? estAmount : amount
							// 	, isBuy ? amount : estAmount
							// 	, isBuy ? 'Buy' : 'Sell'
							// 	, gamePool.wagerToken
							// 	, outcome == Outcome.Home ? gamePool.homeTeamToken : outcome == Outcome.Away ? gamePool.awayTeamToken : gamePool.tieTeamToken
							// )
							// const trade = new Trade(
							// 	txId
							// 	, gamePool.gameId
							// 	, userAuthority.toString()
							// 	, Math.round(new Date().getTime() / 1000)
							// 	, outcome == Outcome.Home ? gamePool.homeTeam : gamePool.awayTeam
							// 	, outcome == Outcome.Home ? gamePool.homeTeamName : gamePool.awayTeamName
							// 	, gamePool.sport
							// 	, gamePool.week
							// 	, isBuy ? estAmount : amount
							// 	, outcome == Outcome.Home ? gamePool.homeTeamToken : outcome == Outcome.Away ? gamePool.awayTeamToken : gamePool.tieTeamToken
							// 	, isBuy ? amount : estAmount
							// 	, isBuy ? 'Buy' : 'Sell'
							// 	, gamePool.wagerToken
							// )
							const trade = {
								'txId': txId
								, 'gameId': gamePool.gameId
								, 'address': userAuthority.toString()
								, 'timestamp': Math.round(new Date().getTime() / 1000)
								, 'teamAbbr': outcome == Outcome.Home ? gamePool.homeTeam : gamePool.awayTeam
								, 'teamName': outcome == Outcome.Home ? gamePool.homeTeamName : gamePool.awayTeamName
								, 'sport': gamePool.sport
								, 'week': gamePool.week
								, 'teamTokenAmount': isBuy ? estAmount : amount
								, 'teamTokenAddress': outcome == Outcome.Home ? gamePool.homeTeamToken : outcome == Outcome.Away ? gamePool.awayTeamToken : gamePool.tieTeamToken
								, 'wagerTokenAmount': isBuy ? amount : estAmount
								, 'action': isBuy ? 'Buy' : 'Sell'
								, 'wagerTokenAddress': gamePool.wagerToken
							}
		
							console.log(`addTrade`);
							console.log(trade);
							let response = await axios({
								method: 'post',
								url: BACKEND_URL+'/api/trade/addTrade',
								data: trade
							});
							console.log(`addTrade response`);
							console.log(response);
						}
						
						if (!txId) {
							n_errors += 1;
						}
					}
				}

			}


			
            const homeTokenSupply = await homeMint.getSupply();
            const awayTokenSupply = await awayMint.getSupply();
			// @ts-ignore
            const tieTokenSupply = tieMint ? await tieMint.getSupply() : 0;
			// console.log(`homeTokenSupply = ${homeTokenSupply}`);
			// console.log(`awayTokenSupply = ${awayTokenSupply}`);

            const userAwayTokenBalance = data.address ? await awayMint.getBalance( new PublicKey(data.address) ) : 0;
            const userHomeTokenBalance = data.address ? await homeMint.getBalance( new PublicKey(data.address) ) : 0;
			// @ts-ignore
            const userTieTokenBalance = data.address && tieMint ? await tieMint.getBalance( new PublicKey(data.address) ) : 0;

			let homePoolWagerTokenBalance: number | undefined = undefined;
			let awayPoolWagerTokenBalance: number | undefined = undefined;
			let tiePoolWagerTokenBalance: number | undefined = undefined;
			const wagerDecimals = await wagerTokenMint.getDecimals();

			if (gamePool.wagerToken == SOL_ADDRESS.toString()) {
				homePoolWagerTokenBalance = await connection.getBalance(new PublicKey(gamePool.homePoolAccount));
				awayPoolWagerTokenBalance = await connection.getBalance(new PublicKey(gamePool.awayPoolAccount));
				tiePoolWagerTokenBalance = gamePool.tiePoolAccount ? await connection.getBalance(new PublicKey(gamePool.tiePoolAccount)) : 0;
			} else {
				const token = new Token(connection, new PublicKey(gamePool.wagerToken), TOKEN_PROGRAM_ID, {} as any);
				
				const homeInfo = await token.getAccountInfo(new PublicKey(gamePool.homePoolWagerTokenAccount))
				const awayInfo = await token.getAccountInfo(new PublicKey(gamePool.awayPoolWagerTokenAccount))
				const tieInfo = gamePool.tiePoolWagerTokenAccount ? await token.getAccountInfo(new PublicKey(gamePool.tiePoolWagerTokenAccount)) : null;
				homePoolWagerTokenBalance = new anchor.BN(homeInfo.amount).toNumber();
				awayPoolWagerTokenBalance = new anchor.BN(awayInfo.amount).toNumber();
				tiePoolWagerTokenBalance = tieInfo ? new anchor.BN(tieInfo.amount).toNumber(): 0;
			}
            

            for (let i = 0; i < data.gamePools.length; i++) {
                const g = data.gamePools[i];
                if (g.gameId === gamePool.gameId) {
                    g.userHomeTokenBalance = userHomeTokenBalance;
                    g.userAwayTokenBalance = userAwayTokenBalance;
                    g.userTieTokenBalance = userTieTokenBalance;
                    g.homeTokenSupply = homeTokenSupply;
                    g.awayTokenSupply = awayTokenSupply;
                    g.tieTokenSupply = tieTokenSupply;
					if (homePoolWagerTokenBalance != undefined && awayPoolWagerTokenBalance != undefined) {
						if (tiePoolWagerTokenBalance == undefined) {
							tiePoolWagerTokenBalance = 0;
						}
						const homeTokenPrice = calcPrice(homeTokenSupply, awayTokenSupply, tieTokenSupply, homePoolWagerTokenBalance, awayPoolWagerTokenBalance, tiePoolWagerTokenBalance);
						const awayTokenPrice = calcPrice(awayTokenSupply, homeTokenSupply, tieTokenSupply, awayPoolWagerTokenBalance, homePoolWagerTokenBalance, tiePoolWagerTokenBalance);
						const tieTokenPrice = calcPrice(tieTokenSupply, homeTokenSupply, awayTokenSupply, tiePoolWagerTokenBalance, homePoolWagerTokenBalance, awayPoolWagerTokenBalance);
						g.homeTokenPrice = homeTokenPrice;
						g.awayTokenPrice = awayTokenPrice;
						g.tieTokenPrice = tieTokenPrice;
						g.homeWagerTokenBalance = homePoolWagerTokenBalance / Math.pow(10, wagerDecimals);
						g.awayWagerTokenBalance = awayPoolWagerTokenBalance / Math.pow(10, wagerDecimals);
						g.tieWagerTokenBalance = tiePoolWagerTokenBalance / Math.pow(10, wagerDecimals);
					}
                }
            }
			
            dispatch(actions.setGamePools(data.gamePools));

			const address = provider.wallet!.publicKey.toString();
			const userPropsBalance = address ? await connection.getBalance(new PublicKey(address)) / LAMPORTS_PER_SOL : 0;
			
			dispatch(actions.setUserPropsBalance(userPropsBalance)); 

			
            forceUpdate();
            setIsSwapping(false);
			if (n_errors) {
				// toast.success("Transaction succeeded", {
				toast.error("Something went wrong :(", {
					position: toast.POSITION.BOTTOM_LEFT
				});
			} else {
				toast.success("Transaction succeeded!", {
					position: toast.POSITION.BOTTOM_LEFT
				});
			}
        }
        catch (err: any) {
			console.log("Error making request");
			// console.log(JSON.stringify(error));
			console.error('err.messsage');
			console.error(err.messsage);
            setIsSwapping(false);
			// setErrorText(JSON.stringify(error));
			// toast.success("Transaction succeeded", {
			toast.error("Something went wrong :(", {
                position: toast.POSITION.BOTTOM_LEFT
            });
        }
    }

    const getSelectedGamePool = (gamePools: GamePoolWithDetail[], team: string, sport: string, week: string) => {
		console.log(`getSelectedGamePool team = ${team} sport = ${sport} week = ${week}`);
		
        for (let i = 0; i < gamePools.length; i++) {
            const g = gamePools[i];
            if ((g.homeTeam === team || g.awayTeam === team) && (g.week == week) && (g.sport == sport)) {
                return(g);
            }
        }
        return new GamePoolWithDetail();
    }

    const getTokenBalance = (gamePools: GamePoolWithDetail[], token: string) => {
		const verbose = token == 'DRAW.TOT-NOT.EPL.0828';
		// if (verbose) console.log(`getTokenBalance token = ${token}`);
		// if (verbose) console.log(gamePools);
		
		const l = token.split('.').length;
        let team = token.split('.')[0];
        let team2 = token.split('.').length > 1 ? token.split('.')[1].split('-')[0] : '';
        const sport = token.split('.')[l - 2];
        const week = token.split('.')[l - 1];
		if (sport == 'NBA') {
			team = `${team}.${token.split('.')[1]}`;
		}
		// if (verbose) console.log(`team = ${team}. sport = ${sport}. week = ${week}. `)
        for (let i = 0; i < gamePools.length; i++) {
            const g = gamePools[i];
            if (g.homeTeam === team && g.sport == sport && g.week == week) {
                return(g.userHomeTokenBalance ? g.userHomeTokenBalance : 0);
            } else if (g.awayTeam === team && g.week == week) {
                return(g.userAwayTokenBalance ? g.userAwayTokenBalance : 0);
            } else if ( team == 'DRAW' && g.awayTeam === team2 && g.week == week) {
                return(g.userTieTokenBalance ? g.userTieTokenBalance : 0);
            }
        }
        return(0);
    }

    const getRequiredToken = (gamePools: GamePoolWithDetail[], fromToken: string, toToken: string) => {
		console.log(`getRequiredToken fromToken = ${fromToken} toToken = ${toToken}`);
		console.log(gamePools);
		
		const token = isRealToken(fromToken) ? toToken : fromToken;
		const l = token.split('.').length;
        let team = token.split('.')[0];
        const sport = token.split('.')[l - 2];
        const week = token.split('.')[l - 1];
        for (let i = 0; i < gamePools.length; i++) {
            const g = gamePools[i];
            if (g.homeTeam === team && g.sport == sport && g.week == week) {
                return(g.wagerToken);
            } else if (g.awayTeam === team && g.sport == sport && g.week == week) {
                return(g.wagerToken);
            } else if (g.tieTeamToken && team == 'DRAW' && g.sport == sport && g.week == week) {
                return(g.wagerToken);
            }
        }
        return('');
    }

	const setSpecificUserTokenBalances = async (tokens: PublicKey[], address: string) => {
		// console.log(`setSpecificUserTokenBalances`);
		const d: any = {...userTokenBalances};
		for (let i = 0; i < tokens.length; i++) {
			const token = tokens[i];
			let userBalance = 0;
			try {
				userBalance = await new Mint(connection, token).getBalance( new PublicKey(address) );
			} catch (error) {
			}
			d[token.toString()] = userBalance;
		}
		// console.log(`setSpecificUserTokenBalances for address ${address}`);
		// console.log(d);
		setUserTokenBalances(d);
	}

    // useEffect(() => {
	// 	// console.log(`useEffect`);
	// 	// console.log(data);
	// 	// const tokens = swapObject.tokens.map(  )
    //     // if ( data.swapToToken && swapObject.tokens.includes(data.swapToToken) ) {
    //     //     if (toToken !== data.swapToToken) {
    //     //         setToToken(data.swapToToken);
	// 	// 		if (!isRealToken(data.swapToToken)) {
	// 	// 			if (swapObject.gamePool && ADDRESS_TO_TOKEN_MAP.hasOwnProperty(swapObject.gamePool.wagerToken)) {
	// 	// 				setFromToken(ADDRESS_TO_TOKEN_MAP[swapObject.gamePool.wagerToken]);
	// 	// 			} else {
	// 	// 				setFromToken('SOL');
	// 	// 			}
	// 	// 		}
    //     //     }
    //     //     dispatch(actions.setSwapToToken(''));
    //     // }
	// 	// if ( data.swapFromToken && swapObject.tokens.includes(data.swapFromToken) ) {
    //     //     if (fromToken !== data.swapFromToken) {
    //     //         setFromToken(data.swapFromToken);
	// 	// 		if (!isRealToken(data.swapFromToken)) {
	// 	// 			setToToken('SOL');
	// 	// 		}
    //     //     }
    //     //     dispatch(actions.setSwapFromToken(''));
    //     // }
    // }, [])


    useEffect(() => {
		// console.log(`useEffect 2`);
		if (data.address) {
			setSpecificUserTokenBalances([MSOL_MINT, USDH_MINT], data.address);
		}
		
		// const mSolBalance = data.address ? await new Mint(connection, MSOL_MINT).getBalance( new PublicKey(address) ) : 0
		const mSolGamePool = new GamePoolWithDetail();
		mSolGamePool.wagerToken = MSOL_MINT.toString();

		const usdhGamePool = new GamePoolWithDetail();
		usdhGamePool.wagerToken = USDH_MINT.toString();

		const pbtGamePool = new GamePoolWithDetail();
		pbtGamePool.wagerToken = PBT_MINT.toString();

		const tokens: TokenObject[] = [
			new TokenObject('SOL', true)
			, new TokenObject('mSOL', true, mSolGamePool, Outcome.Home, 'mSOL', 'mSOL', 0)
			, new TokenObject('USDH', true, usdhGamePool, Outcome.Home, 'USDH', 'USDH', 0)
		];

		// const mSolBalance = data.address ? await new Mint(connection, MSOL_MINT).getBalance( new PublicKey(address) ) : 0
		// const tokens: TokenObject[] = [
		// 	new TokenObject('SOL', true)
		// 	, new TokenObject('mSOL', true, new GamePoolWithDetail(), Outcome.Home, 'mSOL', 'mSOL', 0)
		// ];
		const playedTokens: string[] = []
		for (let i = 0; i < data.gamePools.length; i++) {
			const gamePool: GamePoolWithDetail = data.gamePools[i];
			const not_started = gamePool.gameStartTime > (getCurrentTimestamp() - (60 * 1));
			const has_tokens = (gamePool.userAwayTokenBalance + gamePool.userHomeTokenBalance) > 0;
			const homeToken = convertToTokenName(gamePool.homeTeam, gamePool.week, gamePool.sport);
			const awayToken = convertToTokenName(gamePool.awayTeam, gamePool.week, gamePool.sport);
			const tieToken = convertToTokenName(`DRAW.${gamePool.awayTeam}-${gamePool.homeTeam}`, gamePool.week, gamePool.sport);
			const homeTokenObject = new TokenObject(homeToken, false, gamePool, Outcome.Home, gamePool.homeTeam, gamePool.wagerToken, gamePool.userHomeTokenBalance);
			const awayTokenObject = new TokenObject(awayToken, false, gamePool, Outcome.Away, gamePool.awayTeam, gamePool.wagerToken, gamePool.userAwayTokenBalance);
			const tieTokenObject = new TokenObject(tieToken, false, gamePool, Outcome.Tie, 'DRAW', gamePool.wagerToken, gamePool.userTieTokenBalance);
			
			if (not_started) {
				tokens.push(homeTokenObject);
				tokens.push(awayTokenObject);
				if(gamePool.tiePoolAccount) {
					tokens.push(tieTokenObject);
				}
			} else {
				if(gamePool.userAwayTokenBalance) {
					tokens.push(awayTokenObject);
				}
				if(gamePool.userHomeTokenBalance) {
					tokens.push(homeTokenObject);
				}
				if(gamePool.userTieTokenBalance) {
					tokens.push(tieTokenObject);
				}
			}
			const isPlayed = gamePool.homeTeamTie !== undefined || gamePool.homeTeamWin !== undefined;
			if (isPlayed) {
				playedTokens.push(homeToken);
				playedTokens.push(awayToken);
				playedTokens.push(tieToken);
			}
		}
		
		tokens.sort( (a, b) => b.isRealToken ? 1 : getTokenBalance(data.gamePools, b.tokenString) - getTokenBalance(data.gamePools, a.tokenString) );
		// console.log(`tokens`);
		// console.log(tokens);

		let newSwapObject = {
			...swapObject
			, 'tokens': tokens
		}

		const tokenNames = swapObject.tokens.map( x => x.tokenString );
		// console.log(`data.swapObject`);
		// console.log(data.swapObject);
		
        if ( data.swapObject ) {
			newSwapObject = {
				...data.swapObject
				, 'tokens': tokens
			}
            dispatch(actions.setSwapObject(null));
        }
		// const userMsolBalance = data.address ? await connection.getBalance(new PublicKey(address)) / LAMPORTS_PER_SOL : 0;

		// if ( data.swapFromToken && swapObject.tokens.includes(data.swapFromToken) ) {
        //     if (fromToken !== data.swapFromToken) {
        //         setFromToken(data.swapFromToken);
		// 		if (!isRealToken(data.swapFromToken)) {
		// 			setToToken('SOL');
		// 		}
        //     }
        //     dispatch(actions.setSwapFromToken(''));
        // }		
		
		// let tmpFrom = tokens[0];
		// let tmpTo = tokens.length > 1 ? tokens[1] : tokens[0];
		// const gamePools = data.gamePools.sort((a, b) => a.wagerToken == DUST_MINT.toString() ? 1 : -1 );
		// console.log(`gamePools`);
		// console.log(gamePools);
		
		// if (gamePools.length) {
		// 	console.log(gamePools[0]);
		// 	tmpTo = convertToTokenName(gamePools[0].homeTeam, gamePools[0].week, gamePools[0].sport);
		// 	console.log(`tmpTo = ${tmpTo}`);
		// 	tmpFrom = 'SOL'
		// 	if (ADDRESS_TO_TOKEN_MAP.hasOwnProperty(data.gamePools[0].wagerToken)) {
		// 		tmpFrom = ADDRESS_TO_TOKEN_MAP[data.gamePools[0].wagerToken];
		// 	}
		// 	console.log(`tmpFrom = ${tmpFrom}`);
		// }
		
		setSwapObject(newSwapObject);
		
    }, [data.gamePools])

	const getTeamImgAll = (tokenObject: TokenObject) => {
		const img = tokenObject.isRealToken ? tokenObject.tokenString
		: tokenObject.outcome == Outcome.Tie ? 'DRAW_TOKEN'
		: getTeamImg(tokenObject.gamePool?.sport, tokenObject.team)
		// console.log(`getTeamImgAll`);
		// console.log(tokenObject);
		// console.log(img);
		
		return(img);
	}

	const fromToken = swapObject.isToTeamToken ? ADDRESS_TO_TOKEN_MAP[swapObject.selectedToken.requiredTokenString] : swapObject.selectedToken.tokenString;
    const toToken = swapObject.isToTeamToken ? swapObject.selectedToken.tokenString : ADDRESS_TO_TOKEN_MAP[swapObject.selectedToken.requiredTokenString];

    let swapAmount = parseFloat(fromAmountText);
    swapAmount = swapAmount ? swapAmount : 0;
    const fromTeam = swapObject.isToTeamToken ? ADDRESS_TO_TOKEN_MAP[swapObject.selectedToken.requiredTokenString] : swapObject.selectedToken.team;
    // const fromTeam = isRealToken(fromToken) ? fromToken : fromToken.split('.')[0];
	// const useFromTeam = fromTeam == 'DRAW' ? fromToken.split('.')[1].split('-')[0] : fromTeam;
    const fromSport = swapObject.selectedToken.gamePool?.sport
    const toTeamImg = swapObject.isToTeamToken 
	? (swapObject.selectedToken.outcome == Outcome.Tie ? 'DRAW_TOKEN' : getTeamImgAll(swapObject.selectedToken))
	: ADDRESS_TO_TOKEN_MAP[swapObject.selectedToken.requiredTokenString];
	// console.log(`toTeamImg = ${toTeamImg}`);
	// console.log(swapObject);
	
	// console.log(`swapObject.selectedToken.requiredTokenString = ${swapObject.selectedToken.requiredTokenString}`);
	// console.log(ADDRESS_TO_TOKEN_MAP);
	

    let toTeam = swapObject.isToTeamToken ? swapObject.selectedToken.team : ADDRESS_TO_TOKEN_MAP[swapObject.selectedToken.requiredTokenString];
	// const useToTeam = toTeam == 'DRAW' ? toToken.split('.')[1].split('-')[0] : toTeam;
	// console.log(`toTeam = ${toTeam}`);
    const toSport = swapObject.selectedToken.gamePool?.sport
    const fromTeamImg = swapObject.isToTeamToken 
	? ADDRESS_TO_TOKEN_MAP[swapObject.selectedToken.requiredTokenString]
	: (swapObject.selectedToken.outcome == Outcome.Tie ? 'DRAW_TOKEN' : getTeamImgAll(swapObject.selectedToken));

	// console.log(`fromTeamImg = ${fromTeamImg}`);
	// console.log(`toTeamImg = ${toTeamImg}`);
	

	const requiredToken = swapObject.selectedToken.requiredTokenString;

    const toProps = !swapObject.isToTeamToken;
	// console.log(`toProps = ${toProps}`);	
    let team = toProps ? fromTeam : toTeam;
    let useTeam = toProps ? fromTeam : toTeam;
	// team = fromSport == 'NBA' || toSport == 'NBA'  ? `${team}.${ toProps ? fromToken.split('.')[1] : toToken.split('.')[1] }` : team;
    const sport = toProps ? fromSport : toSport;
    // let weekArr = (toProps ? fromToken : toToken).split('.')
	let week = swapObject.selectedToken.gamePool?.week;

	const other = getOpposingToken(team, week, sport, data.gamePools);
	const otherBalance = getTokenBalance(data.gamePools, other);
    let selectedGamePool = swapObject.selectedToken.gamePool;
	// console.log(`selectedGamePool`);
	// console.log(selectedGamePool);
	
	const wagerToken = selectedGamePool.wagerToken ? new PublicKey(selectedGamePool.wagerToken) : SOL_ADDRESS;
	const wagerTokenMint: Mint = new Mint(connection, wagerToken);

	const buyHome = selectedGamePool.homeTeam === team;
	const buyAway = selectedGamePool.awayTeam === team;
	const outcome: Outcome = buyHome ? Outcome.Home : buyAway ? Outcome.Away : Outcome.Tie;
    // const numBonus = selectedGamePool.numBonus;
    const buyValue = outcome == Outcome.Home ? selectedGamePool.homeTokenValue : outcome == Outcome.Tie ? selectedGamePool.tieTokenValue : selectedGamePool.awayTokenValue;
    const buySupply = outcome == Outcome.Home ? selectedGamePool.homeTokenSupply : outcome == Outcome.Tie ? selectedGamePool.tieTokenSupply : selectedGamePool.awayTokenSupply;
    const buyProps = outcome == Outcome.Home ? selectedGamePool.homeWagerTokenBalance : outcome == Outcome.Tie ? selectedGamePool.tieWagerTokenBalance : selectedGamePool.awayWagerTokenBalance;
    const otherSupply1 = outcome == Outcome.Home ? selectedGamePool.tieTokenSupply : outcome == Outcome.Tie ? selectedGamePool.awayTokenSupply : selectedGamePool.homeTokenSupply;
    const otherSupply2 = outcome == Outcome.Home ? selectedGamePool.awayTokenSupply : outcome == Outcome.Tie ? selectedGamePool.homeTokenSupply : selectedGamePool.tieTokenSupply;
    const otherProps1 = outcome == Outcome.Home ? selectedGamePool.tieWagerTokenBalance : outcome == Outcome.Tie ? selectedGamePool.awayWagerTokenBalance : selectedGamePool.homeWagerTokenBalance;
    const otherProps2 = outcome == Outcome.Home ? selectedGamePool.awayWagerTokenBalance : outcome == Outcome.Tie ? selectedGamePool.homeWagerTokenBalance : selectedGamePool.tieWagerTokenBalance;
	
    let fees = 0, slippage = 0, effectivePrice = 0;
    let resAmount: number = 0;
    let impliedOdds = 0.0;
    let estBonus = 0.0;
	
    if (toProps && selectedGamePool.gameId) {
		// console.log(`selectedGamePool`);
		// console.log(selectedGamePool);
		// console.log(`outcome = ${outcome}`);
		// console.log(`buySupply = ${buySupply}`);
		// console.log(`buyProps = ${buyProps}`);
		// console.log(`otherSupply1 = ${otherSupply1}`);
		// console.log(`otherSupply2 = ${otherSupply2}`);
		// console.log(`otherProps1 = ${otherProps1}`);
		// console.log(`otherProps2 = ${otherProps2}`);
		// console.log(`buyValue = ${buyValue}`);
		
		[resAmount, fees, slippage, effectivePrice, estBonus] = calcEsimatedPROPSAmount(swapAmount, buySupply, otherSupply1, otherSupply2, buyProps, otherProps1, otherProps2, 0, FEE_PCT);
		// if(selectedGamePool.homeBonusValue || selectedGamePool.awayBonusValue) {
		// 	estBonus = (buyBonusValue ? buyBonusValue : 0) * swapAmount;
		// }
		// console.log(`resAmount = ${resAmount}. effectivePrice = ${effectivePrice}`);
		
		
        if ( swapObject.gamePool && (swapObject.gamePool?.homeTokenValue || swapObject.gamePool?.awayTokenValue || swapObject.gamePool?.tieTokenValue) ) {
            effectivePrice = (buyValue ?? 0);
            // effectivePrice = p;
            fees = 0;
            slippage = 0;
            resAmount = swapAmount * effectivePrice;
        }
		// console.log(`resAmount = ${resAmount}.`);
        impliedOdds = calcOdds(buySupply - swapAmount, otherSupply1, otherSupply2, buyProps - resAmount, otherProps1, otherProps2);
	} else if (selectedGamePool.gameId) {
		// eslint-disable-next-line
        [resAmount, fees, slippage, effectivePrice] = calcEsimatedAmount(swapAmount, buySupply, otherSupply1, otherSupply2, buyProps, otherProps1, otherProps2, FEE_PCT);
		// console.log(`resAmount = ${resAmount}`);
		
        // [resAmount, fees, slippage, effectivePrice] = calcEsimatedAmountOg(swapAmount, buySupply, otherSupply1, otherSupply2, buyProps, otherProps1, otherProps2, selectedGamePool.feeRate);
        impliedOdds = calcOdds(buySupply + resAmount, otherSupply1, otherSupply2, buyProps + swapAmount, otherProps1, otherProps2);
		// if (selectedGamePool.gameId == '5ee78ae20ac0510e4a78e03cb1c41e18') {
		// 	console.log({
		// 		'buySupply': buySupply
		// 		, 'buySupply + resAmount': buySupply + resAmount
		// 		, 'otherSupply1': otherSupply1
		// 		, 'otherSupply2': otherSupply2
		// 		, 'buyProps + swapAmount': buyProps + swapAmount
		// 		, 'otherProps1': otherProps1
		// 		, 'otherProps2': otherProps2
		// 		, 'impliedOdds': impliedOdds
		// 	});
		// }
    }
	// console.log(`resAmount = ${resAmount}`);

	const USOdds = impliedOdds>=0.5 ? `-${(((impliedOdds*100)/(1-impliedOdds)).toFixed(0))}` : `+${(((100/(impliedOdds))-100).toFixed(0))}`;
	const decOdds = ((1/impliedOdds).toFixed(2));

    const resAmountTxt = resAmount ? resAmount.toLocaleString('en-US', {maximumFractionDigits: 4}) : '';
    if (resAmountTxt !== toAmountText) {
        setToAmountText( resAmountTxt );
    }

    const noProps = swapObject.selectedToken.team == swapObject.selectedToken.requiredTokenString
    const noToken = swapObject.selectedToken.team == swapObject.selectedToken.requiredTokenString;

	const getTeamTokenBalance = () => {
		if (!swapObject.gamePool) return(0);
		if (swapObject.outcome == Outcome.Home) return(swapObject.gamePool.userHomeTokenBalance);
		if (swapObject.outcome == Outcome.Away) return(swapObject.gamePool.userAwayTokenBalance);
		return(swapObject.gamePool.userTieTokenBalance);
	}

	const getUserBalance = (isFrom: boolean) => {
		// console.log(`getUserBalance isFrom = ${isFrom}`);
		// console.log(swapObject);
		
		if (isRealToken(swapObject.selectedToken.team)) {
			if (swapObject.selectedToken.team == 'SOL') {
				return(data.userPropsBalance);
			}
			if (swapObject.gamePool) {
				const bal = getSpecificUserTokenBalances(swapObject.gamePool.wagerToken)
				// console.log(`bal for ${swapObject.gamePool.wagerToken} = ${bal}`);
				return(bal);
			}
		}
		if ((swapObject.isToTeamToken && isFrom) || (!swapObject.isToTeamToken && !isFrom)) {
			// get user real token balance
			if (swapObject.gamePool) {
				if (swapObject.gamePool.wagerToken == SOL_ADDRESS.toString()) {
					return(data.userPropsBalance);
				} else {
					const bal = getSpecificUserTokenBalances(swapObject.gamePool.wagerToken)
					// console.log(`bal for ${swapObject.gamePool.wagerToken} = ${bal}`);
					return(bal);
				}
			}
			return(data.userPropsBalance)
		}
		return(getTeamTokenBalance());
	}

    const fromBalanceFloat = getUserBalance(true);
	// console.log(`fromBalanceFloat = ${fromBalanceFloat}`);
	
    const fromBalance = fromBalanceFloat.toLocaleString('en-US', {maximumFractionDigits: 4});

	const toBalanceFloat = getUserBalance(false);
	// console.log(`toBalanceFloat = ${toBalanceFloat}`);
	const netToAmount = resAmount + toBalanceFloat;
    const toBalance = toBalanceFloat.toLocaleString('en-US', {maximumFractionDigits: 4});
    const isGameStarted = swapObject.gamePool ? swapObject.gamePool.gameStartTime < (Date.now() / 1000) : false;
	const pairNotSupported = ![TOKEN_MAP[fromToken], TOKEN_MAP[toToken]].includes(requiredToken);
	
	const hasOverUserTokenLimit = isRealToken(toToken) ? false : netToAmount > selectedGamePool.userTeamTokenCap;
	const hasOverPoolTokenLimit = isRealToken(toToken) ? false : swapAmount + selectedGamePool.homeWagerTokenBalance + selectedGamePool.awayWagerTokenBalance > selectedGamePool.poolWagerTokenCap;
	let mxFrom = selectedGamePool.poolWagerTokenCap - selectedGamePool.homeWagerTokenBalance - selectedGamePool.awayWagerTokenBalance;
	mxFrom = mxFrom ? mxFrom : 0;
	const mxTo = selectedGamePool.userTeamTokenCap - toBalanceFloat;
	// const gameCompleted = (swapObject.gamePool ?? false) && (swapObject.gamePool.homeTokenValue ?? 0 > 0 || swapObject.gamePool.awayTokenValue ?? 0 > 0 || swapObject.gamePool.tieTokenValue ?? 0 > 0);
	const gameCompleted = (swapObject.gamePool ?? false) && ((swapObject.gamePool?.homeTokenValue ?? 0) > 0 || (swapObject.gamePool?.awayTokenValue ?? 0) > 0 || (swapObject.gamePool?.tieTokenValue ?? 0) > 0);
	const gameInProgress = isGameStarted && !gameCompleted;
	
	
    const isDisabled = (!(swapAmount > 0)) 
        || gameInProgress
        || isSwapping 
        || fromTeam === toTeam 
        || pairNotSupported 
        || noProps 
        || noToken 
        // || playedTokens.includes(toToken) 
		|| selectedGamePool.gameCancelled && swapObject.isToTeamToken
        || (!selectedGamePool.gameCancelled && selectedGamePool.awayWagerTokenBalance > 0 && selectedGamePool.homeWagerTokenBalance > 0 && selectedGamePool.homeTokenValue != null)
        || swapAmount > fromBalanceFloat
        || hasOverUserTokenLimit
        || hasOverPoolTokenLimit;
	// const isDisabled = false;
	const disabledText = !isDisabled ? ''
		: pairNotSupported ? `This pair is not supported${ requiredToken && ADDRESS_TO_TOKEN_MAP.hasOwnProperty(requiredToken) ? `. You must use ${ADDRESS_TO_TOKEN_MAP[requiredToken]}.` : '' }`
		: hasOverUserTokenLimit ? `You can't have over ${(selectedGamePool.userTeamTokenCap).toLocaleString('en-US', {maximumFractionDigits: 2})} tokens of one game!`
		: hasOverPoolTokenLimit ? `Pool has reached the wager token limit`
		: noToken ? `Please select a team token`
		: noProps ? `Please select a token to swap`
		: selectedGamePool.gameCancelled && swapObject.isToTeamToken ? `Game is cancelled`
		: gameInProgress ? `Buying period is over`
		: swapAmount > fromBalanceFloat ? `Not enough funds`
		// : playedTokens.includes(toToken) ? `Buying period is over`
		: (!selectedGamePool.gameCancelled && selectedGamePool.awayWagerTokenBalance > 0 && selectedGamePool.homeWagerTokenBalance > 0 && selectedGamePool.homeTokenValue != null) ? `Buying period is over`
		: ''
	;
	const getFromToken = () => {
		// if we are to team token, we must be from wager token
		if (swapObject.isToTeamToken) {
			if (swapObject.gamePool) {
				return( ADDRESS_TO_TOKEN_MAP[swapObject.gamePool.wagerToken]);
			}
			return( 'SOL' );
		}
		// check the current outcome
		if (swapObject.gamePool) {
			if (swapObject.outcome == Outcome.Home) return(swapObject.gamePool.homeTeam)
		}
		return( 'SOL' );
	}

	const fromBalanceDiv = 
		<div className='col balance'>
			Balance: {fromBalance}
			{ fromBalance !== '0.00' ? <span onClick={() => { 
				const mx = calcEsimatedPropsNeeded(mxFrom, mxTo, buySupply, otherSupply1, otherSupply2, buyProps, otherProps1, otherProps2, selectedGamePool.feeRate);
				const val = isRealToken(toToken) ? fromBalanceFloat : Math.min(mx ? mx : 0, fromBalanceFloat);
				setFromAmountText(val.toString())
			}} className='max-button'>Max</span> : null }
		</div>
    
	return (
        <div className='swap-page'>
			<div className='row'>
				<div className='col col-lg-7'>
					<div className='swap-panel panel'>
						<div className='swap-panel-inner panel'>
							<div className='row row-1'>
								{ isMobile ? null : <div className='col pay'>Pay</div>}
								{ isMobile ? null : fromBalanceDiv }
							</div>
							<div className='row'>
								<div className='col-12 col-lg-7'>
									<Button onClick={() => setShow(true)} className='token-button'>
										<div className='token-img'>
											<img src={images[`${fromTeamImg}`]} alt='from-team' />
										</div>
										<span className='currency-text'>{fromToken}</span>
										<FaChevronDown />
									</Button>
								</div>
								<div className='col-12 col-lg-5'>
									<div className='input-container'>
										<div className='inner-container'>
											<input placeholder='0.00' value={fromAmountText} onChange={evt => setFromAmountText(evt.target.value)}/>
										</div>
									</div>
								</div>
							</div>
							<div className='row row-1'>
								{ !isMobile ? null : fromBalanceDiv }
							</div>
							<div className='row' style={{'paddingTop': '1.5rem', 'paddingBottom': '0.75rem'}}>
								<button onClick={() => {
									const newTo = fromToken;
									const newFrom = toToken;
									// setFromToken(newFrom);
									// setToToken(newTo);
									const s = swapObject.isToTeamToken;
									setSwapObject({
										...swapObject,
										'isToTeamToken': !s
									})
								}} className='swap-arrow'>
									<img src={images['SWAP_ARROWS']} />
								</button>
							</div>
							<div className='row no-padding-bottom'>
								{ isMobile ? null : <div className='col pay'>Receive (Estimate)</div>}
								{ isMobile ? null : <div className='col balance'>Balance: {toBalance}</div>}
							</div>
							<div className='row'>
								<div className='col-12 col-lg-7'>
									<Button onClick={() => setToShow(true)} className='token-button'>
										<div className='token-img'>
											<img src={images[`${toTeamImg}`]} alt='to-team' />
										</div>
										<span className='currency-text'>{toToken}</span>
										<FaChevronDown />
									</Button>
								</div>
								<div className='col-12 col-lg-5'>
									<div className='input-container'>
										<div className='inner-container'>
											<input className='color-gray-1' disabled placeholder='0.00' value={gameInProgress ? '0.00' : toAmountText} />
										</div>
									</div>
								</div>

								<div className='row no-padding-bottom'>
									{ !isMobile ? null : <div className='col balance'>Balance: {toBalance}</div>}
								</div>
							</div>
							<div className='row' style={{'paddingTop': '4rem'}}>
								{
									disabledText ? <div style={{'paddingBottom': '1.2rem'}}>{disabledText}</div>
									:
										<>
											{
												USE_TOKENS.includes( toToken )
												? <div style={{'paddingBottom': '0.4rem'}}>
													1 ${fromToken} ≈ { effectivePrice ? (( 1 / effectivePrice)).toFixed(3) : 0} ${toToken}
												</div>
												: <div style={{'paddingBottom': '0.4rem'}}>
													1 ${toToken} ≈ {effectivePrice.toFixed(3)} ${fromToken}
												</div>

											}
											{
												gameInProgress || data.odds!=="Percentage" || gameCompleted ? null
												: <div style={{'paddingBottom': '0.4rem'}}>Implied Win Probability: { false && slippage * 100 < 0.01 ? '<0.01' : (impliedOdds * 100).toFixed(2)}%</div>
											}
											{
												gameInProgress || data.odds!=="American" || gameCompleted ? null
												: <div style={{'paddingBottom': '0.4rem'}}>Odds: { USOdds }</div>
											}
											{
												gameInProgress || data.odds!=="Decimal" || gameCompleted ? null
												: <div style={{'paddingBottom': '0.4rem'}}>Odds: { decOdds }</div>
											}
											{
												gameInProgress || gameCompleted ? null
												: <div style={{'paddingBottom': '0.4rem'}}>Price Impact: { slippage * 100 < 0.01 ? '<0.01' : (slippage * 100).toFixed(2)}%</div>
											}
										</>
								}
								<Button disabled={ isDisabled } onClick={() => {
									swapTeamTokensNew(selectedGamePool, outcome, swapAmount, !toProps, gameCompleted, resAmount);
								}} className='confirm-swap-button props-button'>
									{ isSwapping ? 'Swapping...' : 'Swap'}
								</Button>
							</div>
							<div className='error'>
								{errorText ? errorText : null}
							</div>
						</div>
					</div>
				</div>
				{
					isMobile ? null :
					<div className='col col-lg-5'>
						<div className='swap-panel panel'>
							<div className='swap-panel-inner panel'>
								<RecentTradesPanel />
							</div>
						</div>
					</div>
				}
			</div>
            <Modal
            show={show}
            onHide={() => setShow(false)}
            >
                <Modal.Body>
                    <div className='token-search'>
                        <div className='search-icon'>
                            <IoSearchOutline />
                        </div>
                        <div className=''>
                            <input className='color-white' placeholder='Search by token' value={fromModalSearchText} onChange={evt => setFromModalSearchText(evt.target.value)}/>
                        </div>
                    </div>
                    <div className=''>
                    {
                        swapObject.tokens.filter( (x: TokenObject) => x.tokenString.toLowerCase().includes(fromModalSearchText.toLowerCase()) )
                            .map((t: TokenObject, i: number) => 
                            {
                                const img = getTeamImgAll(t)
                                const balance = (t.team === 'SOL' ? data.userPropsBalance : t.isRealToken ? getSpecificUserTokenBalances(t.tokenString) : getTokenBalance(data.gamePools, t.tokenString)).toLocaleString('en-US', {maximumFractionDigits: 4});
								const tname = t.tokenString;
                                return(
                                    <div key={i} className='flex justify-between items-center py-4 hover:bg-th-bkg-4 cursor-pointer px-6' onClick={() => {
                                        setShow(false);
										// setTokenObject(t);
										setSwapObject({
											...swapObject
											, 'gamePool': t.gamePool
											, 'outcome': t.outcome
											, 'isToTeamToken': false
											, 'selectedToken': t
										})
                                    }}>
                                        <div className='flex items-center'>
											<div className='token-img'>
												<img src={images[img]} alt='token-img' />
											</div>
                                            <div className='ml-4'>
                                                <div className='text-th-fgd-2'>
                                                    {tname}
                                                </div>
                                                <div className='text-th-fgd-4'>
                                                    {balance}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        )
                    }
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
            show={toShow}
            onHide={() => setToShow(false)}
            >
                <Modal.Body>
                    <div className='token-search'>
                        <div className='search-icon'>
                            <IoSearchOutline />
                        </div>
                        <div className=''>
                            <input placeholder='Search by token' value={toModalSearchText} onChange={evt => setToModalSearchText(evt.target.value)}/>
                        </div>
                    </div>
                    <div className=''>
                    {
                        swapObject.tokens.filter( (x: TokenObject) => x.tokenString.toLowerCase().includes(toModalSearchText.toLowerCase()) ).map((t: TokenObject, i: number) => 
                            {
                                const img = getTeamImgAll(t)
                                const balance = (t.team === 'SOL' ? data.userPropsBalance : t.isRealToken ? getSpecificUserTokenBalances(t.tokenString) : getTokenBalance(data.gamePools, t.tokenString)).toLocaleString('en-US', {maximumFractionDigits: 4});
								const tname = t.tokenString;
                                return(
                                    <div key={i} className='flex justify-between items-center py-4 hover:bg-th-bkg-4 cursor-pointer px-6' onClick={() => {
                                        setToShow(false);
										setSwapObject({
											...swapObject
											, 'gamePool': t.gamePool
											, 'outcome': t.outcome
											, 'isToTeamToken': true
											, 'selectedToken': t
										})
                                    }}>
                                        <div className='flex items-center'>
											<div className='token-img'>
												<img src={images[img]} alt='token-img' />
											</div>
                                            <div className='ml-4'>
                                                <div className='text-th-fgd-2'>
                                                    {tname}
                                                </div>
                                                <div className='text-th-fgd-4'>
                                                    {balance}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        )
                    }
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer />
        </div>
	)
	return(
		<div></div>
	)
};

export default SwapPage;

