import React from 'react'
import { connect } from 'react-redux';
import images from '../static/images';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import * as actions from '../store/actions/actions';
import { GamePoolWithDetail } from '../models/GamePoolWithDetail';
import { convertToTokenName, timeConverter, calcPrice, calcOdds, getTeamImg } from '../utils/utils';
import { ADDRESS_TO_TOKEN_MAP, MSOL_MINT, SOL_ADDRESS, USDH_MINT } from '../constants';
import { TokenObject } from '../models/TokenObject';
import { Outcome } from '../models/Outcome';
import { SwapObject } from '../models/SwapObject';
import { isMobile } from '../constants';

interface IState {
	result: string;
	homeInput: string;
	awayInput: string;
	homeTokenInput: string;
	awayTokenInput: string;
	userHomeTokenBalance: number;
	userAwayTokenBalance: number;
	awayWagerTokenBalance: number;
}


const getRequiredToken = (gamePools: GamePoolWithDetail[], token: string) => {
	const l = token.split('.').length;
	let team = token.split('.')[0];
	const sport = token.split('.')[l - 2];
	const week = token.split('.')[l - 1];
	for (let i = 0; i < gamePools.length; i++) {
		const g = gamePools[i];
		if (g.homeTeam === team && g.sport == sport && g.week == week) {
			return(g.wagerToken);
		} else if (g.awayTeam === team && g.sport == sport && g.week == week) {
			return(g.wagerToken);
		}
	}
	return('');
}

// the view that shows each pool the users can bet on
class GamePoolCard extends React.Component<any, IState> {
	constructor(props: any) {
		super(props);
		this.state = {
			result: 'win',
			homeInput: '',
			awayInput: '',
			homeTokenInput: '',
			awayTokenInput: '',
			userHomeTokenBalance: 0,
			userAwayTokenBalance: 0,
			awayWagerTokenBalance: 0,
		}
	}

	componentDidUpdate = (prevProps: any) => {
		const userAwayTokenBalance = this.state.userAwayTokenBalance;
		const userHomeTokenBalance = this.state.userHomeTokenBalance;
		const awayWagerTokenBalance = this.state.awayWagerTokenBalance;
		if(userHomeTokenBalance !== this.props.gamePool.userHomeTokenBalance) {
			if (this.props.gamePool.userHomeTokenBalance !== undefined) {
				this.setState({userHomeTokenBalance: this.props.gamePool.userHomeTokenBalance});
			}
		}
		if(userAwayTokenBalance !== this.props.gamePool.userAwayTokenBalance) {
			if (this.props.gamePool.userAwayTokenBalance !== undefined) {
				this.setState({userAwayTokenBalance: this.props.gamePool.userAwayTokenBalance});
			}
		}
		if(awayWagerTokenBalance !== this.props.gamePool.awayWagerTokenBalance) {
			if (this.props.gamePool.awayWagerTokenBalance !== undefined) {
				this.setState({awayWagerTokenBalance: this.props.gamePool.awayWagerTokenBalance});
			}
		}
	}

	handleResultChange = (evt: any) => {
		this.setState({result: evt.target.value});
	}

	componentDidMount = () => {
		
		this.setState({
			userHomeTokenBalance: this.props.gamePool.userHomeTokenBalance,
			userAwayTokenBalance: this.props.gamePool.userAwayTokenBalance,
			awayWagerTokenBalance: this.props.gamePool.awayWagerTokenBalance,
		})
	}

	updateHomeInput(evt: any) {
		this.setState({
			homeInput: evt.target.value
		});
	}

	updateHomeTokenInput(evt: any) {
		this.setState({
			homeTokenInput: evt.target.value
		});
	}

	updateAwayTokenInput(evt: any) {
		this.setState({
			awayTokenInput: evt.target.value
		});
	}

	updateAwayInput(evt: any) {
		this.setState({
			awayInput: evt.target.value
		});
	}

	render = () => {
		const gamePool: GamePoolWithDetail = this.props.gamePool;
		// console.log(gamePool);
		
		
		const g = gamePool;
		// console.log('gamePool');
		// console.log(g);

		const homeTokenPrice = calcPrice(g.homeTokenSupply, g.awayTokenSupply, g.tieTokenSupply, g.homeWagerTokenBalance, g.awayWagerTokenBalance, g.tieWagerTokenBalance);
        const awayTokenPrice = calcPrice(g.awayTokenSupply, g.homeTokenSupply, g.tieTokenSupply, g.awayWagerTokenBalance, g.homeWagerTokenBalance, g.tieWagerTokenBalance);
        const tieTokenPrice = calcPrice(g.tieTokenSupply, g.awayTokenSupply, g.homeTokenSupply, g.tieWagerTokenBalance, g.awayWagerTokenBalance, g.homeWagerTokenBalance);
        let homeOdds = calcOdds(g.homeTokenSupply, g.awayTokenSupply, g.tieTokenSupply, g.homeWagerTokenBalance, g.awayWagerTokenBalance, g.tieWagerTokenBalance);
        // let homeOdds = calcOdds(g.homeTokenSupply, g.tieTokenSupply, g.awayTokenSupply, g.homeWagerTokenBalance, g.tieWagerTokenBalance, g.awayWagerTokenBalance);
        let awayOdds = calcOdds(g.awayTokenSupply, g.homeTokenSupply, g.tieTokenSupply, g.awayWagerTokenBalance, g.homeWagerTokenBalance, g.tieWagerTokenBalance);
        let tieOdds = calcOdds(g.tieTokenSupply, g.awayTokenSupply, g.homeTokenSupply, g.tieWagerTokenBalance, g.awayWagerTokenBalance, g.homeWagerTokenBalance);
		// if (g.gameId == '5ee78ae20ac0510e4a78e03cb1c41e18') {
		// 	console.log({
		// 		'buySupply': g.homeTokenSupply
		// 		, 'otherSupply1': g.awayTokenSupply
		// 		, 'otherSupply2': g.tieTokenSupply
		// 		, 'buyProps + swapAmount': g.homeWagerTokenBalance
		// 		, 'otherProps1': g.awayWagerTokenBalance
		// 		, 'otherProps2': g.tieWagerTokenBalance
		// 		, 'impliedOdds': homeOdds
		// 	});
		// }
		// const tot = homeOdds + awayOdds + tieOdds;
		// homeOdds = homeOdds / tot;
		// awayOdds = awayOdds / tot;
		// tieOdds = tieOdds / tot;

        // const awayTokenPrice = 1 - homeTokenPrice;
		// console.log(`homeTokenSupply = ${g.homeTokenSupply}`);
		// console.log(`awayTokenSupply = ${g.awayTokenSupply}`);
		// console.log(`tieTokenSupply = ${g.tieTokenSupply}`);
		// console.log(`homeWagerTokenBalance = ${g.homeWagerTokenBalance}`);
		// console.log(`awayWagerTokenBalance = ${g.awayWagerTokenBalance}`);
		// console.log(`tieWagerTokenBalance = ${g.tieWagerTokenBalance}`);
		// console.log(`homeTokenPrice = ${g.homeTokenPrice}`);

        const isGamePlayed = (g.homeTeamWin != null && g.homeTeamTie != null);

		const playedOddsHome = g.homeTeamWin ? 1 : g.homeTeamTie && !g.tiePoolAccount ? 0.5 : 0;
		const playedOddsAway = (!g.homeTeamWin) && (!g.homeTeamTie) ? 1 : g.homeTeamTie && !g.tiePoolAccount ? 0.5 : 0;
		const playedOddsTie = g.homeTeamTie && g.tiePoolAccount ? 1 : 0;

		const homeTeamPrice = isGamePlayed && g.homeTokenValue != null ? g.homeTokenValue : homeTokenPrice;
        const awayTeamPrice = isGamePlayed && g.awayTokenValue != null ? g.awayTokenValue : awayTokenPrice;

		const homeTeamOdds = isGamePlayed ? playedOddsHome : homeOdds;
        const awayTeamOdds = isGamePlayed ? playedOddsAway : awayOdds;
        const tieTeamOdds = isGamePlayed ? playedOddsTie : tieOdds;

		const homeTeamUSOdds = homeTeamOdds>=0.5 ? `-${(((homeTeamOdds*100)/(1-homeTeamOdds)).toFixed(0))}` : `+${(((100/(homeTeamOdds))-100).toFixed(0))}`;
		const awayTeamUSOdds = awayTeamOdds>=0.5 ? `-${(((awayTeamOdds*100)/(1-awayTeamOdds)).toFixed(0))}` : `+${(((100/(awayTeamOdds))-100).toFixed(0))}`;
		const tieTeamUSOdds = tieTeamOdds>=0.5 ? `-${(((tieTeamOdds*100)/(1-tieTeamOdds)).toFixed(0))}` : `+${(((100/(tieTeamOdds))-100).toFixed(0))}`;

		const homeTeamDecOdds = ((1/homeTeamOdds).toFixed(2));
		const awayTeamDecOdds = ((1/awayTeamOdds).toFixed(2));
		const tieTeamDecOdds = ((1/tieTeamOdds).toFixed(2));

		const homeTokenPriceIfWin = (g.awayWagerTokenBalance + g.homeWagerTokenBalance + g.tieWagerTokenBalance) / g.homeTokenSupply;
        const awayTokenPriceIfWin = (g.awayWagerTokenBalance + g.homeWagerTokenBalance + g.tieWagerTokenBalance) / g.awayTokenSupply;
        const tieTokenPriceIfWin = (g.awayWagerTokenBalance + g.homeWagerTokenBalance + g.tieWagerTokenBalance) / g.tieTokenSupply;
        const date = timeConverter(g.gameStartTime);
		
		
		const homeSrc = images[getTeamImg(g.sport, g.homeTeam)];
		const awaySrc = images[getTeamImg(g.sport, g.awayTeam)];
		console.log(`homeSrc ${g.homeTeam} = ${getTeamImg(g.sport, g.homeTeam)}`);
		console.log(`awaySrc ${g.awayTeam} = ${getTeamImg(g.sport, g.awayTeam)}`);

		const sportDict: any = {
			'MMA': '🥊'
			, 'NHL': '🏒'
			, 'NBL': '🏀'
			, 'NBA': '🏀'
			, 'BIG3': '🏀'
			, 'G League': '🏀'
			, 'MLB': '⚾'
			, 'Cricket': '🏏'
			, 'EPL': '⚽'
			, 'F1': '🏎️'
			, 'Rally': '🏎️'
			, 'BearMarketRally': '🏎️'
			, 'Soccer': '⚽'
			, 'CL': '⚽'
			, 'UEL': '⚽'
			, 'UNL': '⚽'
			, 'NFL': '🏈'
			, 'CFB': '🏈'
			, 'Tennis': '🎾'
			, 'Valorant': '🎮'
			, 'DOTA': '🎮'
			, 'CSGO': '🎮'
			, 'LoL': '🎮'
			, 'Golf': '⛳'
		}
		const isMsol = g.wagerToken == MSOL_MINT.toString();
		const isUsdh = g.wagerToken == USDH_MINT.toString();

		return(
			<div className="game-pool-card">
				{
					<div className='card-token'>
						{
							isMsol
							? <img className='card-token-img' src={images['mSOL']} />
							: isUsdh ? <img className='card-token-img' src={images['USDH']} />
							: <img className='card-token-img' src={images['SOL']} />
						}
					</div>
				}
				<div className="game-pool-card-inner">
					<div className='row card-header-row'>
						<div className={`header-left ${g.sport}`}>
							<span className='sport-icon'>{sportDict[g.sport]}</span>
							{`${g.sport}`}
						</div>
						<div className={`header-right ${isMsol ? 'msol' : ''}`}>
							<div className={`time-div`}>
								<div className={`time-text`}>
									<img className='clock' src={images['CLOCK']} />
								</div>
								<div className="time-text2">
									{date}
								</div>
							</div>
						</div>
					</div>
					<div className='card-body-row'>
						<div className="row" style={{'paddingTop': '20px'}}>
							<div className="col-5 price-text">
								{
								this.props.data.odds==="Percentage" ? 
								<span className='price-text-1'>
								{ g.awayTokenSupply + g.homeTokenSupply === 0 ? '-' : (homeTeamOdds * 100).toFixed(1) }%
								</span> : null
								}
								{
								this.props.data.odds==="American" ? 
								<span className='price-text-1'>
								{ homeTeamUSOdds }
								</span> : null
								}
								{
								this.props.data.odds==="Decimal" ? 
								<span className='price-text-1'>
								{ homeTeamDecOdds }
								</span> : null
								}
							</div>
							<div className="col-2"/>
							<div className="col-5 price-text">
							{
								this.props.data.odds==="Percentage" ? 
								<span className='price-text-1'>
								{ g.awayTokenSupply + g.homeTokenSupply === 0 ? '-' : (awayTeamOdds * 100).toFixed(1) }%
								</span> : null
								}
								{
								this.props.data.odds==="American" ? 
								<span className='price-text-1'>
								{ awayTeamUSOdds }
								</span> : null
								}
								{
								this.props.data.odds==="Decimal" ? 
								<span className='price-text-1'>
								{ awayTeamDecOdds }
								</span> : null
								}
							</div>
						</div>
						<div className="row" style={{'paddingTop': '13px', 'marginLeft': '45px'}}>
							<div className="col-5">
								<img style={{"maxHeight": "90px", "maxWidth": "90px", 'marginRight': '40px'}} className={"logo"} src={homeSrc} alt={`${g.homeTeam} icon`} />
							</div>
							<div className="col-2 at-symbol">
								<img className='vs-logo' src={images['VS_1']} />
							</div>
							<div className="col-5">
								<img style={{"maxHeight": "90px", "maxWidth": "90px", 'marginLeft': '30px'}} className={"logo"} src={awaySrc} alt={`${g.awayTeam} icon`} />
							</div>
						</div>
						<div className="row" style={{'paddingTop': '7px'}}>
							<div className="col-5 margin-auto">
								<span className='team-name'>{g.homeTeamName}</span>
							</div>
							<div className="col-2">
							</div>
							<div className="col-5 margin-auto">
								<span className='team-name'>{g.awayTeamName}</span>
							</div>
						</div>
						<div  style={{'paddingTop': '13px'}}>
							{
								isGamePlayed ? 
									<div className="row">
										<div className="col-5 detail-text">
											{ homeTeamPrice.toFixed(2) }
										</div>
										<div className="col-2"/>
										<div className="col-5 detail-text">
											{ awayTeamPrice.toFixed(2) }
										</div>
									</div>
								:
									<>
										<div className="row">
											<div className="col-5">
												<span className='detail-text'>
													{ `$${homeTeamPrice.toFixed(2)} to win $${homeTokenPriceIfWin.toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})}` }
												</span>
											</div>
											<div className="col-2"/>
											{/* <span className='detail-text'>
												{ `$${awayTeamPrice.toFixed(2)} to win $${awayTokenPriceIfWin.toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})}` }
											</span> */}
											<div className="col-5">
												<span className='detail-text'>
													{ `$${awayTeamPrice.toFixed(2)} to win $${awayTokenPriceIfWin.toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})}` }
												</span>
											</div>
										</div>
										<div className="row">
											<div className="col-5">
												<span className='detail-text-2'>
												</span>
											</div>
											<div className="col-2">
												{g.tiePoolAccount ?
												<span className='detail-text-2'>
													{
													this.props.data.odds==="Percentage" ? 
													<span className='price-text-1'>
													{ ((tieTeamOdds*100).toFixed(2)) }%
													</span> : null
													}
													{
													this.props.data.odds==="American" ? 
													<span className='price-text-1'>
													{ tieTeamUSOdds }
													</span> : null
													}
													{
													this.props.data.odds==="Decimal" ? 
													<span className='price-text-1'>
													{ tieTeamDecOdds }
													</span> : null
													}
												</span> : null
												}
											</div>
											<div className="col-5">
												<span className='detail-text-2'>
												</span>
											</div>
										</div>
									</>
							}
						</div>
						<div className="row" style={{'paddingTop': '20px'}}>
							<div className="col-5">
								<NavLink
									exact
									to="/trade"
									activeClassName="selected"
								>
									<Button className={`buy-button middle-button ${g.sport == 'NBA' ? 'yes' : ''}`} onClick={() => {
										console.log(`setSwapFromToken(${ADDRESS_TO_TOKEN_MAP[g.wagerToken]})`);
										console.log(`setSwapToToken(${convertToTokenName(g.homeTeam, g.week, g.sport)})`);

										const tokenName = convertToTokenName(g.homeTeam, g.week, g.sport);
										const t = new TokenObject( tokenName, false, g, Outcome.Home, g.homeTeam, g.wagerToken, g.userHomeTokenBalance );
										const s = new SwapObject(g, Outcome.Home, true, [t], t);
										this.props.setSwapObject(s);

										// if (ADDRESS_TO_TOKEN_MAP.hasOwnProperty(g.wagerToken)) {
										// 	this.props.setSwapFromToken(ADDRESS_TO_TOKEN_MAP[g.wagerToken]);
										// }
										// this.props.setSwapToToken(convertToTokenName(g.homeTeam, g.week, g.sport))
									}}>
										<div className='dollar'>BET ${ `${g.homeTeam}${ g.sport == 'NBA' ? `.${g.week}` : ''}`}</div>
									</Button>
								</NavLink>
							</div>
							<div className="col-2">
								{
									g.tiePoolAccount ? 
									<NavLink
										exact
										to="/trade"
									>
										<Button className={`buy-button middle-button`} onClick={() => {
											const tokenName = convertToTokenName(`DRAW.${g.awayTeam}-${g.homeTeam}`, g.week, g.sport);
											const t = new TokenObject( tokenName, false, g, Outcome.Tie, tokenName, g.wagerToken, g.userTieTokenBalance );
											const s = new SwapObject(g, Outcome.Tie, true, [t], t);
											this.props.setSwapObject(s);
										}}>
											<div className='dollar'>BET ${`DRAW`}</div>
										</Button>
									</NavLink>
									: null
								}
							</div>
							<div className="col-5">
								<NavLink
									exact
									to="/trade"
									activeClassName="selected"
								>
									<Button className={`buy-button right-button ${g.sport == 'NBA' ? 'no' : ''}`} onClick={() => {
										const tokenName = convertToTokenName(g.awayTeam, g.week, g.sport);
										const t = new TokenObject( tokenName, false, g, Outcome.Away, g.awayTeam, g.wagerToken, g.userAwayTokenBalance );
										const s = new SwapObject(g, Outcome.Away, true, [t], t);
										this.props.setSwapObject(s);										
										// if (ADDRESS_TO_TOKEN_MAP.hasOwnProperty(g.wagerToken)) {
										// 	this.props.setSwapFromToken(ADDRESS_TO_TOKEN_MAP[g.wagerToken]);
										// }
										// this.props.setSwapToToken(convertToTokenName(g.awayTeam, g.week, g.sport))
									}}>
										<div className='dollar'>BET ${ `${g.awayTeam}${ g.sport == 'NBA' ? `.${g.week}` : ''}`}</div>
									</Button>
								</NavLink>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setSwapToToken: (token: string) => dispatch(actions.setSwapToToken(token)),
        setSwapFromToken: (token: string) => dispatch(actions.setSwapFromToken(token)),
        setSwapObject: (swapObject: SwapObject) => dispatch(actions.setSwapObject(swapObject)),
    }
}

export default connect(null, mapDispatchToProps)(GamePoolCard);
