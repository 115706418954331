// import { useState, useEffect, useRef, RefObject }, React from "react";
import React from "react";
// import { useOddsStore } from "../../store/odds";


type OddsDisplayProps = {
    price: number;
    type: string;
    odds: any;
}

var percentColors = [
    { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
    { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
    { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } }];



var getColorForPercentage = function (pct: number) {
    for (var i = 1; i < percentColors.length - 1; i++) {
        if (pct < percentColors[i].pct) {
            break;
        }
    }
    var lower = percentColors[i - 1];
    var upper = percentColors[i];
    var range = upper.pct - lower.pct;
    var rangePct = (pct - lower.pct) / range;
    var pctLower = 1 - rangePct;
    var pctUpper = rangePct;
    var color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };

    let r: string = color.r.toString(16);
    let g: string = color.g.toString(16);
    let b: string = color.b.toString(16);

    if (r.length == 1)
        r = "0" + r;
    if (g.length == 1)
        g = "0" + g;
    if (b.length == 1)
        b = "0" + b;

    return "#" + r + g + b;
    // or output as hex if preferred
};



const OddsDisplay = ({ price, type, odds }: OddsDisplayProps) => {

    const color = getColorForPercentage(parseFloat(price.toFixed(2)))

    const decimalVal = parseFloat((100 / price / 100).toFixed(2))
    console.log(decimalVal)
    const americanVal = decimalVal >= 2 ? ((decimalVal - 1)*100).toFixed(0) : ((100)/(decimalVal - 1)).toFixed(0)

    return <>

        {odds == "Percentage" ?
            <div className={` tw-w-full tw-bg-base-500 tw-rounded-lg tw-relative flex tw-py-3 ${type == "home" ? " tw-flex-row-reverse " : " tw-flex-row "} `}>
                <div style={{ width: `${(price * 100).toFixed(0)}%`, backgroundColor: color }} className={`   tw-text-center tw-h-full tw-rounded-lg`}>
                    <p className={` tw-text-black1  `}>{(price * 100).toFixed(0)}%</p>
                </div>
            </div> : (odds == "American" ?
                <div className="tw-flex tw-justify-center tw-text-xl tw-font-title">
                    <p>{price > 0.50 ? "-" : "+"}{americanVal}</p>
                </div> :
                <div className="tw-flex tw-justify-center tw-text-xl tw-font-title">
                    <p>{decimalVal}</p>
                </div>)}

    </>
}

export default OddsDisplay