import React from 'react';
import images from '../static/images';
import { useSelector } from 'react-redux';
import { IState } from '../store/interfaces/state';
import { getTeamImg, ordinal_suffix_of, timeConverter, timeConverterShort } from '../utils/utils';
import { LeaderboardEntry } from '../models/LeaderboardEntry';
import { Dropdown } from 'react-bootstrap';
import { Trade } from '../models/Trade';
import { ADDRESS_TO_TOKEN_MAP, TOKEN_MAP } from '../constants';

// a leaderboard page to show the performance of each user
// can use this during beta testing for fun
const MyTradesPage = (props: any) => {

    const data: IState = useSelector((state: any) => state.data);
	console.log(`MyTradesPage`);
	console.log(data.userTrades);

    const myTrades = data.userTrades.sort((a, b) => b.timestamp - a.timestamp).map( (a: Trade, i: number) => {
        // const c = a.address === data.address ? 'gold-border' : '';
		const teamTokenSrc = getTeamImg(a.sport, a.teamAbbr);
		const src = images[ADDRESS_TO_TOKEN_MAP[a.wagerTokenAddress]];
		const calcOdds = (a.wagerTokenAmount / a.teamTokenAmount);
		const PerOdds = `${((a.wagerTokenAmount / a.teamTokenAmount)*100).toFixed(1)}%`;
		const USOdds = calcOdds>=0.5 ? `-${(((calcOdds*100)/(1-calcOdds)).toFixed(0))}` : `+${(((100/(calcOdds))-100).toFixed(0))}`;
		const DecOdds = ((1/calcOdds).toFixed(4));
		return(
            <tr key={i} className={`${i % 2 ? 'odd' : 'even'}`}>
                <td className='img-cell'>
					<img src={images[teamTokenSrc]} alt="" />
				</td>
				<td>{a.teamName}</td>
				<td className={a.sport.toLowerCase()}>{a.sport}</td>
				<td>{timeConverterShort(a.timestamp)}</td>
				<td><img src={src} /></td>
				<td>{a.wagerTokenAmount >= 100 ? a.wagerTokenAmount.toLocaleString('en-US', {maximumFractionDigits: 0, minimumFractionDigits: 0}) : a.wagerTokenAmount.toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</td>
				{/* <td>
					{data.user && data.user.userOdds==="Percentage" ? PerOdds : null}
					{data.user && data.user.userOdds==="American" ? USOdds : null}
					{data.user && data.user.userOdds==="Decimal" ? DecOdds : null}
				</td> */}
				<td>{a.teamTokenAmount >= 100 ? a.teamTokenAmount.toLocaleString('en-US', {maximumFractionDigits: 0, minimumFractionDigits: 0}) : a.teamTokenAmount.toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</td>
				{/* <td className={a.sport.toLowerCase()}>{a.sport}</td> */}
				{/* <td>{(x.wagerTokenAmount / x.teamTokenAmount).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</td> */}
				<td className={a.action.toLowerCase()}>{a.action}</td>
				<td>
					<a href={`https://solana.fm/tx/${a.txId}`} target='_'>
						{a.txId.substring(0, 8)}...
					</a>
				</td>
				<td>
					{
						a.payoutAmount
						? a.payoutAmount.toLocaleString('en-US', {maximumFractionDigits: 4, minimumFractionDigits: 0})
						: null
					}
				</td>
				<td>
					{
						a.payoutTx ?
							<a href={`https://solana.fm/tx/${a.payoutTx}`} target='_'>
								{a.payoutTx.substring(0, 8)}...
							</a>
						: null
					}
				</td>
            </tr>
        )
    })

    return(
        <div className="my-trades panel">
            <div className="panel-outer">
				<div className="panel-inner">
					<div className='row'>
						<div className='col-12 pb-4 text-th-fgd-1 text-lg'>
							My Trades
						</div>
					</div>
					<table className='props-table'>
						<thead>
							<tr>
								<th colSpan={2}>Team</th>
								<th>Sport</th>
								<th>Date</th>
								<th>Token</th>
								<th>Wager Amt</th>
								{/* <th>Odds</th> */}
								<th>Team Amt</th>
								<th>Action</th>
								<th>Tx</th>
								<th>Auto Payout</th>
								<th>Auto Payout Tx</th>
							</tr>
						</thead>
						<tbody>
							{myTrades}
						</tbody>
					</table>
            	</div>
            </div>
        </div>
    )
};

export default MyTradesPage;
