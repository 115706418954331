import SwapPage from './components/SwapPage';
import BasicLayout from './components/BasicLayout';
import GamePoolList from './components/GamePoolList';
import { Route, BrowserRouter } from 'react-router-dom';
import MyPositions from './components/mobile/MyPositions';
import MyPositionsPage from './components/MyPositionsPage';
import LeaderboardPage from './components/LeaderboardPage';
import MyProfilePage from './components/MyProfilePage';
import EventPage from './components/EventPage';
import GamePoolListNew from './components/GamePoolListNew';
import MyPositionsPageNew from './components/MyPositionsPageNew';
import UploadImageToS3WithReactS3 from './components/ImageUploader';
import MyTradesPage from './components/MyTradesPage';
import MyPayoutsPage from './components/MyPayoutsPage';
import { isMobile } from './constants';

export function Routes() {
  return (
      <BrowserRouter>
        <BasicLayout>
            {/* <Route exact path="/faucet" component={PropsFaucetPage} /> */}
            <Route exact path="/" component={GamePoolList} />
            <Route exact path="/games" component={GamePoolList} />
            <Route exact path="/games-2" component={GamePoolListNew} />
            <Route exact path="/event" component={EventPage} />
            <Route exact path="/trade" component={SwapPage} />
            <Route exact path="/my-trades" component={MyTradesPage} />
            <Route exact path="/auto-payouts" component={MyPayoutsPage} />
            {isMobile? <Route exact path="/my-positions" component={MyPositions} />
            : <Route exact path="/my-positions" component={MyPositionsPage} />}
            <Route exact path="/my-positions-2" component={MyPositionsPageNew} />
            <Route exact path="/leaderboard" component={LeaderboardPage} />
            <Route exact path="/my-profile" component={MyProfilePage} />
            <Route exact path="/test" component={UploadImageToS3WithReactS3} />
            {/* <Route exact path="*" component={GamePoolList} /> */}
        </BasicLayout>
      </BrowserRouter>
  );
}
