import React, { useState, useEffect } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import Navigation from '../components/mobile/Navigation';
import images from '../static/images';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useSelector } from 'react-redux';
import { IState } from '../store/interfaces/state';
import { Dropdown } from 'react-bootstrap';
import GamesDropDown from './mobile/GamesDropDown';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import { Bars3Icon } from '@heroicons/react/24/solid'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { isMobile } from '../constants';
import { Layout } from 'antd';
import AWS from 'aws-sdk'
import { getCurrentTimestamp } from '../utils/utils';



const { Header, Content } = Layout;

type PoolInfo = {
    sport: string;
    count: number;
}

const S3_BUCKET = 'props-avatars';
const REGION = 'us-east-1';

AWS.config.update({
    accessKeyId: 'AKIAULSMK74QTRRSAAPZ',
    secretAccessKey: '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'
})

const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
})


const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
          main: '#ffffff',
        },
        secondary: {
          main: '#f2c94c',
        },
      },
  });
  


function MobileLayout(props: any, setSportMobile: any) {
    const location = useLocation();
    const page = location.pathname == '/trade' ? 'trade' : location.pathname == '/my-positions' ? 'my-positions' : location.pathname == '/leaderboard' ? 'leaderboard' : 'games';
    const data: IState = useSelector((state: any) => state.data)
    const [ sportValue, setSportValue ] = useState('ALL');
    const [ src, setSrc ] = React.useState( images['AVATAR'] );
    const [menuOpen, setMenuOpen] = React.useState<Boolean>(false)
    const now = getCurrentTimestamp();
    const pools = data.gamePools.filter( x => x.gameStartTime > now ).sort( (a, b) => a.gameStartTime == b.gameStartTime ? ( a.week == b.week ? (b.homeTokenPrice - a.homeTokenPrice) : a.week > b.week ? 1 : -1) : a.gameStartTime - b.gameStartTime );


    const handleSportChange = (event: any, newValue: any) => {
        console.log('new sport', newValue)
;       setSportValue(newValue);
        props.setSportMobile(newValue);
      };

    
    const history = useHistory();

    useEffect(() => {
		if (data.address) {
			var params = {Bucket: 'props-avatars', Key: `${ data.address ? data.address : ''}.png`};
				s3.getObject(params).on('success', function(response: any) {
				// console.log("Key was", response.request.params.Key);
				setSrc(`https://props-avatars.s3.us-east-1.amazonaws.com/${response.request.params.Key}`);
				
			}).on('error',function(error){
				//error return a object with status code 404
				console.log(`topbar avatar error`);
				console.log(error);
				
			}).send();
		} else {
			setSrc('');
		}
    }, [data.address])

    return (
        <>
        <div className="mobile-background" style={{'width': '94%', 'height': '15vh', 'paddingLeft': '3vw', 'position': 'fixed', 'top': '0px'}}>
            <div className='top-bar-mobile'>
                <div className='top-bar-mobile-inner'>
                    <img style={{'minHeight': '10vh', 'maxWidth': '80px'}} src={images['PROPS']}></img>
                    <div className='top-row-col right'>
                        <NavLink
                            exact
                            to="/my-profile"
                        >
                            <img 
                                className='avatar' 
                                style={{'height': '32px', 'marginTop': '6px', 'marginLeft': '0px', 'cursor':'pointer', 'borderRadius': '40px'}}
                                onClick={() => history.push('/my-profile')} 
                                // src={'https://su4biyk6uopanmz25lldvhwacvoql2pswcmj5jybgalog4xbei.arweave.net/-lTgUYV6jngazOurWOp7AFV0F6fKwmJ6nATAW43LhIs'}
                                // src={images['AVATAR']}
                                src={src}
                                alt=''
                            />
                        </NavLink>
                    </div>
                    <div style={{'paddingBottom': '10px'}}>
						{
							data.address ? 
                                    <WalletMultiButton style={{'height': '32px', 'width': '100%', 'marginTop': '1.5vh', 'cursor':'pointer'}}/>
							:
                                    <WalletMultiButton style={{'height': '32px', 'width': '100%', 'marginTop': '1.5vh', 'cursor':'pointer'}}>
                                        <img 
                                            className='wallet-icon' 
                                            style={{'height': '16px', 'marginTop': '0px', 'cursor':'pointer'}}
                                            // src={'https://su4biyk6uopanmz25lldvhwacvoql2pswcmj5jybgalog4xbei.arweave.net/-lTgUYV6jngazOurWOp7AFV0F6fKwmJ6nATAW43LhIs'}
                                            // src={images['AVATAR']}
                                            src={images['WALLET_ICON']}
                                            alt='wallet-icon'
                                        />
                                        <span style={{'paddingLeft': '10px'}}>Connect</span>
                                    </WalletMultiButton>
						}
                    </div>
                    <div className="md:tw-hidden">
                        {menuOpen ? null :
                            <button onClick={() => {
                            setMenuOpen(true)
                            }}>
                            <Bars3Icon className="tw-h-14" />
                            </button>
                        }
                    </div>
                </div>
            </div>
            {/* <div className='tab-selector'>
                <div className={`side-panel-link ${page == 'games' ? 'selected' : ''}`}>
                    <NavLink
                        exact
                        to="/"
                        activeClassName="selected"
                    >
                        <img style={{'height': '21px', 'paddingRight': '10px'}} src={images['GAMES']}/>
                        Games
                    </NavLink>
                </div>
                <div className={`side-panel-link ${page == 'trade' ? 'selected' : ''}`}>
                    <NavLink
                        exact
                        to="/trade"
                        activeClassName="selected"
                    >
                        <img style={{'height': '21px', 'paddingRight': '10px'}} src={images['SWAP']}/>
                        Trade
                    </NavLink>
                </div>
                <div className={`side-panel-link ${page == 'my-positions' ? 'selected' : ''}`}>
                    <NavLink
                        exact
                        to="/my-positions"
                        activeClassName="selected"
                    >
                        <img style={{'height': '21px', 'paddingRight': '10px'}} src={images['MY_POSITIONS']}/>
                        Positions
                    </NavLink>
                </div>
                <div className={`side-panel-link ${page == 'leaderboard' ? 'selected' : ''}`}>
                    <NavLink
                        exact
                        to="/leaderboard"
                        activeClassName="selected"
                    >
                        <img style={{'height': '21px', 'paddingRight': '10px'}} src={images['LEADERBOARD']}/>
                        Leaderboard
                    </NavLink>
                </div>
            </div> */}
            {location.pathname==="/games" || location.pathname==="/" ? 
            <div className=" tw-flex tw-flex-row tw-items-end md:tw-items-center tw-space-x-5 tw-mb-2 tw-bg-base-100 tw-pb-3 tw-mt-7">
                <p className="tw-text-3xl md:tw-text-4xl tw-font-title">Active Games</p> 
                <GamesDropDown sportValue={sportValue} setSportValue={setSportValue} pools={pools} setSportMobile={setSportMobile} props={props} />
            </div> : null
            }
            

        {/* <div style={{'paddingLeft': '250px', 'width': 'auto', 'overflow': 'hidden'}}> */}
        <Navigation isOpen={menuOpen} close={() => setMenuOpen(false)} />
        <Content style={{ flex: 1 }}>{props.children}</Content>
        </div>
        </>
    );
}

export default MobileLayout;