import { useSelector } from 'react-redux';
import { IState } from '../store/interfaces/state';
import images from '../static/images';
import { ADDRESS_TO_TOKEN_MAP } from '../constants';
import { getTeamImg } from '../utils/utils';


const RecentTradesPanel = (props: any) => {
	const data: IState = useSelector((state: any) => state.data);

	const rows = data.trades.map( (x, index) => {
		const src = getTeamImg(x.sport, x.teamAbbr);
		// console.log(`src = ${src}`);
		const tokenSrc = ADDRESS_TO_TOKEN_MAP[x.wagerTokenAddress];
		
		return(
			<tr key={index} className={index % 2 ? 'even' : 'odd'}>
				<td className='img-cell'>
					<img className='avatar' src={images[src]} />
				</td>
				<td>{x.teamName}</td>
				<td className={x.sport.toLowerCase()}>{x.sport}</td>
				<td className='img-cell'>
					<img className='token-img-small' src={images[tokenSrc]} />
				</td>
				<td>{x.wagerTokenAmount >= 100 ? x.wagerTokenAmount.toLocaleString('en-US', {maximumFractionDigits: 0, minimumFractionDigits: 0}) : x.wagerTokenAmount.toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</td>
				<td className={x.action.toLowerCase()}>{x.action}</td>
			</tr>
		)
	})

	return(
		<div className="recent-trades panel">
			<div className='recent-trades-header'>
				Recent Trades
			</div>
			<div>
				<table>
					<thead>
						<tr>
							<th colSpan={2}>
								Team
							</th>
							<th colSpan={1}>
								Sport
							</th>
							<th colSpan={1}>
								Token
							</th>
							<th colSpan={1}>
								Amount
							</th>
							{/* <th colSpan={1}>
								Price
							</th> */}
							<th colSpan={1}>
								Action
							</th>
						</tr>
					</thead>
					<tbody>
						{rows}
					</tbody>
				</table>
			</div>
		</div>
	)
};

export default RecentTradesPanel;
