import { Outcome } from '../models/Outcome';
import { GamePoolWithDetail } from '../models/GamePoolWithDetail';
import { TokenObject } from './TokenObject';

// gamePool, outcome, isToTeamToken
export interface ISwapObject {
    gamePool: GamePoolWithDetail | null;
    outcome: Outcome | null;
    isToTeamToken: boolean | null;
    tokens: TokenObject[];
    selectedToken: TokenObject;
}



export class SwapObject implements ISwapObject {
    gamePool: GamePoolWithDetail | null;
    outcome: Outcome | null;
    isToTeamToken: boolean | null;
    tokens: TokenObject[];
    selectedToken: TokenObject;

	constructor(
        gamePool: GamePoolWithDetail | null = new GamePoolWithDetail()
        , outcome: Outcome | null = Outcome.Home
        , isToTeamToken: boolean | null = true
        , tokens: TokenObject[] = []
        , selectedToken: TokenObject = new TokenObject()
    ) {
        this.gamePool = gamePool;
        this.outcome = outcome;
        this.isToTeamToken = isToTeamToken;
        this.tokens = tokens;
        this.selectedToken = selectedToken;
    }

}
