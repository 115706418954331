import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import * as actions from '../store/actions/actions';
import TopBar from './TopBar';
import images from '../static/images';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IState } from '../store/interfaces/state';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import MobileLayout from './MobileLayout';
import AWS from 'aws-sdk'
import { isMobile } from '../constants';
import { Dropdown } from 'react-bootstrap';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import FormHelperText from '@mui/material/FormHelperText';
import { updateUser } from '../api/user';
import { getCurrentTimestamp } from '../utils/utils';


const { Header, Content } = Layout;


const S3_BUCKET = 'props-avatars';
const REGION = 'us-east-1';

AWS.config.update({
    accessKeyId: 'AKIAULSMK74QTRRSAAPZ',
    secretAccessKey: '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'
})

const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
})

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
      },
  });
  
// put header at the top of every page
function BasicLayout( props: any ) {
	const location = useLocation();
	// console.log(`this.props.location`);
	// console.log(location.pathname);
    const page = location.pathname == '/trade' ? 'trade' 
	: location.pathname == '/my-positions' ? 'my-positions' 
	: location.pathname == '/leaderboard' ? 'leaderboard' 
	: location.pathname == '/my-trades' ? 'my-trades' 
	: location.pathname == '/auto-payouts' ? 'auto-payouts' 
	: 'games';

	const setSport = (evt: any) => {
		console.log(`setSport`);
		console.log(evt.target.textContent);
		props.setSport(evt.target.textContent)
	}

	const setOdds = (evt: any) => {
		console.log(`setOdds`);
		props.setOdds(evt)
	}

	const setSportMobile = (newVal: any) => {
		props.setSport(newVal)
	}

	const clickDropdown = (sport: string) => {
		setSport(sport);
	}

	const data: IState = useSelector((state: any) => state.data)
	const [ src, setSrc ] = React.useState( images['AVATAR'] );
	const history = useHistory();

	const now = getCurrentTimestamp();
	const pools = data.gamePools.filter( x => x.gameStartTime > now ).sort( (a, b) => a.gameStartTime == b.gameStartTime ? ( a.week == b.week ? (b.homeTokenPrice - a.homeTokenPrice) : a.week > b.week ? 1 : -1) : a.gameStartTime - b.gameStartTime );
	const sportsList: any = [];

    useEffect(() => {
		if (data.address) {
			var params = {Bucket: 'props-avatars', Key: `${ data.address ? data.address : ''}.png`};
				s3.getObject(params).on('success', function(response: any) {
				// console.log("Key was", response.request.params.Key);
				setSrc(`https://props-avatars.s3.us-east-1.amazonaws.com/${response.request.params.Key}`);
				
			}).on('error',function(error){
				//error return a object with status code 404
				console.log(`topbar avatar error`);
				console.log(error);
				
			}).send();
		} else {
			setSrc(images['AVATAR']);
		}
    }, [data.address])

	useEffect(() => {
		const initOdds = data.user && data.user.userOdds ? data.user.userOdds : 'Percentage'
		setOdds(initOdds);
	}, [data.user])

	const handleOddsChange = async (event: SelectChangeEvent) => {
		setOdds(event.target.value);
		if (data.address && data.user) {
		const val = await updateUser(data.address, data.user.userName, data.user?.userAvatarUrl, event.target.value);
		}
	};

	if (isMobile) return(
		<>
			{page==='games' ?
			<div style={{'width': '96%', 'height': '60vh', 'paddingLeft': '2vw', 'position': 'relative', 'marginTop': '28vh'}}>
				<Content style={{ 'flex': '1', 'position': 'absolute' }}>{props.children}</Content>
			</div>
			:
			<div style={{'width': '96%', 'height': '60vh', 'paddingLeft': '2vw', 'position': 'relative', 'marginTop': '20vh'}}>
				<Content style={{ 'flex': '1', 'position': 'absolute' }}>{props.children}</Content>
			</div>
			}
			<MobileLayout setSportMobile={setSportMobile} />
		</>
	);
	
	return (
		<React.Fragment>
			<div style={{'width': '160px', 'height': '95vh', 'padding': '0 0 10px 20px', 'position': 'fixed', 'top': '25px'}}>
				<div className='side-panel'>
					<div className='side-panel-inner'>
						<img style={{'width': '40%', 'paddingBottom': '40px', 'marginLeft': '43px'}} src={images['PROPS']}></img>
						<div className={`side-panel-link ${page == 'games' ? 'selected' : ''}`}>
							<NavLink
								exact
								to="/"
								activeClassName="selected"
							>
								<img style={{'height': '21px', 'paddingRight': '10px'}} src={images['GAMES']}/>
								<div className="side-panel-link-text">Games</div>
							</NavLink>
						</div>
						<div className={`side-panel-link ${page == 'trade' ? 'selected' : ''}`}>
							<NavLink
								exact
								to="/trade"
								activeClassName="selected"
							>
								<img style={{'height': '21px', 'paddingRight': '10px'}} src={images['SWAP']}/>
								<div className="side-panel-link-text">Trade</div>
							</NavLink>
						</div>
						<div className={`side-panel-link ${page == 'my-positions' ? 'selected' : ''}`}>
							<NavLink
								exact
								to="/my-positions"
								activeClassName="selected"
							>
								<img style={{'height': '21px', 'paddingRight': '10px'}} src={images['MY_POSITIONS']}/>
								<div className="side-panel-link-text">Positions</div>
							</NavLink>
						</div>
						<div className={`side-panel-link ${page == 'leaderboard' ? 'selected' : ''}`}>
							<NavLink
								exact
								to="/leaderboard"
								activeClassName="selected"
							>
								<img style={{'height': '21px', 'paddingRight': '10px'}} src={images['LEADERBOARD']}/>
								<div className="side-panel-link-text">Leaderboard</div>
							</NavLink>
						</div>
						<div className={`side-panel-link ${page == 'my-trades' ? 'selected' : ''}`}>
							<NavLink
								exact
								to="/my-trades"
								activeClassName="selected"
							>
								<img style={{'height': '21px', 'paddingRight': '10px'}} src={images['MY_TRADES']}/>
								<div className="side-panel-link-text">My Trades</div>
							</NavLink>
						</div>
						<div className={`side-panel-link ${page == 'auto-payouts' ? 'selected' : ''}`}>
							<NavLink
								exact
								to="/auto-payouts"
								activeClassName="selected"
							>
								<img style={{'height': '21px', 'paddingRight': '10px'}} src={images['MY_PAYOUTS']}/>
								<div className="side-panel-link-text">Auto Payouts</div>
							</NavLink>
						</div>
					</div>
				</div>
			</div>
			{/* <div style={{'paddingLeft': '250px', 'width': 'auto', 'overflow': 'hidden'}}> */}
			<div style={{'paddingLeft': '160px'}}>
				{/* <Header>
					<TopBar />
				</Header> */}
				<div className='top-row' style={{'margin': 0, 'padding': '30px 40px 0px 30px', 'minHeight': '60px'}} >

				{
					isMobile ? 
						<>
						<div style={{'paddingBottom': '10px'}}>
						{
							data.address ? 
								<WalletMultiButton style={{'height': '32px', 'width': '100%', 'marginTop': '-10px', 'cursor':'pointer'}}/>
							:
								<WalletMultiButton style={{'height': '32px', 'width': '100%', 'marginTop': '-10px', 'cursor':'pointer'}}>

									<img 
										className='wallet-icon' 
										style={{'height': '16px', 'marginTop': '0px', 'cursor':'pointer'}}
										// src={'https://su4biyk6uopanmz25lldvhwacvoql2pswcmj5jybgalog4xbei.arweave.net/-lTgUYV6jngazOurWOp7AFV0F6fKwmJ6nATAW43LhIs'}
										// src={images['AVATAR']}
										src={images['WALLET_ICON']}
										alt='wallet-icon'
									/>
									<span style={{'paddingLeft': '10px'}}>Connect</span>
								</WalletMultiButton>
						}
						</div>
						<Dropdown style={{'paddingBottom': '10px'}}>
							<Dropdown.Toggle style={{'width': '100%'}} id="leaderboard-dropdown">
								{data.sport}
							</Dropdown.Toggle>

							<Dropdown.Menu>
								<Dropdown.Item onClick={() => {clickDropdown('ALL')}}>ALL</Dropdown.Item>
								<Dropdown.Item onClick={() => {clickDropdown('NFL')}}>NFL</Dropdown.Item>
								<Dropdown.Item onClick={() => {clickDropdown('MMA')}}>MMA</Dropdown.Item>
								<Dropdown.Item onClick={() => {clickDropdown('MLB')}}>MLB</Dropdown.Item>
								<Dropdown.Item onClick={() => {clickDropdown('CFB')}}>CFB</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						</>
					: 
						<>
							<div className='top-row-col lg' style={{'fontWeight': '700', 'fontSize': '20px'}}>Select Games</div>
							<div className={`top-row-col ${ data.sport == 'ALL' ? 'selected' : '' }`} onClick={setSport}>ALL</div>
							{pools.map((x, index) => {
								const showSport = !sportsList.includes(x.sport);
								sportsList.push(x.sport);
								if (showSport) return <div key={index} className={`top-row-col ${ data.sport == `${x.sport}` ? 'selected' : '' }`} onClick={setSport}>{x.sport}</div>
							})}
							<ThemeProvider theme={darkTheme}>
								<FormControl variant="standard" sx={{ m: 1 }}>
									<div style={{"marginTop": "-8px", "marginLeft": "-30px"}}>
									<Select
										labelId="demo-simple-select-standard-label"
										id="demo-simple-select-standard"
										value={data.odds}
										onChange={handleOddsChange}
										autoWidth
										label="Odds"
									>
										<MenuItem value="Percentage">Percentage</MenuItem>
										<MenuItem value="American">American</MenuItem>
										<MenuItem value="Decimal">Decimal</MenuItem>
									</Select>
									<FormHelperText>Preferred Odds</FormHelperText>
									</div>
								</FormControl>
							</ThemeProvider>

							<div className='top-row-col right'>
								{
									data.address ? <WalletMultiButton style={{'height': '32px', 'marginTop': '-10px', 'cursor':'pointer'}}/>
									:
									<WalletMultiButton style={{'height': '32px', 'marginTop': '-10px', 'cursor':'pointer'}}>
			
										<img 
											className='wallet-icon' 
											style={{'height': '32px', 'marginTop': '-10px', 'cursor':'pointer'}}
											// src={'https://su4biyk6uopanmz25lldvhwacvoql2pswcmj5jybgalog4xbei.arweave.net/-lTgUYV6jngazOurWOp7AFV0F6fKwmJ6nATAW43LhIs'}
											// src={images['AVATAR']}
											src={images['WALLET_ICON']}
											alt='wallet-icon'
										/>
										Connect Wallet
									</WalletMultiButton>
								}
							</div>
							{
								data.address ?
								<div className='top-row-col right'>
									<NavLink
										exact
										to="/my-profile"
									>
										<img 
											className='avatar' 
											style={{'height': '32px', 'marginTop': '-10px', 'cursor':'pointer'}}
											onClick={() => history.push('/my-profile')} 
											// src={'https://su4biyk6uopanmz25lldvhwacvoql2pswcmj5jybgalog4xbei.arweave.net/-lTgUYV6jngazOurWOp7AFV0F6fKwmJ6nATAW43LhIs'}
											// src={images['AVATAR']}
											src={src}
											alt=''
										/>
									</NavLink>
								</div>
								: null						
							}
							<div className='top-row-col right'>
								<a
									href='https://twitter.com/poolprops'
									target='_blank'
								>
									<img style={{'height': '32px', 'marginTop': '-10px'}} src={images['TWITTER']}/>
								</a>
							</div>
							<div className='top-row-col right'>
								<a
									href='https://discord.gg/FTQ6Csj6zA'
									target='_blank'
								>
									<img style={{'height': '32px', 'marginTop': '-10px'}} src={images['DISCORD_WHITE']}/>
								</a>
							</div>
					</>
				}
				</div>
				<Content style={{ flex: 1 }}>{props.children}</Content>
			</div>
		</React.Fragment>
	);
}



const mapDispatchToProps = (dispatch: any) => {
    return {
        setSport: (sport: string) => dispatch(actions.setSport(sport)),
		setOdds: (odds: string) => dispatch(actions.setOdds(odds)),
    }
}

export default connect(null, mapDispatchToProps)(BasicLayout);
 