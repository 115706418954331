import React from 'react';
import images from '../static/images';
import { useSelector } from 'react-redux';
import { IState } from '../store/interfaces/state';
import { ordinal_suffix_of } from '../utils/utils';
import { LeaderboardEntry } from '../models/LeaderboardEntry';
import { Dropdown } from 'react-bootstrap';

// a leaderboard page to show the performance of each user
// can use this during beta testing for fun
const LeaderboardPage = (props: any) => {

    const data: IState = useSelector((state: any) => state.data);
    const [ leaderboardName, setLeaderboardName ] = React.useState('World Cup');
    let userRank = null;
	

    const leaderboard = data.leaderboard.filter(x => x.leaderboardName == leaderboardName).sort((a, b) => (b.score == a.score) ? b.usdVolume - a.usdVolume : (b.score - a.score)).map( (a: LeaderboardEntry, i: number) => {
        if (a.address === data.address) {
            userRank = i + 1;
        }
        const c = a.address === data.address ? 'gold-border' : '';
		const src = a.userAvatarUrl ? a.userAvatarUrl : images['AVATAR'];
		return(
            <tr key={i} className={`${c} border-b border-th-bkg-3 bg-[rgba(255,255,255,0.03)] ${i % 2 ? 'odd' : 'even'}`}>
                <td className='px-4 py-3.5 whitespace-nowrap text-sm text-th-fgd-1'>
                    {a.userName ? a.userName : a.address.substring(0, 8).concat('...') }
                </td>
                <td className='px-4 py-3.5 whitespace-nowrap text-sm text-th-fgd-1'>
					<img className='leaderboard-img' src={src} alt="" />
				</td>
                <td className='px-4 py-3.5 whitespace-nowrap text-sm text-th-fgd-1'>{i + 1}</td>
                <td className='px-4 py-3.5 whitespace-nowrap text-sm text-th-fgd-1'>{ a.wins }</td>
                <td className='px-4 py-3.5 whitespace-nowrap text-sm text-th-fgd-1'>{ (a.avgMult).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}) }</td>
                <td className='px-4 py-3.5 whitespace-nowrap text-sm text-th-fgd-1'>{ (a.score).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}) }</td>
            </tr>
        )
    })

    return(
        <div className="leaderboard-page">
            <div className="leaderboard-panel panel rounded-lg">
                <div className='row'>
					<div className='col-3'></div>
					<div className='col-6 pb-4 text-th-fgd-1 text-lg'>
						Leaderboard
					</div>
					<div className='col-3'>

						<Dropdown>
						<Dropdown.Toggle id="leaderboard-dropdown">
							{leaderboardName}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item onClick={() => {setLeaderboardName('World Cup')}}>World Cup</Dropdown.Item>
							<Dropdown.Item onClick={() => {setLeaderboardName('Sep 29 - Oct 3')}}>Sep 29 - Oct 3</Dropdown.Item>
							<Dropdown.Item onClick={() => {setLeaderboardName('CyberSam 3-Week')}}>CyberSam 3-Week</Dropdown.Item>
							<Dropdown.Item onClick={() => {setLeaderboardName('Sep 22-26')}}>Sep 22-26</Dropdown.Item>
							<Dropdown.Item onClick={() => {setLeaderboardName('Sep 15-19')}}>Sep 15-19</Dropdown.Item>
							<Dropdown.Item onClick={() => {setLeaderboardName('Sep 18')}}>Sep 18</Dropdown.Item>
							<Dropdown.Item onClick={() => {setLeaderboardName('Sep 8-12')}}>Sep 8-12</Dropdown.Item>
						</Dropdown.Menu>
						</Dropdown>
					</div>
				</div>
                {
                    userRank ?
                    <div className='semi-title'>
                        {`You are currently in ${ordinal_suffix_of(userRank)} place!` }
                    </div>
                    : null
                }
                <div className='text-align-left'>
                    **Leaderboard will update daily**
                </div>
				<div>
				</div>
                <table className='leaderboard-table min-w-full divide-y divide-th-bkg-2'>
                    <thead>
                        <tr className='text-th-fgd-3 text-xs'>
                            <th className='px-4 pb-2 text-left font-normal'>User</th>
                            <th className='px-4 pb-2 text-left font-normal'></th>
                            <th className='px-4 pb-2 text-left font-normal'>Rank</th>
                            <th className='px-4 pb-2 text-left font-normal'>Wins</th>
                            <th className='px-4 pb-2 text-left font-normal'>Avg Multiplier</th>
                            <th className='px-4 pb-2 text-left font-normal'>Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        {leaderboard}
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default LeaderboardPage;
