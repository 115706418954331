import React from "react";
import LogoWrapper from "../../utils/LogoWrapper";
import images from '../../static/images';
import { IState } from '../../store/interfaces/state';
import { GamePoolWithDetail } from '../../models/GamePoolWithDetail';
import { Outcome } from '../../models/Outcome';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ADDRESS_TO_TOKEN_MAP, TOKEN_MAP } from '../../constants';
import { getTeamImg } from "../../utils/utils";
import * as actions from '../../store/actions/actions';
import { SwapObject } from '../../models/SwapObject';
import { TokenObject } from '../../models/TokenObject';


const Positions = () => {

    const data: IState = useSelector((state: any) => state.data);
    const positions: any[] = [];
    const dispatch = useDispatch();
    const history = useHistory();
    const [ userName, setUserName ] = React.useState('');

    function shortenName(name: string){
        if(name.length > 8) {
            let newName = name.slice(0, 7);
            let finalName = newName+'...';
            return finalName;
        } else {
            return name;
        }
    }


    // Get a list of user positions
    for (let i = 0; i < data.gamePools.length; i++) {
        const g: GamePoolWithDetail = data.gamePools[i];
		const payouts = data.userPayouts.filter(x => x.gameId == g.gameId)
        if (g.userAwayTokenBalance > 0) {
			const p = payouts.filter(x => x.teamToken == g.awayTeamToken);
            positions.push({
                'token': `${g.awayTeam}.${g.sport}.${g.week}`
                , 'sport': g.sport
                , 'wagerToken': ADDRESS_TO_TOKEN_MAP[g.wagerToken]
                , 'amount': g.userAwayTokenBalance
                , 'value': g.userAwayTokenBalance * ( g.awayTokenValue === null || g.awayTokenValue === undefined ? g.awayTokenPrice : g.awayTokenValue)
                , 'gamePool': g
                , 'outcome': Outcome.Away
                , 'team': g.awayTeam
                , 'balance': g.userAwayTokenBalance
                , 'teamName': g.awayTeamName
				, 'payoutAmount': p.length ? p[0].wagerTokenAmount : 0
				, 'payoutTx': p.length ? p[0].txId : ''
            })
        }
        if (g.userHomeTokenBalance > 0) {
			const p = payouts.filter(x => x.teamToken == g.homeTeamToken);
            positions.push({
                'token': `${g.homeTeam}.${g.sport}.${g.week}`
                , 'sport': g.sport
                , 'wagerToken': ADDRESS_TO_TOKEN_MAP[g.wagerToken]
                , 'amount': g.userHomeTokenBalance
                , 'value': g.userHomeTokenBalance * ( g.homeTokenValue === null || g.homeTokenValue === undefined ? g.homeTokenPrice : g.homeTokenValue)
                , 'gamePool': g
                , 'outcome': Outcome.Home
                , 'team': g.homeTeam
                , 'balance': g.userHomeTokenBalance
                , 'teamName': g.homeTeamName
				, 'payoutAmount': p.length ? p[0].wagerTokenAmount : 0
				, 'payoutTx': p.length ? p[0].txId : ''
            })
        }
        if (g.userTieTokenBalance > 0) {
			const p = payouts.filter(x => x.teamToken == g.tieTeamToken);
            positions.push({
                'token': `DRAW.${g.awayTeam}-${g.homeTeam}.${g.sport}.${g.week}`
                , 'sport': g.sport
                , 'wagerToken': ADDRESS_TO_TOKEN_MAP[g.wagerToken]
                , 'amount': g.userTieTokenBalance
                , 'value': g.userTieTokenBalance * ( g.tieTokenValue === null || g.tieTokenValue === undefined ? g.tieTokenPrice : g.tieTokenValue)
                , 'gamePool': g
                , 'outcome': Outcome.Tie
                , 'team': 'DRAW'
                , 'teamName': ''
                , 'balance': g.userTieTokenBalance
				, 'payoutAmount': p.length ? p[0].wagerTokenAmount : 0
				, 'payoutTx': p.length ? p[0].txId : ''
            })
        }
    }

  return (
    <>
    <div className="tw-min-h-screen tw-w-full">
        
      <div className="tw-w-full tw-flex tw-flex-col xl:tw-flex-row xl:tw-space-x-5 xl:tw-justify-center xl:tw-items-start">
        <div className=" tw-flex tw-flex-col tw-w-full tw-p-5  tw-space-y-5 tw-items-end md:tw-items-center  tw-mb-2 tw-bg-base-300">
          <div className="tw-w-full tw-text-left" >
            <p className="tw-text-3xl  tw-font-title tw-mb-4">Positions&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
          </div>

          <div className="tw-w-full tw-px-1 md:tw-px-10 tw-flex tw-text-center tw-flex-row tw-space-x-1  ">
            <p className="tw-w-2/5 tw-text-left">Token</p>
            <p className="tw-w-1/5">Amount</p>
            <p className="tw-w-1/5">Value</p>
            <p className="tw-w-1/5">Trade</p>
          </div>

          <div className="tw-w-full">
            {positions.length > 0 ? <div>

              {positions.map((val: any) => {
                const src = getTeamImg(val.sport, val.team)
                return <div className="tw-w-full tw-px-1 md:tw-px-10 tw-flex tw-text-center tw-flex-row tw-items-center tw-font-bold tw-space-x-1">
                  <div className="tw-w-2/5 tw-flex tw-flex-row md:tw-justify-start tw-space-x-1 tw-items-center">
                    <img className="tw-h-6" src={ images[src] } alt='team-img' />
                    <p className="">{val.name} {shortenName(val.sport)}</p>
                  </div>
                  <p className="tw-w-1/5 tw-text-xl">{val.balance.toFixed(2)}</p>
                  <div className="tw-w-1/5 tw-flex tw-flex-row tw-justify-center">
                    <p >{(val.value.toFixed(2))}</p>
                  </div>

                  <div className="tw-w-1/5 tw-flex tw-flex-row tw-justify-center">
                    <button
                    className=" tw-bg-base-200 tw-text-black tw-py-1 tw-px-4 tw-rounded-lg"
                      onClick={() => {
                        dispatch(actions.setSwapFromToken(val.token));
						const tokenName = val.token;
						const g = val.gamePool;
						const t = new TokenObject( tokenName, false, g, val.outcome, val.team, g.wagerToken, val.balance );
						const s = new SwapObject(g, val.outcome, false, [t], t);
						// const swapObject = new SwapObject();
                        dispatch(actions.setSwapObject(s));
                        history.push(`/trade`);
                      }}

                    >
                      Sell
                    </button>
                  </div>
                </div>
              })}
            </div> :
              <p className="tw-font-title tw-text-xl tw-text-center tw-my-8">No Positions</p>
            }
          </div>

        </div>
      </div>
    </div>
    </>
  );
};

export default Positions;
