import soccerArg from './logos/soccer/ARG.png'
import soccerAus from './logos/soccer/AUS.png'
import soccerBel from './logos/soccer/BEL.png'
import soccerBra from './logos/soccer/BRA.png'
import soccerCam from './logos/soccer/CAM.png'
import soccerCan from './logos/soccer/CAN.png'
import soccerCos from './logos/soccer/COS.png'
import soccerCro from './logos/soccer/CRO.png'
import soccerDen from './logos/soccer/DEN.png'
import soccerEcu from './logos/soccer/ECU.png'
import soccerEng from './logos/soccer/ENG.png'
import soccerFra from './logos/soccer/FRA.png'
import soccerGer from './logos/soccer/GER.png'
import soccerGha from './logos/soccer/GHA.png'
import soccerIra from './logos/soccer/IRA.png'
import soccerJap from './logos/soccer/JAP.png'
import soccerMex from './logos/soccer/MEX.png'
import soccerMor from './logos/soccer/MOR.png'
import soccerNet from './logos/soccer/NET.png'
import soccerPol from './logos/soccer/POL.png'
import soccerPor from './logos/soccer/POR.png'
import soccerQat from './logos/soccer/QAT.png'
import soccerSau from './logos/soccer/SAU.png'
import soccerSen from './logos/soccer/SEN.png'
import soccerSer from './logos/soccer/SER.png'
import soccerSou from './logos/soccer/SOU.png'
import soccerSpa from './logos/soccer/SPA.png'
import soccerSwi from './logos/soccer/SWI.png'
import soccerTun from './logos/soccer/TUN.png'
import soccerUru from './logos/soccer/URU.png'
import soccerUsa from './logos/soccer/USA.png'
import soccerWal from './logos/soccer/WAL.png'

import y00ts_no from './logos/y00ts/y00t-no.png'
import y00ts_yes from './logos/y00ts/y00t-yes.png'

import nflEzekielElliot from './logos/nfl/EzekielElliot.png'
import nflJalenHurts from './logos/nfl/JalenHurts.png'
import nflMattRyan from './logos/nfl/MattRyan.png'
import nflMichaelPittman from './logos/nfl/MichaelPittman.png'
import nflPhillipLindsay from './logos/nfl/PhillipLindsay.png'
import nflNyheimHines from './logos/nfl/NyheimHines.png'
import nflMitchTrubisky from './logos/nfl/MitchTrubisky.png'
import nflDiontaeJohnson from './logos/nfl/DiontaeJohnson.png'
import nflJacobyBrissett from './logos/nfl/JacobyBrissett.png'
import nflAmariCooper from './logos/nfl/AmariCooper.png'
import nflJoeBurrow from './logos/nfl/JoeBurrow.png'
import nflLamarJackson from './logos/nfl/LamarJackson.png'
import nflAndyDalton from './logos/nfl/AndyDalton.png'
import nflTuaTagovailoa from './logos/nfl/TuaTagovailoa.png'
import nflTeeHiggins from './logos/nfl/TeeHiggins.png'
import nflJaylenWaddle from './logos/nfl/JaylenWaddle.png'
import nflTyreekHill from './logos/nfl/TyreekHill.png'
import nflJamarrChase from './logos/nfl/JamarrChase.png'
import nflJoshJacobs from './logos/nfl/JoshJacobs.png'
import nflClydeEdwardsHelaire from './logos/nfl/ClydeEdwardsHelaire.png'
import nflDerekCarr from './logos/nfl/DerekCarr.png'
import nflAri from './logos/nfl/ARI.png'
import nflAtl from './logos/nfl/ATL.png'
import nflBal from './logos/nfl/BAL.png'
import nflBuf from './logos/nfl/BUF.png'
import nflCar from './logos/nfl/CAR.png'
import nflChi from './logos/nfl/CHI.png'
import nflCin from './logos/nfl/CIN.png'
import nflCle from './logos/nfl/CLE.png'
import nflDal from './logos/nfl/DAL.png'
import nflDen from './logos/nfl/DEN.png'
import nflDet from './logos/nfl/DET.png'
import nflGnb from './logos/nfl/GNB.png'
import nflHou from './logos/nfl/HOU.png'
import nflInd from './logos/nfl/IND.png'
import nflJax from './logos/nfl/JAX.png'
import nflKan from './logos/nfl/KAN.png'
import nflLvr from './logos/nfl/LVR.png'
import nflLac from './logos/nfl/LAC.png'
import nflLar from './logos/nfl/LAR.png'
import nflMia from './logos/nfl/MIA.png'
import nflMin from './logos/nfl/MIN.png'
import nflNep from './logos/nfl/NEP.png'
import nflNor from './logos/nfl/NOR.png'
import nflNyg from './logos/nfl/NYG.png'
import nflNyj from './logos/nfl/NYJ.png'
import nflPhi from './logos/nfl/PHI.png'
import nflPit from './logos/nfl/PIT.png'
import nflSfo from './logos/nfl/SFO.png'
import nflSea from './logos/nfl/SEA.png'
import nflTam from './logos/nfl/TAM.png'
import nflTen from './logos/nfl/TEN.png'
import nflWas from './logos/nfl/WAS.png'
import nflKan2 from './logos/nfl/KAN copy.png'
import nflLac2 from './logos/nfl/LAC copy.png'
import cfbFres2 from './logos/cfb/FRES copy.png'
import cfbUsc2 from './logos/cfb/USC copy.png'
import cfbVt from './logos/cfb/VT.png'
import cfbCal from './logos/cfb/CAL.png'

import cfbHou from './logos/cfb/HOU.png'
import cfbSjst from './logos/cfb/SJST.png'
import cfbMem from './logos/cfb/MEM.png'
import cfbVan from './logos/cfb/VAN.png'
import cfbUnlv from './logos/cfb/UNLV.png'
import cfbNavy from './logos/cfb/NAVY.png'
import cfbSmu from './logos/cfb/SMU.png'
import cfbTul from './logos/cfb/TUL.png'

import nflMikeEvans from './logos/nfl/MikeEvans.png'
import nflChrisGodwin from './logos/nfl/ChrisGodwin.png'
import nflJuJuSmithSchuster from './logos/nfl/JuJuSmithSchuster.png'
import nflTravisKelce from './logos/nfl/TravisKelce.png'
import nflTomBrady from './logos/nfl/TomBrady.png'
import nflPatrickMahomes from './logos/nfl/PatrickMahomes.png'

import nflCooperKupp from './logos/nfl/CooperKupp.png'
import nflDeeboSamuel from './logos/nfl/DeeboSamuel.png'
import nflJeffWilson from './logos/nfl/JeffWilson.png'
import nflCamAkers from './logos/nfl/CamAkers.png'
import nflMatthewStafford from './logos/nfl/MatthewStafford.png'
import nflJimmyGaroppolo from './logos/nfl/JimmyGaroppolo.png'

import nflRussellWilson from './logos/nfl/RussellWilson.png'
import nflJustinHerbert from './logos/nfl/JustinHerbert.png'
import nflGenoSmith from './logos/nfl/GenoSmith.png'
import nflCourtlandSutton from './logos/nfl/CourtlandSutton.png'
import nflAjBrown from './logos/nfl/AjBrown.png'
import nflJustinJefferson from './logos/nfl/JustinJefferson.png'
import nflDkMetcalf from './logos/nfl/DkMetcalf.png'
import nflUnder445 from './logos/nfl/under-44-5.png'
import nflOver445 from './logos/nfl/over-44-5.png'
import nflUnder535 from './logos/nfl/under-53-5.png'
import nflOver535 from './logos/nfl/over-53-5.png'
import nflCooperRush from './logos/nfl/CooperRush.png'
import nflDanielJones from './logos/nfl/DanielJones.png'
import cfbOver25 from './logos/cfb/over-2-5.png'
import cfbUnder25 from './logos/cfb/under-2-5.png'
import valorantLoud from './logos/valorant/LOUD.png'
import valorantOptic from './logos/valorant/OPTIC.png'
import unlSct from './logos/unl/SCT.png'
import unlUa from './logos/unl/UA.png'
import unlGer from './logos/unl/GER.png'
import unlEng from './logos/unl/ENG.png'
import unlHun from './logos/unl/HUN.png'
import unlIta from './logos/unl/ITA.png'
import unlSwi from './logos/unl/SWI.png'
import unlCz from './logos/unl/CZ.png'
import unlPt from './logos/unl/PT.png'
import unlEs from './logos/unl/ES.png'

import dotaBeast from './logos/dota/BEAST.png'
import dotaBoom from './logos/dota/BOOM.png'
import dotaEg from './logos/dota/EG.png'
import dotaLgd from './logos/dota/LGD.png'

import dotaLiquid from './logos/dota/LIQUID.png'
import dotaEntity from './logos/dota/ENTITY.png'
import dotaOg from './logos/dota/OG.png'
import dotaGaimin from './logos/dota/GAIMIN.png'
import dotaAster from './logos/dota/ASTER.png'
import dotaTundra from './logos/dota/TUNDRA.png'

import csgoG2 from './logos/csgo/g2.png'
import csgoVitality from './logos/csgo/vitality.png'
import csgoComplexity from './logos/csgo/complexity.png'
import csgoFaze from './logos/csgo/faze.png'
import csgoFnatic from './logos/csgo/fnatic.png'
import csgoLiquid from './logos/csgo/liquid.png'
import csgoFuria from './logos/csgo/furia.png'
import csgoHeroic from './logos/csgo/heroic.png'
import csgoNatus from './logos/csgo/natus.png'
import csgoOutsiders from './logos/csgo/outsiders.png'
import csgoCloud9 from './logos/csgo/cloud9.png'
import csgoMouz from './logos/csgo/mouz.png'

import lolCloud9 from './logos/lol/cloud9.png'
import lolEdwardGaming from './logos/lol/edwardgaming.png'
import lolFnatic from './logos/lol/fnatic.png'
import lolGeng from './logos/lol/geng.png'
import lolRng from './logos/lol/rng.png'
import lolT1 from './logos/lol/t1.png'

import cricketEng from './logos/cricket/ENG.png'
import cricketIn from './logos/cricket/IN.png'
import cricketPk from './logos/cricket/PK.png'
import cricketSa from './logos/cricket/SA.png'

import soccerRva from './logos/soccer/RVA.png'
import soccerVilla from './logos/soccer/VILLA.png'
import soccerRoma from './logos/soccer/Roma.png'
import uelPsve from './logos/soccer/PSVE.png'
import soccerPsve from './logos/soccer/PSVE.png'
import soccerBetis from './logos/soccer/Betis.png'
import soccerSev from './logos/soccer/SEV.png'
import soccerVal from './logos/soccer/VAL.png'
import soccerAtm from './logos/soccer/ATM.png'
import soccerCol from './logos/soccer/COL.png'
import soccerChi from './logos/soccer/CHI.png'
import soccerSa from './logos/soccer/SA.png'
import soccerLev from './logos/soccer/LEV.png'
import soccerFcb from './logos/soccer/FCB.png'
import soccerFcp from './logos/soccer/FCP.png'
import soccerScb from './logos/soccer/SCB.png'
import soccerDor from './logos/soccer/DOR.png'
import soccerMad from './logos/soccer/MAD.png'
import soccerSlb from './logos/soccer/SLB.png'

import under298 from './logos/cfb/under-298-5.png'
import over298 from './logos/cfb/over-298-5.png'
import over56 from './logos/cfb/over-56-5.png'
import under56 from './logos/cfb/under-56-5.png'

import usflPHI from './logos/usfl/PHI.png'
import usflBIR from './logos/usfl/BIR.png'

import nblAde from './logos/nbl/ADE.png'
import nblIll from './logos/nbl/ILL.png'

import atlNbaLogo from './logos/nba/ATL.png'
import bknNbaLogo from './logos/nba/BKN.png'
import bosNbaLogo from './logos/nba/BOS.png'
import chaNbaLogo from './logos/nba/CHA.png'
import chiNbaLogo from './logos/nba/CHI.png'
import cleNbaLogo from './logos/nba/CLE.png'
import dalNbaLogo from './logos/nba/DAL.png'
import denNbaLogo from './logos/nba/DEN.png'
import detNbaLogo from './logos/nba/DET.png'
import gswNbaLogo from './logos/nba/GSW.png'
import houNbaLogo from './logos/nba/HOU.png'
import indNbaLogo from './logos/nba/IND.png'
import lacNbaLogo from './logos/nba/LAC.png'
import lalNbaLogo from './logos/nba/LAL.png'
import memNbaLogo from './logos/nba/MEM.png'
import miaNbaLogo from './logos/nba/MIA.png'
import milNbaLogo from './logos/nba/MIL.png'
import minNbaLogo from './logos/nba/MIN.png'
import nopNbaLogo from './logos/nba/NOP.png'
import nykNbaLogo from './logos/nba/NYK.png'
import okcNbaLogo from './logos/nba/OKC.png'
import orlNbaLogo from './logos/nba/ORL.png'
import phiNbaLogo from './logos/nba/PHI.png'
import phxNbaLogo from './logos/nba/PHX.png'
import porNbaLogo from './logos/nba/POR.png'
import sacNbaLogo from './logos/nba/SAC.png'
import sasNbaLogo from './logos/nba/SAS.png'
import torNbaLogo from './logos/nba/TOR.png'
import utaNbaLogo from './logos/nba/UTA.png'
import wasNbaLogo from './logos/nba/WAS.png'
import nbaStephenCurry from './logos/nba/StephenCurry.png'
import nbaLebronJames from './logos/nba/LebronJames.png'

import carNhlLogo from './logos/nhl/CAR.png'
import nyrNhlLogo from './logos/nhl/NYR.png'
import edmNhlLogo from './logos/nhl/EDM.png'
import colNhlLogo from './logos/nhl/COL.png'
import tamNhlLogo from './logos/nhl/TAM.png'
import nhlDal from './logos/nhl/DAL.png'
import nhlMon from './logos/nhl/MON.png'
import nhlTor from './logos/nhl/TOR.png'
import nhlLv from './logos/nhl/LV.png'
import nhlLak from './logos/nhl/LAK.png'
import nhlVan from './logos/nhl/VAN.png'
import nhlChi from './logos/nhl/CHI.png'
import nhlWas from './logos/nhl/WAS.png'
import nhlMin from './logos/nhl/MIN.png'
import nhlCal from './logos/nhl/CAL.png'
import nhlWin from './logos/nhl/WIN.png'
import nhlClb from './logos/nhl/CLB.png'
import nhlSj from './logos/nhl/SJ.png'
import nhlFl from './logos/nhl/FL.png'
import nhlSea from './logos/nhl/SEA.png'
import nhlAna from './logos/nhl/ANA.png'
import nhlBos from './logos/nhl/BOS.png'
import nhlBuf from './logos/nhl/BUF.png'
import nhlNash from './logos/nhl/NASH.png'
import nhlNj from './logos/nhl/NJ.png'
import nhlNyi from './logos/nhl/NYI.png'
import nhlOtt from './logos/nhl/OTT.png'
import nhlPhi from './logos/nhl/PHI.png'
import nhlStl from './logos/nhl/STL.png'
import nhlPitt from './logos/nhl/PITT.png'

import uelMunLogo from './logos/uel/MUN.png'
import uelOlyLogo from './logos/uel/OLY.png'
import uelScfLogo from './logos/uel/SCF.png'
import uelSheLogo from './logos/uel/SHE.png'
import uelRoma from './logos/uel/ROMA.png'
import uelBetis from './logos/uel/BETIS.png'
import uelTrab from './logos/uel/TRAB.png'
import uelMonaco from './logos/uel/MONACO.png'
import uelAsm from './logos/uel/ASM.png'
import uelFvs from './logos/uel/FVS.png'
import uelFyd from './logos/uel/FYD.png'
import uelLzo from './logos/uel/LZO.png'
import uelScd from './logos/uel/SCD.png'

import emmettMmaLogo from './logos/mma/Emmett.png'
import kattarMmaLogo from './logos/mma/Kattar.png'
import tsarukyanMmaLogo from './logos/mma/Tsarukyan.png'
import gamrotMmaLogo from './logos/mma/Gamrot.png'
import magnyMmaLogo from './logos/mma/Magny.png'
import rakhmonovMmaLogo from './logos/mma/Rakhmonov.png'
import hollowayMmaLogo from './logos/mma/Holloway.png'
import volkanovskiMmaLogo from './logos/mma/Volkanovski.png'
import cannonierMmaLogo from './logos/mma/Cannonier.png'
import adesanyaMmaLogo from './logos/mma/Adesanya.png'
import mmaRodriguez from './logos/mma/Rodriguez.png'
import mmaOrtega from './logos/mma/Ortega.png'
import mmaBlaydes from './logos/mma/Blaydes.png'
import mmaAspinall from './logos/mma/Aspinall.png'
import mmaNunes from './logos/mma/NUNES.png'
import mmaPena from './logos/mma/PENA.png'
import mmaFrance from './logos/mma/FRANCE.png'
import mmaMoreno from './logos/mma/MORENO.png'
import mmaThiagoSantos from './logos/mma/thiago_santos.png'
import mmaJamahalHill from './logos/mma/jamahal_hill.png'
import mmaDavidOnama from './logos/mma/david_onama.png'
import mmaNateLandwehr from './logos/mma/nate_landwehr.png'
import mmaDominickCruz from './logos/mma/dominick_cruz.png'
import mmaMarlonVera from './logos/mma/marlon_vera.png'
import mmaLeonEdwards from './logos/mma/LeonEdwards.png'
import mmaYanXiaonan from './logos/mma/YanXiaonan.png'
import mmaMackenzieDern from './logos/mma/MackenzieDern.png'
import mmaPauloCosta from './logos/mma/PauloCosta.png'
import mmaKamaruUsman from './logos/mma/KamaruUsman.png'
import mmaLukeRockhold from './logos/mma/LukeRockhold.png'
import mmaKhamzatChimaev from './logos/mma/KhamzatChimaev.png'
import mmaNateDiaz from './logos/mma/NateDiaz.png'
import mmaLiJingliang from './logos/mma/LiJingliang.png'
import mmaKevinHolland from './logos/mma/KevinHolland.png'
import mmaDanielRodriguez from './logos/mma/DanielRodriguez.png'
import mmaTonyFerguson from './logos/mma/TonyFerguson.png'
import mmaSongYadong from './logos/mma/SongYadong.png'
import mmaCorySandhagen from './logos/mma/CorySandhagen.png'

import mmaSeanOMalley from './logos/mma/SeanOMalley.png'
import mmaPetrYan from './logos/mma/PetrYan.png'
import mmaTJDillashaw from './logos/mma/TJDillashaw.png'
import mmaAljamainSterling from './logos/mma/AljamainSterling.png'
import mmaIslamMakhachev from './logos/mma/IslamMakhachev.png'
import mmaCharlesOliveira from './logos/mma/CharlesOliveira.png'

import ajGriffinNbaLogo from './logos/nba/aj-griffin.png'
import bennedictMathurinNbaLogo from './logos/nba/bennedict-mathurin.png'
import chetHolmgrenNbaLogo from './logos/nba/chet-holmgren.png'
import dysonDanielsNbaLogo from './logos/nba/dyson-daniels.png'
import jabariSmithJrNbaLogo from './logos/nba/jabari-smith-jr.png'
import jadenIveyNbaLogo from './logos/nba/jaden-ivey.png'
import jalenDurenNbaLogo from './logos/nba/jalen-duren.png'
import johnnyDavisNbaLogo from './logos/nba/johnny-davis.png'
import keeganMurrayNbaLogo from './logos/nba/keegan-murray.png'
import paoloBancheroNbaLogo from './logos/nba/paolo-banchero.png'
import shaedonSharpeNbaLogo from './logos/nba/shaedon-sharpe.png'

import mlbAl from './logos/mlb/AL.png'
import mlbNl from './logos/mlb/NL.png'
import mlbOhtani from './logos/mlb/OHTANI.png'
import mlbAlonso from './logos/mlb/ALONSO.png'
import mlbOthers from './logos/mlb/OTHERS.png'

import clAja from './logos/cl/AJA.png'
import clAtm from './logos/cl/ATM.png'
import clB04 from './logos/cl/B04.png'
import clBay from './logos/cl/BAY.png'
import clBru from './logos/cl/BRU.png'
import clCel from './logos/cl/CEL.png'
import clCop from './logos/cl/COP.png'
import clDyn from './logos/cl/DYN.png'
import clDor from './logos/cl/DOR.png'
import clDza from './logos/cl/DZA.png'
import clFcb from './logos/cl/FCB.png'
import clHai from './logos/cl/HAI.png'
import clInt from './logos/cl/INT.png'
import clJuv from './logos/cl/JUV.png'
import clLiv from './logos/cl/LIV.png'
import clMad from './logos/cl/MAD.png'
import clMci from './logos/cl/MCI.png'
import clMil from './logos/cl/MIL.png'
import clNap from './logos/cl/NAP.png'
import clOlm from './logos/cl/OLM.png'
import clPor from './logos/cl/POR.png'
import clRbl from './logos/cl/RBL.png'
import clRbs from './logos/cl/RBS.png'
import clRfc from './logos/cl/RFC.png'
import clPsg from './logos/cl/PSG.png'
import clPsv from './logos/cl/PSV.png'
import clSev from './logos/cl/SEV.png'
import clSge from './logos/cl/SGE.png'
import clShk from './logos/cl/SHK.png'
import clSlb from './logos/cl/SLB.png'
import clSli from './logos/cl/SLI.png'
import clTot from './logos/cl/TOT.png'
import clVpl from './logos/cl/VPL.png'
import clHarryKane from './logos/cl/HarryKane.png'
import clRobertLewandowski from './logos/cl/RobertLewandowski.png'
import clChe from './logos/cl/CHE.png'

import eplArs from './logos/epl/ARS.png'
import eplAvl from './logos/epl/AVL.png'
import eplBou from './logos/epl/BOU.png'
import eplBre from './logos/epl/BRE.png'
import eplBri from './logos/epl/BRI.png'
import eplChe from './logos/epl/CHE.png'
import eplCry from './logos/epl/CRY.png'
import eplEve from './logos/epl/EVE.png'
import eplFul from './logos/epl/FUL.png'
import eplLee from './logos/epl/LEE.png'
import eplLei from './logos/epl/LEI.png'
import eplLiv from './logos/epl/LIV.png'
import eplMci from './logos/epl/MCI.png'
import eplMun from './logos/epl/MUN.png'
import eplNew from './logos/epl/NEW.png'
import eplNot from './logos/epl/NOT.png'
import eplSou from './logos/epl/SOU.png'
import eplTot from './logos/epl/TOT.png'
import eplWhu from './logos/epl/WHU.png'
import eplWol from './logos/epl/WOL.png'

import mlbArz from './logos/mlb/ARZ.png'
import mlbAtl from './logos/mlb/ATL.png'
import mlbBal from './logos/mlb/BAL.png'
import mlbBos from './logos/mlb/BOS.png'
import mlbCws from './logos/mlb/CWS.png'
import mlbCin from './logos/mlb/CIN.png'
import mlbCle from './logos/mlb/CLE.png'
import mlbCol from './logos/mlb/COL.png'
import mlbDet from './logos/mlb/DET.png'
import mlbHou from './logos/mlb/HOU.png'
import mlbKan from './logos/mlb/KAN.png'
import mlbLaa from './logos/mlb/LAA.png'
import mlbLad from './logos/mlb/LAD.png'
import mlbMia from './logos/mlb/MIA.png'
import mlbMil from './logos/mlb/MIL.png'
import mlbMin from './logos/mlb/MIN.png'
import mlbNym from './logos/mlb/NYM.png'
import mlbNyy from './logos/mlb/NYY.png'
import mlbOak from './logos/mlb/OAK.png'
import mlbPhi from './logos/mlb/PHI.png'
import mlbPit from './logos/mlb/PIT.png'
import mlbSdp from './logos/mlb/SDP.png'
import mlbSfg from './logos/mlb/SFG.png'
import mlbSea from './logos/mlb/SEA.png'
import mlbStl from './logos/mlb/STL.png'
import mlbTam from './logos/mlb/TAM.png'
import mlbTex from './logos/mlb/TEX.png'
import mlbTor from './logos/mlb/TOR.png'
import mlbWas from './logos/mlb/WAS.png'

import ncaabDuke from './logos/cfb/DUKE.png'
import ncaabKu from './logos/cfb/KU.png'
import ncaabUk from './logos/cfb/UK.png'
import ncaabMsu from './logos/cfb/MSU.png'

import cfbNev from './logos/cfb/NEV.png'
import cfbFres from './logos/cfb/FRES.png'
import cfbHaw from './logos/cfb/HAW.png'
import cfbPennst from './logos/cfb/PENNST.png'
import cfbMar from './logos/cfb/MAR.png'
import cfbKu from './logos/cfb/KU.png'
import cfbBsu from './logos/cfb/BSU.png'
import cfbUw from './logos/cfb/UW.png'
import cfbUcla from './logos/cfb/UCLA.png'
import cfbOkla from './logos/cfb/OKLA.png'
import cfbSdsu from './logos/cfb/SDSU.png'

import cfbAzst from './logos/cfb/AZST.png'
import cfbNcst from './logos/cfb/NCST.png'
import cfbOkst from './logos/cfb/OKST.png'
import cfbMiss from './logos/cfb/MISS.png'
import cfbIowa from './logos/cfb/IOWA.png'
import cfbMich from './logos/cfb/MICH.png'
import cfbUsc from './logos/cfb/USC.png'
import cfbTamu from './logos/cfb/TAMU.png'
import cfbArk from './logos/cfb/ARK.png'
import cfbOrst from './logos/cfb/ORST.png'
import cfbTtu from './logos/cfb/TTU.png'
import cfbBay from './logos/cfb/BAY.png'
import cfbWfu from './logos/cfb/WFU.png'
import cfbIsu from './logos/cfb/ISU.png'
import cfbVir from './logos/cfb/VIR.png'
import cfbSyr from './logos/cfb/SYR.png'
import cfbNeb from './logos/cfb/NEB.png'
import cfbNww from './logos/cfb/NWW.png'
import cfbOre from './logos/cfb/ORE.png'
import cfbWmu from './logos/cfb/WMU.png'
import cfbMsu from './logos/cfb/MSU.png'
import cfbClem from './logos/cfb/CLEM.png'
import cfbFsu from './logos/cfb/FSU.png'
import cfbGt from './logos/cfb/GT.png'
import cfbLsu from './logos/cfb/LSU.png'
import cfbUf from './logos/cfb/UF.png'
import cfbUtah from './logos/cfb/UTAH.png'
import cfbNd from './logos/cfb/ND.png'
import cfbOsu from './logos/cfb/OSU.png'
import cfbGeo from './logos/cfb/GEO.png'
import cfbPit from './logos/cfb/PIT.png'
import cfbWvu from './logos/cfb/WVU.png'
import cfbTcu from './logos/cfb/TCU.png'
import cfbCu from './logos/cfb/CU.png'
import cfbAla from './logos/cfb/ALA.png'
import cfbLou from './logos/cfb/LOU.png'
import cfbTenn from './logos/cfb/TENN.png'
import cfbTex from './logos/cfb/TEX.png'
import cfbGonz from './logos/cfb/GONZ.png'
import cfbEmu from './logos/cfb/EMU.png'
import cfbKent from './logos/cfb/KENT.png'
import cfbUcf from './logos/cfb/UCF.png'
import cfbByu from './logos/cfb/BYU.png'
import cfbOsuMinus31 from './logos/cfb/OSU copy.png'
import cfbScarPlus24 from './logos/cfb/SCAR copy.png'
import cfbTolPlus31 from './logos/cfb/TOL copy.png'
import cfbGeoMinus24 from './logos/cfb/GEO copy.png'
import cfbUk from './logos/cfb/UK.png'
import cfbU3285 from './logos/cfb/under-328-5.png'
import cfbO3285 from './logos/cfb/over-328-5.png'

import big3Killer3s from './logos/big3/killer3s.png'
import big3Bivouac from './logos/big3/bivouac.png'
import big3Aliens from './logos/big3/aliens.png'

import vsGold from './assets/vs-gold.png'
import vsPurple from './assets/vs-purple.png'
import clock from './assets/clock.png'
import games from './assets/games.png'
import swap from './assets/swap.png'
import swapArrows from './assets/swap-arrows.png'
import myPositions from './assets/my-positions.png'
import myTrades from './assets/my-trades.png'
import myPayouts from './assets/auto-payouts.png'
import leaderboard from './assets/leaderboard.png'

import logo from './props-logo.png'
import usdc from './usdc.png'
import props from './props-logo.png'
import pbt from './pbt.png'
import sol from './sol.png'
import mSOL from './logos/tokens/msol.png'
import usdh from './logos/tokens/usdh.png'
import dust from './logos/tokens/dust.png'
import avatar from './avatar.png'
import twitter from './assets/twitter-blue.png'
import walletIcon from './assets/wallet-icon.png'
import connectWallet from './assets/connect-wallet.png'
import discord from './discord.png'
import discordWhite from './assets/discord-white.png'
import tieToken from './assets/tie-token.png'
import drawToken from './assets/draw-token.png'
import under from './assets/under.png'
import over from './assets/over.png'
import yes from './yes.png'
import no from './no.png'

import golfPatrickCantlay from './logos/golf/PatrickCantlay.png'
import golfHidekiMatsuyama from './logos/golf/HidekiMatsuyama.png'
import golfScottieScheffler from './logos/golf/ScottieScheffler.png'
import golfSungjaeIm from './logos/golf/SungjaeIm.png'
import golfJustinThomas from './logos/golf/JustinThomas.png'
import golfAdamScott from './logos/golf/AdamScott.png'
import golfZalatoris from './logos/golf/will_zalatoris.png'
import golfYoung from './logos/golf/cameron_young.png'

import tennisCARLOSALCARAZ from './logos/tennis/CarlosAlcaraz.png'
import tennisCASPERRUUD from './logos/tennis/CasperRuud.png'

import electionDemocrat from './logos/election/democrat.png'
import electionRepublican from './logos/election/republican.png'

const images: { [key: string]: string } = {
	Election_DEMOCRATSCONTROLTHESENATE: electionDemocrat,
	Election_REPUBLICANSCONTROLTHESENATE: electionRepublican,
	Election_DEMOCRATSCONTROLTHEHOUSE: electionDemocrat,
	Election_REPUBLICANSCONTROLTHEHOUSE: electionRepublican,

	y00ts_Y00TS_REVEAL_NO: y00ts_no,
	y00ts_Y00TS_REVEAL_YES: y00ts_yes,

	GOLF_ZALATORIS: golfZalatoris,
	GOLF_YOUNG: golfYoung,

	Tennis_CARLOSALCARAZ: tennisCARLOSALCARAZ,
	Tennis_CASPERRUUD: tennisCASPERRUUD,

	NFL_ANDY_DALTON: nflAndyDalton,
	NFL_LAMAR_JACKSON: nflLamarJackson,
	NFL_LAMARJACKSON: nflLamarJackson,
	NFL_JOEBURROW: nflJoeBurrow,
	NFL_TUATAGOVAILOA: nflTuaTagovailoa,
	NFL_JAYLENWADDLE: nflJaylenWaddle,
	NFL_TEEHIGGINS: nflTeeHiggins,
	NFL_TYREEKHILL: nflTyreekHill,
	NFL_AMARICOOPER: nflAmariCooper,
	NFL_DIONTAEJOHNSON: nflDiontaeJohnson,
	NFL_JACOBYBRISSETT: nflJacobyBrissett,
	NFL_MITCHTRUBISKY: nflMitchTrubisky,
	NFL_JAMARRCHASE: nflJamarrChase,
	NFL_CLYDEEDWARDSHELAIRE: nflClydeEdwardsHelaire,
	NFL_JOSHJACOBS: nflJoshJacobs,
	NFL_DEREKCARR: nflDerekCarr,

	NFL_NYHEIMHINES: nflNyheimHines,
	NFL_EZEKIELELLIOT: nflEzekielElliot,
	NFL_JALENHURTS: nflJalenHurts,
	NFL_MATTRYAN: nflMattRyan,
	NFL_MICHAELPITTMAN: nflMichaelPittman,
	NFL_PHILLIPLINDSAY: nflPhillipLindsay,

	NFL_ARI: nflAri,
	NFL_ATL: nflAtl,
	NFL_BAL: nflBal,
	NFL_BUF: nflBuf,
	NFL_CAR: nflCar,
	NFL_CHI: nflChi,
	NFL_CIN: nflCin,
	NFL_CLE: nflCle,
	NFL_DAL: nflDal,
	NFL_DEN: nflDen,
	NFL_DET: nflDet,
	NFL_GNB: nflGnb,
	NFL_HOU: nflHou,
	NFL_IND: nflInd,
	NFL_JAX: nflJax,
	NFL_KAN: nflKan,
	NFL_LVR: nflLvr,
	NFL_LAC: nflLac,
	NFL_LAR: nflLar,
	NFL_MIA: nflMia,
	NFL_MIN: nflMin,
	NFL_NEP: nflNep,
	NFL_NOR: nflNor,
	NFL_NYG: nflNyg,
	NFL_NYJ: nflNyj,
	NFL_PHI: nflPhi,
	NFL_PIT: nflPit,
	NFL_SFO: nflSfo,
	NFL_SEA: nflSea,
	NFL_TAM: nflTam,
	NFL_TEN: nflTen,
	NFL_WAS: nflWas,
	NFL_GENOSMITH: nflGenoSmith,
	NFL_PATRICKMAHOMES: nflPatrickMahomes,

	NFL_COOPERKUPP: nflCooperKupp,
	NFL_DEEBOSAMUEL: nflDeeboSamuel,
	NFL_JEFFWILSON: nflJeffWilson,
	NFL_CAMAKERS: nflCamAkers,
	NFL_MATTHEWSTAFFORD: nflMatthewStafford,
	NFL_JIMMYGAROPPOLO: nflJimmyGaroppolo,

	NFL_TOMBRADY: nflTomBrady,
	NFL_TRAVISKELCE: nflTravisKelce,
	NFL_JUJUSMITHSCHUSTEER: nflJuJuSmithSchuster,
	NFL_CHRISGODWIN: nflChrisGodwin,
	NFL_MIKEEVANS: nflMikeEvans,

	NFL_JUSTINHERBERT: nflJustinHerbert,
	NFL_DANIELJONES: nflDanielJones,
	NFL_COOPERRUSH: nflCooperRush,
	NFL_RUSSELLWILSON: nflRussellWilson,
	NFL_COURTLANDSUTTON: nflCourtlandSutton,
	NFL_DKMETCALF: nflDkMetcalf,
	NFL_KAN_MINUS_3_5: nflKan2,
	NFL_LAC_PLUS_3_5: nflLac2,

	NCAAB_MEM: cfbMem,
	NCAAB_VAN: cfbVan,
	NCAAB_TEX: cfbTex,
	NCAAB_GONZ: cfbGonz,

	CFB_KENT: cfbKent,
	CFB_EMU: cfbEmu,
	CFB_HOU: cfbHou,
	CFB_MEM: cfbMem,
	CFB_UNLV: cfbUnlv,
	CFB_SJST: cfbSjst,
	CFB_NAVY: cfbNavy,
	CFB_SMU: cfbSmu,
	CFB_TUL: cfbTul,

	CFB_VT: cfbVt,
	CFB_CAL: cfbCal,
	CFB_USC_MINUS_11: cfbUsc2,
	CFB_FRES_PLUS_11: cfbFres2,
	NFL_MAHOMES_OVER_17_5_RYDS: nflPatrickMahomes,
	NFL_MAHOMES_UNDER_17_5_RYDS: nflPatrickMahomes,
	CFB_CUNNINGHAM_UNDER_298_5_RUSH_AND_PASS_YDS: under298,
	CFB_CUNNINGHAM_OVER_298_5_RUSH_AND_PASS_YDS: over298,
	CFB_LOU_FSU_OVER_56_5: over56,
	CFB_LOU_FSU_UNDER_56_5: under56,
	CFB_CALEB_WILLIAMS_PASS_TD_UNDER_2_5: cfbUnder25,
	CFB_CALEB_WILLIAMS_PASS_TD_OVER_2_5: cfbOver25,
	NFL_KAN_LAC_OVER_53_5: nflOver535,
	NFL_KAN_LAC_UNDER_53_5: nflUnder535,
	NFL_KAN_LAC_OVER_53: nflOver535,
	NFL_KAN_LAC_UNDER_53: nflUnder535,
	NFL_DEN_SEA_OVER_44_5: nflOver445,
	NFL_DEN_SEA_UNDER_44_5: nflUnder445,
	NFL_DEN_SEA_OVER_44: nflOver445,
	NFL_DEN_SEA_UNDER_44: nflUnder445,
	NFL_DEN_SEA_JEUDY_TD_YES: yes,
	NFL_DEN_SEA_JEUDY_TD_NO: no,
	NFL_AJBROWN: nflAjBrown,
	NFL_JUSINJEFFERSON: nflJustinJefferson,
	MLB_AARON_JUDGE_HR_YES: yes,
	MLB_AARON_JUDGE_HR_NO: no,

	Valorant_LOUD: valorantLoud,
	Valorant_OPTIC: valorantOptic,

	DOTA_BEAST: dotaBeast,
	DOTA_BOOM: dotaBoom,
	DOTA_EG: dotaEg,
	DOTA_LGD: dotaLgd,

	DOTA_LIQUID: dotaLiquid,
	DOTA_ENTITY: dotaEntity,
	DOTA_OG: dotaOg,
	DOTA_GAIMIN: dotaGaimin,
	DOTA_ASTER: dotaAster,
	DOTA_TUNDRA: dotaTundra,

	CSGO_G2: csgoG2,
	CSGO_VITALITY: csgoVitality,
	CSGO_COMPLEXITY: csgoComplexity,
	CSGO_FAZE: csgoFaze,
	CSGO_FNATIC: csgoFnatic,
	CSGO_LIQUID: csgoLiquid,
	CSGO_FURIA: csgoFuria,
	CSGO_HEROIC: csgoHeroic,
	CSGO_NATUS: csgoNatus,
	CSGO_OUTSIDERS: csgoOutsiders,
	CSGO_CLOUD9: csgoCloud9,
	CSGO_MOUZ: csgoMouz,

	LoL_CLOUD9: lolCloud9,
	LoL_EDWARDGAMING: lolEdwardGaming,
	LoL_FNATIC: lolFnatic,
	LoL_GENG: lolGeng,
	LoL_RNG: lolRng,
	LoL_T1: lolT1,

	UNL_SCT: unlSct,
	UNL_UA: unlUa,
	UNL_GER: unlGer,
	UNL_ENG: unlEng,
	UNL_HUN: unlHun,
	UNL_ITA: unlIta,

	UNL_ES: unlEs,
	UNL_PT: unlPt,
	UNL_CZ: unlCz,
	UNL_SWI: unlSwi,

	Cricket_ENG: cricketEng,
	Cricket_SA: cricketSa,
	Cricket_PK: cricketPk,
	Cricket_IN: cricketIn,
	Soccer_ARG: soccerArg,
	Soccer_AUS: soccerAus,
	Soccer_BEL: soccerBel,
	Soccer_BRA: soccerBra,
	Soccer_CAM: soccerCam,
	Soccer_CAN: soccerCan,
	Soccer_COS: soccerCos,
	Soccer_CRO: soccerCro,
	Soccer_DEN: soccerDen,
	Soccer_ECU: soccerEcu,
	Soccer_ENG: soccerEng,
	Soccer_FRA: soccerFra,
	Soccer_GER: soccerGer,
	Soccer_GHA: soccerGha,
	Soccer_IRA: soccerIra,
	Soccer_JAP: soccerJap,
	Soccer_MEX: soccerMex,
	Soccer_MOR: soccerMor,
	Soccer_NET: soccerNet,
	Soccer_POL: soccerPol,
	Soccer_POR: soccerPor,
	Soccer_QAT: soccerQat,
	Soccer_SAU: soccerSau,
	Soccer_SEN: soccerSen,
	Soccer_SER: soccerSer,
	Soccer_SOU: soccerSou,
	Soccer_SPA: soccerSpa,
	Soccer_SWI: soccerSwi,
	Soccer_TUN: soccerTun,
	Soccer_URU: soccerUru,
	Soccer_USA: soccerUsa,
	Soccer_WAL: soccerWal,

	UEL_PSVE: soccerPsve,
	Soccer_PSVE: soccerPsve,
	Soccer_ROMA: soccerRoma,
	Soccer_BETIS: soccerBetis,
	Soccer_ATM: soccerAtm,
	Soccer_SEV: soccerSev,
	Soccer_VAL: soccerVal,
	Soccer_COL: soccerCol,
	Soccer_CHI: soccerChi,
	Soccer_SA: soccerSa,
	Soccer_FCB: soccerFcb,
	Soccer_LEV: soccerLev,
	Soccer_FCP: soccerFcp,
	Soccer_SCB: soccerScb,
	Soccer_DOR: soccerDor,
	Soccer_MAD: soccerMad,
	Soccer_SLB: soccerSlb,

	Golf_MATSUYAMA_AND_KIM: golfHidekiMatsuyama,
	Golf_SCHEFFLER_AND_BURNS: golfScottieScheffler,
	Golf_CANTLAY_AND_SCHAUFFELE: golfPatrickCantlay,
	Golf_SCOTT_AND_DAVIS: golfAdamScott,
	Golf_THOMAS_AND_SPIETH: golfJustinThomas,
	Golf_IM_AND_MUNOZ: golfSungjaeIm,

	UEL_MUN: uelMunLogo,
	UEL_OLY: uelOlyLogo,
	UEL_SCF: uelScfLogo,
	UEL_SHE: uelSheLogo,
	UEL_MONACO: uelMonaco,
	UEL_ASM: uelAsm,
	UEL_FVS: uelFvs,
	UEL_ROMA: uelRoma,
	UEL_BETIS: uelBetis,
	UEL_TRAB: uelTrab,
	UEL_FYD: uelFyd,
	UEL_LZO: uelLzo,
	UEL_SCD: uelScd,

	NBL_ADE: nblAde,
	NBL_ILL: nblIll,

	NBA_ATL: atlNbaLogo,
	NBA_BKN: bknNbaLogo,
	NBA_BOS: bosNbaLogo,
	NBA_CHA: chaNbaLogo,
	NBA_CHI: chiNbaLogo,
	NBA_CLE: cleNbaLogo,
	NBA_DAL: dalNbaLogo,
	NBA_DEN: denNbaLogo,
	NBA_DET: detNbaLogo,
	NBA_GSW: gswNbaLogo,
	NBA_HOU: houNbaLogo,
	NBA_IND: indNbaLogo,
	NBA_LAC: lacNbaLogo,
	NBA_LAL: lalNbaLogo,
	NBA_MEM: memNbaLogo,
	NBA_MIA: miaNbaLogo,
	NBA_MIL: milNbaLogo,
	NBA_MIN: minNbaLogo,
	NBA_NOP: nopNbaLogo,
	NBA_NYK: nykNbaLogo,
	NBA_OKC: okcNbaLogo,
	NBA_ORL: orlNbaLogo,
	NBA_PHI: phiNbaLogo,
	NBA_PHX: phxNbaLogo,
	NBA_POR: porNbaLogo,
	NBA_SAC: sacNbaLogo,
	NBA_SAS: sasNbaLogo,
	NBA_TOR: torNbaLogo,
	NBA_UTA: utaNbaLogo,
	NBA_WAS: wasNbaLogo,

	NBA_STEPHENCURRY: nbaStephenCurry,
	NBA_LEBRONJAMES: nbaLebronJames,

	NCAAB_KU: ncaabKu,
	NCAAB_DUKE: ncaabDuke,
	NCAAB_UK: ncaabUk,
	NCAAB_MSU: ncaabMsu,

	CFB_OKLA: cfbOkla,
	CFB_UCLA: cfbUcla,
	CFB_SDSU: cfbSdsu,
	CFB_UW: cfbUw,
	CFB_MAR: cfbMar,
	CFB_PENNST: cfbPennst,
	CFB_NEV: cfbNev,
	CFB_FRES: cfbFres,
	CFB_HAW: cfbHaw,
	CFB_BSU: cfbBsu,
	CFB_KU: cfbKu,

	CFB_AZST: cfbAzst,
	CFB_OKST: cfbOkst,
	CFB_NCST: cfbNcst,
	CFB_MISS: cfbMiss,
	CFB_IOWA: cfbIowa,
	CFB_MICH: cfbMich,
	CFB_USC: cfbUsc,
	CFB_TTU: cfbTtu,
	CFB_TAMU: cfbTamu,
	CFB_ARK: cfbArk,
	CFB_ORST: cfbOrst,
	CFB_BAY: cfbBay,
	CFB_ISU: cfbIsu,
	CFB_WFU: cfbWfu,
	CFB_VIR: cfbVir,
	CFB_SYR: cfbSyr,
	CFB_NEB: cfbNeb,
	CFB_NWW: cfbNww,
	CFB_ND: cfbNd,
	CFB_GEO: cfbGeo,
	CFB_PIT: cfbPit,
	CFB_WVU: cfbWvu,
	CFB_TCU: cfbTcu,
	CFB_CU: cfbCu,
	CFB_OSU: cfbOsu,
	CFB_ORE: cfbOre,
	CFB_WMU: cfbWmu,
	CFB_MSU: cfbMsu,
	CFB_CLEM: cfbClem,
	CFB_FSU: cfbFsu,
	CFB_GT: cfbGt,
	CFB_LSU: cfbLsu,
	CFB_UF: cfbUf,
	CFB_UTAH: cfbUtah,
	CFB_ALA: cfbAla,
	CFB_LOU: cfbLou,
	CFB_TENN: cfbTenn,
	CFB_TEX: cfbTex,
	CFB_UCF: cfbUcf,
	CFB_UK: cfbUk,
	CFB_OSU_MINUS_31: cfbOsuMinus31,
	CFB_TOL_PLUS_31: cfbTolPlus31,
	CFB_BYU: cfbByu,
	CFB_SCAR_PLUS_24_5: cfbScarPlus24,
	CFB_GEO_MINUS_24_5: cfbGeoMinus24,
	CFB_CJ_STROUND_OVER_328_5_PASS_YDS: cfbO3285,
	CFB_CJ_STROUND_UNDER_328_5_PASS_YDS: cfbU3285,

	MLB_AL: mlbAl,
	MLB_NL: mlbNl,
	MLB_OHTANI: mlbOhtani,
	MLB_OTHERS: mlbOthers,
	MLB_ALONSO: mlbAlonso,

	MLB_ARZ: mlbArz,
	MLB_PIT: mlbPit,
	MLB_ATL: mlbAtl,
	MLB_BAL: mlbBal,
	MLB_BOS: mlbBos,
	MLB_CWS: mlbCws,
	MLB_CIN: mlbCin,
	MLB_CLE: mlbCle,
	MLB_COL: mlbCol,
	MLB_DET: mlbDet,
	MLB_HOU: mlbHou,
	MLB_KAN: mlbKan,
	MLB_LAA: mlbLaa,
	MLB_LAD: mlbLad,
	MLB_MIA: mlbMia,
	MLB_MIL: mlbMil,
	MLB_MIN: mlbMin,
	MLB_NYM: mlbNym,
	MLB_NYY: mlbNyy,
	MLB_OAK: mlbOak,
	MLB_PHI: mlbPhi,
	MLB_SDP: mlbSdp,
	MLB_SFG: mlbSfg,
	MLB_SEA: mlbSea,
	MLB_STL: mlbStl,
	MLB_TAM: mlbTam,
	MLB_TEX: mlbTex,
	MLB_TOR: mlbTor,
	MLB_WAS: mlbWas,

	CL_AJA: clAja,
	CL_ATM: clAtm,
	CL_B04: clB04,
	CL_BAY: clBay,
	CL_BRU: clBru,
	CL_CEL: clCel,
	CL_COP: clCop,
	CL_DOR: clDor,
	CL_DYN: clDyn,
	CL_DZA: clDza,
	CL_FCB: clFcb,
	CL_HAI: clHai,
	CL_INT: clInt,
	CL_JUV: clJuv,
	CL_LIV: clLiv,
	CL_MAD: clMad,
	CL_MCI: clMci,
	CL_MIL: clMil,
	CL_NAP: clNap,
	CL_OLM: clOlm,
	CL_POR: clPor,
	CL_PSG: clPsg,
	CL_PSV: clPsv,
	CL_RBL: clRbl,
	CL_RBS: clRbs,
	CL_RFC: clRfc,
	CL_SEV: clSev,
	CL_SGE: clSge,
	CL_SHK: clShk,
	CL_SLB: clSlb,
	CL_SLI: clSli,
	CL_TOT: clTot,
	CL_VPL: clVpl,
	CL_CHE: clChe,
	CL_HARRY_KANE_GOAL_NO: no,
	CL_HARRY_KANE_GOAL_YES: yes,
	CL_ROBERT_LEWANDOWSKI_GOAL_NO: no,
	CL_ROBERT_LEWANDOWSKI_GOAL_YES: yes,

	Soccer_VILLA: soccerVilla,
	Soccer_RVA: soccerRva,
	UEL_ARS: eplArs,
	EPL_ARS: eplArs,
	EPL_AVL: eplAvl,
	EPL_BOU: eplBou,
	EPL_BRE: eplBre,
	EPL_BRI: eplBri,
	EPL_CHE: eplChe,
	EPL_CRY: eplCry,
	EPL_EVE: eplEve,
	EPL_FUL: eplFul,
	EPL_LEE: eplLee,
	EPL_LEI: eplLei,
	EPL_LIV: eplLiv,
	EPL_MCI: eplMci,
	EPL_MUN: eplMun,
	EPL_NEW: eplNew,
	EPL_NOT: eplNot,
	EPL_SOU: eplSou,
	EPL_TOT: eplTot,
	EPL_WHU: eplWhu,
	EPL_WOL: eplWol,


	NHL_CAR: carNhlLogo,
	NHL_NYR: nyrNhlLogo,
	NHL_EDM: edmNhlLogo,
	NHL_COL: colNhlLogo,
	NHL_TAM: tamNhlLogo,
	NHL_DAL: nhlDal,
	NHL_MON: nhlMon,
	NHL_TOR: nhlTor,
	NHL_LV: nhlLv,
	NHL_LAK: nhlLak,
	NHL_VAN: nhlVan,
	NHL_CHI: nhlChi,
	NHL_WAS: nhlWas,
	NHL_MIN: nhlMin,
	NHL_CAL: nhlCal,
	NHL_WIN: nhlWin,
	NHL_CLB: nhlClb,
	NHL_SJ: nhlSj,
	NHL_FL: nhlFl,
	NHL_SEA: nhlSea,
	NHL_ANA: nhlAna,
	NHL_BOS: nhlBos,
	NHL_BUF: nhlBuf,
	NHL_NASH: nhlNash,
	NHL_NJ: nhlNj,
	NHL_NYI: nhlNyi,
	NHL_OTT: nhlOtt,
	NHL_PHI: nhlPhi,
	NHL_STL: nhlStl,
	NHL_PITT: nhlPitt,

	USFL_BIR: usflBIR,
	USFL_PHI: usflPHI,

	MMA_KATTAR: kattarMmaLogo,
	MMA_EMMETT: emmettMmaLogo,
	MMA_TSARUKYAN: tsarukyanMmaLogo,
	MMA_GAMROT: gamrotMmaLogo,
	MMA_MAGNY: magnyMmaLogo,
	MMA_RAKHMONOV: rakhmonovMmaLogo,
	MMA_HOLLOWAY: hollowayMmaLogo,
	MMA_VOLKANOVSKI: volkanovskiMmaLogo,
	MMA_CANNONIER: cannonierMmaLogo,
	MMA_ADESANYA: adesanyaMmaLogo,
	MMA_RODRIGUEZ: mmaRodriguez,
	MMA_ORTEGA: mmaOrtega,
	MMA_ASPINALL: mmaAspinall,
	MMA_NUNES: mmaNunes,
	MMA_MORENO: mmaMoreno,
	MMA_SANTOS: mmaThiagoSantos,
	MMA_THIAGOSANTOS: mmaThiagoSantos,
	MMA_HILL: mmaJamahalHill,
	MMA_JAMAHALHILL: mmaJamahalHill,
	MMA_DAVID_ONAMA: mmaDavidOnama,
	MMA_NATE_LANDWEHR: mmaNateLandwehr,
	MMA_DOMINICK_CRUZ: mmaDominickCruz,
	MMA_MARLON_VERA: mmaMarlonVera,
	MMA_FRANCE: mmaFrance,
	MMA_PENA: mmaPena,
	MMA_BLAYDES: mmaBlaydes,
	MMA_LEONEDWARDS: mmaLeonEdwards,
	MMA_YANXIAONAN: mmaYanXiaonan,
	MMA_MACKENZIEDERN: mmaMackenzieDern,
	MMA_PAULOCOSTA: mmaPauloCosta,
	MMA_KAMARUUSMAN: mmaKamaruUsman,
	MMA_LUKEROCKHOLD: mmaLukeRockhold,
	MMA_KHAMZATCHIMAEV: mmaKhamzatChimaev,
	MMA_NATEDIAZ: mmaNateDiaz,
	MMA_LIJINGLIANG: mmaLiJingliang,
	MMA_KEVINHOLLAND: mmaKevinHolland,
	MMA_DANIELRODRIGUEZ: mmaDanielRodriguez,
	MMA_TONYFERGUSON: mmaTonyFerguson,
	MMA_SANDHAGEN: mmaCorySandhagen,
	MMA_SONG: mmaSongYadong,

	MMA_SEANOMALLEY: mmaSeanOMalley,
	MMA_PETRYAN: mmaPetrYan,
	MMA_TJDILLASHAW: mmaTJDillashaw,
	MMA_ALJAMAINSTERLING: mmaAljamainSterling,
	MMA_ISLAMMAKHACHEV: mmaIslamMakhachev,
	MMA_CHARLESOLIVEIRA: mmaCharlesOliveira,

	NBA_AJ_GRIFFIN: ajGriffinNbaLogo,
	NBA_BENNEDICT_MATHURIN: bennedictMathurinNbaLogo,
	NBA_CHET_HOLMGREN: chetHolmgrenNbaLogo,
	NBA_DYSON_DANIELS: dysonDanielsNbaLogo,
	NBA_JABARI_SMITH_JR: jabariSmithJrNbaLogo,
	NBA_JADEN_IVEY: jadenIveyNbaLogo,
	NBA_JALEN_DUREN: jalenDurenNbaLogo,
	NBA_JOHNNY_DAVIS: johnnyDavisNbaLogo,
	NBA_KEEGAN_MURRAY: keeganMurrayNbaLogo,
	NBA_PAOLO_BANCHERO: paoloBancheroNbaLogo,
	NBA_SHAEDON_SHARPE: shaedonSharpeNbaLogo,
	BIG3_BIVOUAC: big3Bivouac,
	BIG3_KILLER3S: big3Killer3s,
	BIG3_ALIENS: big3Aliens,

	SWAP: swap,
	TIE_TOKEN: tieToken,
	DRAW_TOKEN: drawToken,
	SWAP_ARROWS: swapArrows,
	MY_POSITIONS: myPositions,
	MY_TRADES: myTrades,
	MY_PAYOUTS: myPayouts,
	LEADERBOARD: leaderboard,
	GAMES: games,
	CLOCK: clock,
	VS_0: vsPurple,
	VS_1: vsGold,
	LOGO: logo,
	USDC: usdc,
	AVATAR: avatar,
	TWITTER: twitter,
	DISCORD: discord,
	DISCORD_WHITE: discordWhite,
	CONNECT_WALLET: connectWallet,
	WALLET_ICON: walletIcon,
	PBT: pbt,
	SOL: sol,
	mSOL: mSOL,
	USDH: usdh,
	DUST: dust,
	PROPS: props,
	YES: yes,
	UNDER: under,
	OVER: over,
	NO: no
}

export default images
