import React from 'react';
import images from '../static/images';
import { useHistory } from 'react-router';
import { IState } from '../store/interfaces/state';
import * as actions from '../store/actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { GamePoolWithDetail } from '../models/GamePoolWithDetail';

// shows each of your holdings
const MyPositionsPageNew = (props: any) => {

    const data: IState = useSelector((state: any) => state.data);
    const positions: any[] = [];
    const dispatch = useDispatch();
    const history = useHistory();
    const [ userName, setUserName ] = React.useState('');

	// get a list of the user's positions
    for (let i = 0; i < data.gamePools.length; i++) {
        const g: GamePoolWithDetail = data.gamePools[i];
        if (g.userAwayTokenBalance > 0) {
            positions.push({
                'token': `${g.awayTeam}.${g.sport}.${g.week}`
                , 'sport': g.sport
                , 'amount': g.userAwayTokenBalance
                , 'value': g.userAwayTokenBalance * ( g.awayTokenValue === null || g.awayTokenValue === undefined ? g.awayTokenPrice : g.awayTokenValue)
            })
        }
        if (g.userHomeTokenBalance > 0) {
            positions.push({
                'token': `${g.homeTeam}.${g.sport}.${g.week}`
                , 'sport': g.sport
                , 'amount': g.userHomeTokenBalance
                , 'value': g.userHomeTokenBalance * ( g.homeTokenValue === null || g.homeTokenValue === undefined ? g.homeTokenPrice : g.homeTokenValue)
            })
        }
    }

	// create a list to display
    const positionList = positions.sort((a, b) => b.value - a.value).map( (a: any, i: number) => {
        const team = a.token.split('.')[0].replaceAll('-', '_').toUpperCase();
        return(
            <tr key={i} className={`border-b border-th-bkg-3 bg-[rgba(255,255,255,0.03)] ${i % 2 ? 'odd' : 'even'}`}>
                <td className='px-4 py-3.5 whitespace-nowrap text-sm text-th-fgd-1'>
                    <img src={images[`${a.sport}_${team}`]} alt='team-img' className='token-img padding-right-sm' />
                    {a.token}
                </td>
                <td className='px-4 py-3.5 whitespace-nowrap text-sm text-th-fgd-1'>{a.amount.toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</td>
                <td className='px-4 py-3.5 whitespace-nowrap text-sm text-th-fgd-1'>${a.value.toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</td>
                <td style={{'width':'100px'}} className='px-4 py-3.5 no-padding text-sm text-th-fgd-1'>
                    <button className='props-button sell-button' onClick={() => {
                        dispatch(actions.setSwapFromToken(a.token));
                        history.push('/swap-2');
                    }}>
                        Sell
                    </button>
                </td>
            </tr>
        )
    })

	// some good ol' fashioned rendering!
	
    return(
        <div className="my-positions-page">
			<div className='row padding-1-0'>
				<div className='col-3'>
				</div>
				<div className='col-3'>
					<div className='row'>
						<div>
							<img src={images[`PBT`]}></img>
						</div>
						<div className='token-name'>Props Beta Token: {data.userPropsBalance.toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</div>
					</div>
				</div>
				<div className='col-3'>
					<div className='row'>
						<div>
							<img src={images[`PROPS`]}></img>
						</div>
						<div className='token-name'>Props Token: {data.userBonusBalance.toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})}</div>
					</div>
				</div>
				<div className='col-3'>
				</div>
			</div>
            <div className="my-positions-panel rounded-lg">
                <div className='pb-4 text-th-fgd-1 text-lg'>
                    My Positions
                </div>
                <table className='positions-table min-w-full divide-y divide-th-bkg-2'>
                    <thead>
                        <tr className='text-th-fgd-3 text-xs'>
                            <th className='px-4 pb-2 text-left font-normal'>Token</th>
                            <th className='px-4 pb-2 text-left font-normal'>Amount</th>
                            <th className='px-4 pb-2 text-left font-normal'>Value</th>
                            <th className='px-4 no-padding pb-2 text-left font-normal'></th>
                        </tr>
                    </thead>
                    <tbody>
                        { positionList }
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default MyPositionsPageNew;
