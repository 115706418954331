export const SET_ODDS = 'SET_ODDS';
export const SET_USER = 'SET_USER';
export const SET_SPORT = 'SET_SPORT';
export const SET_TRADES = 'SET_TRADES';
export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_GAME_POOLS = 'SET_GAME_POOLS';
export const SET_SWAP_OBJECT = 'SET_SWAP_OBJECT';
export const SET_LEADERBOARD = 'SET_LEADERBOARD';
export const SET_USER_TRADES = 'SET_USER_TRADES';
export const SET_USER_TOKENS = 'SET_USER_TOKENS';
export const SET_USER_PAYOUTS = 'SET_USER_PAYOUTS';
export const SET_SWAP_TO_TOKEN = 'SET_SWAP_TO_TOKEN';
export const SET_LOADING_POOLS = 'SET_LOADING_POOLS';
export const SET_SWAP_FROM_TOKEN = 'SET_SWAP_FROM_TOKEN';
export const SET_USER_PROPS_BALANCE = 'SET_USER_PROPS_BALANCE';
export const SET_USER_BONUS_BALANCE = 'SET_USER_BONUS_BALANCE';
