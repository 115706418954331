import './index.css';
import App from './App';
import React from 'react';
import thunk from 'redux-thunk'; 
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import rootReducer from './store/reducers';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import { createStore, applyMiddleware } from 'redux'; 

const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
        <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
