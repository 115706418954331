
import { PublicKey } from "@solana/web3.js";

// how much $PROPS to initialize the pool with

// how much $PROPS to initialize the pool with
export const START_POOL_PROPS_AMOUNT = 10000;

// the percent of each tx that is taken out as a fee
// Bet3 TODO: update FEE_PCT
export const FEE_PCT = 0.000;
// Bet3 TODO: update FEE_ADDRESS - this is the address that will be receiving fees for Bet3
// Make sure it has both a USDH and mSOL token account (basically fund the address with a small amount of these tokens)
// Otherwise it will not receive fees
export const FEE_ADDRESS = 'GFCYkPusxYWJ1yEoHNrFwjgLUmuCYiwC4nhwrV2UpMBk';
// export const FEE_PCT = 0.000;

export const FEE_DECIMALS = 4;
export const PRICE_MULT = 1 / (1 - FEE_PCT);


// Program ID of the Solana program
export const PROGRAM_ID: PublicKey = new PublicKey(
    'GrcZwT9hSByY1QrUTaRPp6zs5KxAA5QYuqEhjT1wihbm'
);

export const isDev = false;

// the url of the backend - can use local or the one deployed on heroku
// export const BACKEND_URL: string = 'http://localhost:5000';
export const BACKEND_URL: string = isDev ? 'http://localhost:5000' : 'https://solanaprops-client.herokuapp.com';

// mint address of props token
// export const NETWORK = 'https://api.devnet.solana.com';
// export const PROPS_MINT: PublicKey = new PublicKey(
//     "4ZbziJ8WAg1aTG2sTAMwRqqZAxHLPGnG9y7A8c3x4eyP"
// );

// // mint address of props token
// export const PBT_MINT: PublicKey = new PublicKey(
//     "9UPZjRRqygSKqEvdpTdPqFg51wNjRk2SpuKiew9c75r3"
// );

// mint address of props token
// export const NETWORK = 'https://api.mainnet-beta.solana.com';
export const SOL_ADDRESS: PublicKey = new PublicKey('So11111111111111111111111111111111111111112');
export const ADMIN_ADDRESSES: string[] = [
	'9VhsSZ6ni7dZtmKRHE81yAd3UQW1oKu9LNEWRGFMA5wj'
	, '6Ch8KyKpkZHKHNzEioLYg6b3w1WM8NMYg4pYnVsBU3pk'
];
export const NETWORK = isDev ? 'https://api.devnet.solana.com' : 'https://red-cool-wildflower.solana-mainnet.quiknode.pro/a1674d4ab875dd3f89b34863a86c0f1931f57090/';
// export const NETWORK = isDev ? 'https://api.devnet.solana.com' : 'https://api.mainnet-beta.solana.com';
export const PROPS_MINT: PublicKey = new PublicKey(
    isDev ? "4ZbziJ8WAg1aTG2sTAMwRqqZAxHLPGnG9y7A8c3x4eyP" : "FtPjwYdRjYxcELz3b7JgdcXhqgbWaVF2vvo9L7avJx5S"
);

// mint address of props token
export const PBT_MINT: PublicKey = new PublicKey(
    isDev ? "9UPZjRRqygSKqEvdpTdPqFg51wNjRk2SpuKiew9c75r3" : "GqV6UM2iWu1X6TZRyj39J3Bzf8ofZuG7owmfwA3cjUqe"
);

// mint address of props token
export const DUST_MINT: PublicKey = new PublicKey('DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ');
export const MSOL_MINT: PublicKey = new PublicKey('mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So');
export const USDH_MINT: PublicKey = new PublicKey('USDH1SM1ojwWUga67PGrgFWUHibbjqMvuMaDkRJTgkX');

export const MAX_TOKENS = 1000;

// the decimals of the props token
export const DECIMALS: number = 9;

// the signer of the props token
export const PROPS_SIGNER: PublicKey = new PublicKey(
    "EpEAXENq4xUN9maC2XByCvvvEafKAuFcmH75HkJeFSKY"
);

// the nonce of the props token
export const PROPS_NONCE: number = 255;


export const TOKEN_MAP: any = {
	'PBT': PBT_MINT.toString()
	, 'SOL': SOL_ADDRESS.toString()
	, 'DUST': DUST_MINT.toString()
	, 'mSOL': MSOL_MINT.toString()
	, 'USDH': USDH_MINT.toString()
}
TOKEN_MAP[`${MSOL_MINT.toString()}`] = MSOL_MINT.toString();
TOKEN_MAP[`${USDH_MINT.toString()}`] = USDH_MINT.toString();
TOKEN_MAP[`${PBT_MINT.toString()}`] = PBT_MINT.toString();

const map: any = {
	'SOL': 'SOL'
	, 'DUST': 'DUST'
	, 'mSOL': 'mSOL'
	, 'USDH': 'USDH'
}
map[`${PBT_MINT.toString()}`] = 'PBT';
map[`${SOL_ADDRESS.toString()}`] = 'SOL';
map[`${DUST_MINT.toString()}`] = 'DUST';
map[`${MSOL_MINT.toString()}`] = 'mSOL';
map[`${USDH_MINT.toString()}`] = 'USDH';

export const ADDRESS_TO_TOKEN_MAP = {...map};

export const isMobile = window.matchMedia('(max-width: 576px)').matches;
