import { ChevronDownIcon, BoltIcon } from "@heroicons/react/24/solid";
import { useState, useEffect, useRef, RefObject } from "react";
import { useOutsideAlerter } from '../../utils/OutsideAlerter';
import { setSport } from '../../store/actions/actions';
import { IState } from '../../store/interfaces/state';
import { useSelector } from 'react-redux';




type DropdownProps = {
    sportValue: string;
    setSportValue: Function;
    pools: any;
    setSportMobile: Function;
    props: any;
}

type PoolInfo = {
    sport: string;
    count: number;
}

const GamesDropDown = ({ sportValue, setSportValue, pools, setSportMobile, props }: DropdownProps) => {

    const [selected, setSelected] = useState(false)
    const [selectedText, setSelectedText] = useState("")
    const [options, setOptions] = useState([]);
    const data: IState = useSelector((state: any) => state.data)
    const currentLen = pools.filter((x: any) => x.sport===sportValue);

    const sportsList: any = [];
    const tempOptions: any = [];
    // options formatted like: [[NBA, 2], [MLB, 3], [NFL ,1]]
    useEffect(() => {
        for (let i=0; i < pools.length; i++) {
            sportsList.push(pools[i].sport);
        } 
        for (let k=0; k<sportsList.length; k++) {
            if (arrayFirstIndexHas(tempOptions, sportsList[k])!==-1) {
                const ind = arrayFirstIndexHas(tempOptions, sportsList[k]);
                tempOptions[ind][1] += 1;
            } else {
                tempOptions.push([sportsList[k], 1]);
            }
            setOptions(tempOptions);
        }
    }, [pools])

    function arrayFirstIndexHas(array: any, value: any) {
        for (let i=0; i<array.length; i++) {
            if (array[i][0]===value) return i;
        }
        return -1;
    }
    
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, setSelected);

    return <div className=" tw-relative " ref={wrapperRef}>
        <button onClick={() => setSelected(!selected)} className={`tw-w-full tw-whitespace-nowrap
         tw-bg-neutral  tw-bg-opacity-75 focus:tw-outline-none tw-border-base-200
         tw-border-2 tw-border-solid tw-p-2 rounded-md  tw-flex tw-flex-row tw-space-x-5  tw-px-5 items-center`} >
            <p className="tw-w-1/3 tw-font-bold">{sportValue}</p>
            <div className=" tw-flex tw-flex-row tw-items-center  tw-bg-base-300 tw-text-gray-400 tw-rounded-xl tw-p-1 tw-px-2">
                {pools.length > 2 ? <BoltIcon className="tw-h-4 tw-mr-1" /> : null}
                <p className="  tw-text-sm">{sportValue==="ALL" ? pools.length : (pools.filter((x: any) => x.sport===sportValue)).length} Pool{(sportValue==="ALL" ? pools.length : (pools.filter((x: any) => x.sport===sportValue)).length) > 1 ? "s" : ""}</p>
            </div>

            <ChevronDownIcon className={`tw-h-5 tw-transition-all tw-duration-500 ${!selected ? " tw-rotate-180" : " "}`} />   </button>
        {selected ?
            <div className="tw-absolute tw-flex tw-flex-col tw-text-white tw-bg-neutral tw-w-full tw-overflow-scroll tw-overflow-x-hidden tw-border-base-200 tw-z-50 tw-space-y-1  tw-border-b-2 tw-border-x-2 tw-border-solid tw-rounded-b-md tw-shadow-lg">
                <button onClick={() => {
                        props.setSportMobile("ALL");
                        setSportValue("ALL");
                        setSelected(false)
                    }} className="hover:tw-bg-base-200 tw-p-2 hover:tw-bg-opacity-20 tw-space-x-2 tw-flex tw-flex-row tw-items-center tw-px-5">
                        <p className="tw-w-1/3">{"ALL"}</p>
                        <div className=" tw-flex tw-flex-row tw-items-center tw-bg-base-300 tw-text-gray-400 tw-rounded-xl tw-p-1 tw-px-2">
                            {pools.length > 2 ? <BoltIcon className="tw-h-4 tw-mr-1" /> : null}
                            <p className="  tw-text-sm">{pools.length} Pool{pools.length > 1 ? "s" : ""}</p>
                        </div>
                    </button>
                {options.map((val: any) => {
                    return <button key={val} onClick={() => {
                        props.setSportMobile(val[0]);
                        setSportValue(val[0]);
                        setSelected(false)
                    }} className="hover:tw-bg-base-200 tw-p-2 hover:tw-bg-opacity-20 tw-space-x-2 tw-flex tw-flex-row tw-items-center tw-px-5">
                        <p className="w-1/3" key={val+1}>{val[0]}</p>
                        <div key={val+2} className=" tw-flex tw-flex-row tw-items-center tw-bg-base-300 tw-text-gray-400 tw-rounded-xl tw-p-1 tw-px-2">
                            {val[1] > 2 ? <BoltIcon key={val+3} className="tw-h-4 tw-mr-1" /> : null}
                            <p key={val+4} className="  tw-text-sm">{val[1]} Pool{val[1] > 1 ? "s" : ""}</p>
                        </div>

                    </button>
                })}
            </div> : null}


    </div>
}

export default GamesDropDown;