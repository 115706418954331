import { Keypair, PublicKey } from "@solana/web3.js";
import { web3, BN } from '@project-serum/anchor';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { FEE_DECIMALS, FEE_PCT } from "../constants";

// RPC call to buy or sell team tokens
export async function swapTeamTokensRpc(
    program: any,
    poolAccount: PublicKey,
    poolSigner: PublicKey,
    homePoolSigner: PublicKey,
    poolBonus: PublicKey,
    poolProps: PublicKey,
    homePoolProps: PublicKey,

    feePoolAccount: PublicKey,
    feePoolSigner: PublicKey,
    feePoolProps: PublicKey,

    propsMint: PublicKey,
    teamToken: PublicKey,
    homeTeamToken: PublicKey,
    awayTeamToken: PublicKey,

    userAuthority: PublicKey,
    userBonus: PublicKey,
    userProps: PublicKey,
    userTeamTokenAccount: PublicKey,

    numTokens: number,
    isBuy: boolean,
) {
    if (isBuy) {
        return await program.rpc.buyTeamTokens(
            new BN(numTokens),
            {
                accounts: {
                    poolAccount,
                    poolSigner,
                    homePoolSigner,
                    poolBonus,
                    poolProps,
                    homePoolProps,
    
                    feePoolAccount,
                    feePoolSigner,
                    feePoolProps,
    
                    propsMint,
                    teamToken,
                    homeTeamToken,
                    awayTeamToken,
    
                    userAuthority,
                    userBonus,
                    userProps,
                    userTeamTokenAccount,
    
                    tokenProgram: TOKEN_PROGRAM_ID,
                    clock: web3.SYSVAR_CLOCK_PUBKEY,
                },
                // signers: [poolAccount]
            }
        );
    }
    return await program.rpc.sellTeamTokens(
        new BN(numTokens),
        {
            accounts: {
                poolAccount,
                poolSigner,
                homePoolSigner,
                poolBonus,
                poolProps,
                homePoolProps,

                feePoolAccount,
                feePoolSigner,
                feePoolProps,

                propsMint,
                teamToken,
                homeTeamToken,
                awayTeamToken,

                userAuthority,
                userBonus,
                userProps,
                userTeamTokenAccount,

                tokenProgram: TOKEN_PROGRAM_ID,
                clock: web3.SYSVAR_CLOCK_PUBKEY,
            },
            // signers: [poolAccount]
        }
    );
}

// RPC call to buy or sell team tokens
export async function swapTeamTokensRpcNew(
    program: any,
    poolAccount: PublicKey,
    userAuthority: PublicKey,
    homePoolAccount: PublicKey,
    awayPoolAccount: PublicKey,

	teamToken: PublicKey,
    homeTeamToken: PublicKey,
    awayTeamToken: PublicKey,
	wagerToken: PublicKey,

    userTeamTokenAccount: PublicKey,

	homePoolWagerTokenAccount: PublicKey,
	awayPoolWagerTokenAccount: PublicKey,
	userWagerTokenAccount: PublicKey,
	feeReceiverWagerTokenAccount: PublicKey,
	feeReceiverAccount: PublicKey,

    numTokens: number,
    isBuy: boolean,
) {
	// console.log(`swapTeamTokensRpcNew 2`);
	// console.log(`poolAccount = ${poolAccount}`);
	// console.log(`userAuthority = ${userAuthority}`);
	// console.log(`homePoolAccount = ${homePoolAccount}`);
	// console.log(`awayPoolAccount = ${awayPoolAccount}`);
	// console.log(`teamToken = ${teamToken}`);
	// console.log(`homeTeamToken = ${homeTeamToken}`);
	// console.log(`awayTeamToken = ${awayTeamToken}`);
	// console.log(`wagerToken = ${wagerToken}`);
	// console.log(`userTeamTokenAccount = ${userTeamTokenAccount}`);
	// console.log(`homePoolWagerTokenAccount = ${homePoolWagerTokenAccount}`);
	// console.log(`awayPoolWagerTokenAccount = ${awayPoolWagerTokenAccount}`);
	// console.log(`userWagerTokenAccount = ${userWagerTokenAccount}`);
	// console.log(`feeReceiverWagerTokenAccount = ${feeReceiverWagerTokenAccount}`);
	// console.log(`numTokens = ${numTokens}`);
	
    if (isBuy) {
		try {
			console.log(`buyTeamTokensNew`);
			const a = await program.rpc.buyTeamTokensNew(
				new BN(numTokens),
				{
					accounts: {
						poolAccount,
						userAuthority,
						homePoolAccount,
						awayPoolAccount,
						teamToken,
						homeTeamToken,
						awayTeamToken,
						wagerToken,
						userTeamTokenAccount,
						homePoolWagerTokenAccount,
						awayPoolWagerTokenAccount,
						userWagerTokenAccount,
						feeReceiverWagerTokenAccount,
						feeReceiverAccount,
						userAccount: userAuthority,
		
						tokenProgram: TOKEN_PROGRAM_ID,
						clock: web3.SYSVAR_CLOCK_PUBKEY,
						systemProgram: web3.SystemProgram.programId,
					},
					signers: []
				}
			);
			console.log(`buyTeamTokensNew a:`);
			console.log(a);
			return(a);
		}
		catch (err) {
			console.log(`Error`);
			console.log(err);
			
		}
    } else {
		try {
			console.log(`sellTeamTokensNew`);
			const a = await program.rpc.sellTeamTokensNew(
				new BN(numTokens),
				{
					accounts: {
						poolAccount,
						userAuthority,
						homePoolAccount,
						awayPoolAccount,
						teamToken,
						homeTeamToken,
						awayTeamToken,
						wagerToken,
						userTeamTokenAccount,
						homePoolWagerTokenAccount,
						awayPoolWagerTokenAccount,
						userWagerTokenAccount,
						feeReceiverWagerTokenAccount,
						feeReceiverAccount,
						userAccount: userAuthority,
		
						tokenProgram: TOKEN_PROGRAM_ID,
						clock: web3.SYSVAR_CLOCK_PUBKEY,
						systemProgram: web3.SystemProgram.programId,
					},
					signers: []
				}
			);
			console.log(`a`);
			console.log(a);
			return(a);
		}
		catch (err) {
			console.log(`Error`);
			console.log(err);
			
		}
	}
}

// RPC call to buy or sell team tokens
export async function swapTeamTokensWithFeeRpc(
    program: any,
    poolAccount: PublicKey,
    userAuthority: PublicKey,
    homePoolAccount: PublicKey,
    awayPoolAccount: PublicKey,

	teamToken: PublicKey,
    homeTeamToken: PublicKey,
    awayTeamToken: PublicKey,
	wagerToken: PublicKey,

    userTeamTokenAccount: PublicKey,

	homePoolWagerTokenAccount: PublicKey,
	awayPoolWagerTokenAccount: PublicKey,
	userWagerTokenAccount: PublicKey,
	feeReceiverWagerTokenAccount: PublicKey,
	feeReceiverWagerTokenAccount2: PublicKey,
	feeReceiverAccount: PublicKey,
	feeReceiverAccount2: PublicKey,

    numTokens: number,
    isBuy: boolean,
    feeRate: number, // the fee rate * (10**4), so "200" means a 200 / 10,000 = 2% fee
    pctToFeeReciever1: number, // % of fee to go to Props * (10**4), so "5000" means Props gets 5,000 / 10,000 = 50%
) {
	// console.log(`swapTeamTokensWithFeeRpc`);
	// console.log(`poolAccount = ${poolAccount}`);
	// console.log(`userAuthority = ${userAuthority}`);
	// console.log(`homePoolAccount = ${homePoolAccount}`);
	// console.log(`awayPoolAccount = ${awayPoolAccount}`);
	// console.log(`teamToken = ${teamToken}`);
	// console.log(`homeTeamToken = ${homeTeamToken}`);
	// console.log(`awayTeamToken = ${awayTeamToken}`);
	// console.log(`wagerToken = ${wagerToken}`);
	// console.log(`userTeamTokenAccount = ${userTeamTokenAccount}`);
	// console.log(`homePoolWagerTokenAccount = ${homePoolWagerTokenAccount}`);
	// console.log(`awayPoolWagerTokenAccount = ${awayPoolWagerTokenAccount}`);
	// console.log(`userWagerTokenAccount = ${userWagerTokenAccount}`);
	// console.log(`feeReceiverWagerTokenAccount = ${feeReceiverWagerTokenAccount}`);
	// console.log(`numTokens = ${numTokens}`);
	// console.log(`feeRate = ${feeRate}`);
	// console.log(`pctToFeeReciever1 = ${pctToFeeReciever1}`);

	if (isBuy) {
		try {
			console.log(`buyTeamTokensWithFee`);
			// convert the decimals

			const a = await program.rpc.buyTeamTokensWithFee(
				new BN(numTokens),
				new BN(feeRate),
				new BN(pctToFeeReciever1),
				{
					accounts: {
						poolAccount,
						userAuthority,
						homePoolAccount,
						awayPoolAccount,
						teamToken,
						homeTeamToken,
						awayTeamToken,
						wagerToken,
						userTeamTokenAccount,
						homePoolWagerTokenAccount,
						awayPoolWagerTokenAccount,
						userWagerTokenAccount,
						feeReceiverWagerTokenAccount,
						feeReceiverAccount,
						feeReceiverWagerTokenAccount2,
						feeReceiverAccount2,
						userAccount: userAuthority,
		
						tokenProgram: TOKEN_PROGRAM_ID,
						clock: web3.SYSVAR_CLOCK_PUBKEY,
						systemProgram: web3.SystemProgram.programId,
					},
					signers: []
				}
			);
			console.log(`buyTeamTokensWithFee a:`);
			console.log(a);
			return(a);
		}
		catch (err) {
			console.log(`Error`);
			console.log(err);
			
		}
    } else {
		try {
			console.log(`sellTeamTokensWithFee`);
			const a = await program.rpc.sellTeamTokensWithFee(
				new BN(numTokens),
				new BN(feeRate),
				new BN(pctToFeeReciever1),
				{
					accounts: {
						poolAccount,
						userAuthority,
						homePoolAccount,
						awayPoolAccount,
						teamToken,
						homeTeamToken,
						awayTeamToken,
						wagerToken,
						userTeamTokenAccount,
						homePoolWagerTokenAccount,
						awayPoolWagerTokenAccount,
						userWagerTokenAccount,
						feeReceiverWagerTokenAccount,
						feeReceiverAccount,
						feeReceiverWagerTokenAccount2,
						feeReceiverAccount2,
						userAccount: userAuthority,
		
						tokenProgram: TOKEN_PROGRAM_ID,
						clock: web3.SYSVAR_CLOCK_PUBKEY,
						systemProgram: web3.SystemProgram.programId,
					},
					signers: []
				}
			);
			console.log(`sellTeamTokensWithFee a:`);
			console.log(a);
			return(a);
		}
		catch (err) {
			console.log(`Error`);
			console.log(err);
			
		}
	}
}


// RPC call to buy or sell team tokens
export async function swapTeamTokensTiePoolRpc(
    program: any,
    poolAccount: PublicKey,
    userAuthority: PublicKey,
    homePoolAccount: PublicKey,
    awayPoolAccount: PublicKey,
    tiePoolAccount: PublicKey,

	teamToken: PublicKey,
    homeTeamToken: PublicKey,
    awayTeamToken: PublicKey,
    tieTeamToken: PublicKey,
	wagerToken: PublicKey,

    userTeamTokenAccount: PublicKey,

	homePoolWagerTokenAccount: PublicKey,
	awayPoolWagerTokenAccount: PublicKey,
	tiePoolWagerTokenAccount: PublicKey,
	userWagerTokenAccount: PublicKey,
	feeReceiverWagerTokenAccount: PublicKey,
	feeReceiverAccount: PublicKey,

    numTokens: number,
    isBuy: boolean,
) {
	// console.log(`swapTeamTokensTiePoolRpc`);
	// console.log(`poolAccount = ${poolAccount.toString()}`);
	// console.log(`userAuthority = ${userAuthority.toString()}`);
	// console.log(`homePoolAccount = ${homePoolAccount.toString()}`);
	// console.log(`awayPoolAccount = ${awayPoolAccount.toString()}`);
	// console.log(`teamToken = ${teamToken.toString()}`);
	// console.log(`homeTeamToken = ${homeTeamToken.toString()}`);
	// console.log(`awayTeamToken = ${awayTeamToken.toString()}`);
	// console.log(`wagerToken = ${wagerToken.toString()}`);
	// console.log(`userTeamTokenAccount = ${userTeamTokenAccount.toString()}`);
	// console.log(`homePoolWagerTokenAccount = ${homePoolWagerTokenAccount.toString()}`);
	// console.log(`awayPoolWagerTokenAccount = ${awayPoolWagerTokenAccount.toString()}`);
	// console.log(`userWagerTokenAccount = ${userWagerTokenAccount.toString()}`);
	// console.log(`feeReceiverWagerTokenAccount = ${feeReceiverWagerTokenAccount.toString()}`);
	// console.log(`numTokens = ${numTokens}`);
	
    if (isBuy) {
		try {
			console.log(`buyTeamTokensTiePool`);
			console.log({
				'numTokens': numTokens
				, 'poolAccount': poolAccount
				, 'userAuthority': userAuthority
				, 'homePoolAccount': homePoolAccount
				, 'awayPoolAccount': awayPoolAccount
				, 'tiePoolAccount': tiePoolAccount
				, 'teamToken': teamToken
				, 'homeTeamToken': homeTeamToken
				, 'awayTeamToken': awayTeamToken
				, 'tieTeamToken': tieTeamToken
				, 'wagerToken': wagerToken
				, 'userTeamTokenAccount': userTeamTokenAccount
				, 'homePoolWagerTokenAccount': homePoolWagerTokenAccount
				, 'awayPoolWagerTokenAccount': awayPoolWagerTokenAccount
				, 'tiePoolWagerTokenAccount': tiePoolWagerTokenAccount
				, 'userWagerTokenAccount': userWagerTokenAccount
				, 'feeReceiverWagerTokenAccount': feeReceiverWagerTokenAccount
				, 'feeReceiverAccount': feeReceiverAccount
			});
			
			
			const a = await program.rpc.buyTeamTokensTiePool(
				new BN(numTokens),
				{
					accounts: {
						poolAccount,
						userAuthority,
						homePoolAccount,
						awayPoolAccount,
						tiePoolAccount,
						teamToken,
						homeTeamToken,
						awayTeamToken,
						tieTeamToken,
						wagerToken,
						userTeamTokenAccount,
						homePoolWagerTokenAccount,
						awayPoolWagerTokenAccount,
						tiePoolWagerTokenAccount,
						userWagerTokenAccount,
						feeReceiverWagerTokenAccount,
						feeReceiverAccount,
						userAccount: userAuthority,
		
						tokenProgram: TOKEN_PROGRAM_ID,
						clock: web3.SYSVAR_CLOCK_PUBKEY,
						systemProgram: web3.SystemProgram.programId,
					},
					signers: []
				}
			);
			console.log(`a`);
			console.log(a);
			return(a);
		}
		catch (err) {
			console.log(`Error`);
			console.log(err);
			
		}
    } else {
		try {
			const a = await program.rpc.sellTeamTokensTiePool(
				new BN(numTokens),
				{
					accounts: {
						poolAccount,
						userAuthority,
						homePoolAccount,
						awayPoolAccount,
						tiePoolAccount,
						teamToken,
						homeTeamToken,
						awayTeamToken,
						tieTeamToken,
						wagerToken,
						userTeamTokenAccount,
						homePoolWagerTokenAccount,
						awayPoolWagerTokenAccount,
						tiePoolWagerTokenAccount,
						userWagerTokenAccount,
						feeReceiverWagerTokenAccount,
						feeReceiverAccount,
						userAccount: userAuthority,
		
						tokenProgram: TOKEN_PROGRAM_ID,
						clock: web3.SYSVAR_CLOCK_PUBKEY,
						systemProgram: web3.SystemProgram.programId,
					},
					signers: []
				}
			);
			console.log(`a`);
			console.log(a);
			return(a);
		}
		catch (err) {
			console.log(`Error`);
			console.log(err);
			
		}
	}
}


// RPC call to buy or sell team tokens
export async function swapTeamTokensTiePoolWithFeeRpc(
    program: any,
    poolAccount: PublicKey,
    userAuthority: PublicKey,
    homePoolAccount: PublicKey,
    awayPoolAccount: PublicKey,
    tiePoolAccount: PublicKey,

	teamToken: PublicKey,
    homeTeamToken: PublicKey,
    awayTeamToken: PublicKey,
    tieTeamToken: PublicKey,
	wagerToken: PublicKey,

    userTeamTokenAccount: PublicKey,

	homePoolWagerTokenAccount: PublicKey,
	awayPoolWagerTokenAccount: PublicKey,
	tiePoolWagerTokenAccount: PublicKey,
	userWagerTokenAccount: PublicKey,
	feeReceiverWagerTokenAccount: PublicKey,
	feeReceiverWagerTokenAccount2: PublicKey,
	feeReceiverAccount: PublicKey,
	feeReceiverAccount2: PublicKey,

    numTokens: number,
    isBuy: boolean,
    feeRate: number, // the fee rate * (10**4), so "200" means a 200 / 10,000 = 2% fee
    pctToFeeReciever1: number, // % of fee to go to Props * (10**4), so "5000" means Props gets 5,000 / 10,000 = 50%
) {
	// console.log(`swapTeamTokensTiePoolRpc`);
	// console.log(`poolAccount = ${poolAccount.toString()}`);
	// console.log(`userAuthority = ${userAuthority.toString()}`);
	// console.log(`homePoolAccount = ${homePoolAccount.toString()}`);
	// console.log(`awayPoolAccount = ${awayPoolAccount.toString()}`);
	// console.log(`teamToken = ${teamToken.toString()}`);
	// console.log(`homeTeamToken = ${homeTeamToken.toString()}`);
	// console.log(`awayTeamToken = ${awayTeamToken.toString()}`);
	// console.log(`wagerToken = ${wagerToken.toString()}`);
	// console.log(`userTeamTokenAccount = ${userTeamTokenAccount.toString()}`);
	// console.log(`homePoolWagerTokenAccount = ${homePoolWagerTokenAccount.toString()}`);
	// console.log(`awayPoolWagerTokenAccount = ${awayPoolWagerTokenAccount.toString()}`);
	// console.log(`userWagerTokenAccount = ${userWagerTokenAccount.toString()}`);
	// console.log(`feeReceiverWagerTokenAccount = ${feeReceiverWagerTokenAccount.toString()}`);
	// console.log(`numTokens = ${numTokens}`);
	// console.log(`feeReceiverWagerTokenAccount2 = ${feeReceiverWagerTokenAccount2}`);
	// console.log(`feeReceiverAccount2 = ${feeReceiverAccount2}`);
	
    if (isBuy) {
		try {
			console.log(`buyTeamTokensTiePool`);
			console.log({
				'numTokens': numTokens
				, 'poolAccount': poolAccount
				, 'userAuthority': userAuthority
				, 'homePoolAccount': homePoolAccount
				, 'awayPoolAccount': awayPoolAccount
				, 'tiePoolAccount': tiePoolAccount
				, 'teamToken': teamToken
				, 'homeTeamToken': homeTeamToken
				, 'awayTeamToken': awayTeamToken
				, 'tieTeamToken': tieTeamToken
				, 'wagerToken': wagerToken
				, 'userTeamTokenAccount': userTeamTokenAccount
				, 'homePoolWagerTokenAccount': homePoolWagerTokenAccount
				, 'awayPoolWagerTokenAccount': awayPoolWagerTokenAccount
				, 'tiePoolWagerTokenAccount': tiePoolWagerTokenAccount
				, 'userWagerTokenAccount': userWagerTokenAccount
				, 'feeReceiverWagerTokenAccount': feeReceiverWagerTokenAccount
				, 'feeReceiverAccount': feeReceiverAccount
				, 'feeReceiverWagerTokenAccount2': feeReceiverWagerTokenAccount2
				, 'feeReceiverAccount2': feeReceiverAccount2
			});
			
			
			const a = await program.rpc.buyTeamTokensTiePoolWithFee(
				new BN(numTokens),
				new BN(feeRate),
				new BN(pctToFeeReciever1),
				{
					accounts: {
						poolAccount,
						userAuthority,
						homePoolAccount,
						awayPoolAccount,
						tiePoolAccount,
						teamToken,
						homeTeamToken,
						awayTeamToken,
						tieTeamToken,
						wagerToken,
						userTeamTokenAccount,
						homePoolWagerTokenAccount,
						awayPoolWagerTokenAccount,
						tiePoolWagerTokenAccount,
						userWagerTokenAccount,
						feeReceiverWagerTokenAccount,
						feeReceiverWagerTokenAccount2,
						feeReceiverAccount,
						feeReceiverAccount2,
						userAccount: userAuthority,
		
						tokenProgram: TOKEN_PROGRAM_ID,
						clock: web3.SYSVAR_CLOCK_PUBKEY,
						systemProgram: web3.SystemProgram.programId,
					},
					signers: []
				}
			);
			console.log(`a`);
			console.log(a);
			return(a);
		}
		catch (err) {
			console.log(`Error`);
			console.log(err);
			
		}
    } else {
		try {
			const a = await program.rpc.sellTeamTokensTiePoolWithFee(
				new BN(numTokens),
				{
					accounts: {
						poolAccount,
						userAuthority,
						homePoolAccount,
						awayPoolAccount,
						tiePoolAccount,
						teamToken,
						homeTeamToken,
						awayTeamToken,
						tieTeamToken,
						wagerToken,
						userTeamTokenAccount,
						homePoolWagerTokenAccount,
						awayPoolWagerTokenAccount,
						tiePoolWagerTokenAccount,
						userWagerTokenAccount,
						feeReceiverWagerTokenAccount,
						feeReceiverWagerTokenAccount2,
						feeReceiverAccount,
						feeReceiverAccount2,
						userAccount: userAuthority,
		
						tokenProgram: TOKEN_PROGRAM_ID,
						clock: web3.SYSVAR_CLOCK_PUBKEY,
						systemProgram: web3.SystemProgram.programId,
					},
					signers: []
				}
			);
			console.log(`a`);
			console.log(a);
			return(a);
		}
		catch (err) {
			console.log(`Error`);
			console.log(err);
			
		}
	}
}
