import axios from 'axios';
import { BACKEND_URL, DECIMALS, PROGRAM_ID, PROPS_MINT, START_POOL_PROPS_AMOUNT } from '../constants';
import fetch from "node-fetch";
import { Game } from '../models/Game';
import { useWallet } from '@solana/wallet-adapter-react';

export const updateUser = async (
	address: string
	, userName: string
	, userAvatarUrl: string
	, userOdds: string
) => {
	console.log(`updateUser`);
	

	const user = {
		'address': address
		, 'userName': userName
		, 'userAvatarUrl': userAvatarUrl
		, 'userOdds': userOdds
	}


	let response = await axios({
		method: 'post',
		url: BACKEND_URL+'/api/user',
		data: user
	});
	console.log(`response`);
	console.log(response);
	

}
