
import React from 'react';
import idl from '../idl.json';
import GamePoolRow from './GamePoolRow';
import { useSelector } from 'react-redux';
import { IState } from '../store/interfaces/state';
import { getCurrentTimestamp } from '../utils/utils';

// the container for all the GamePoolRows
export const GamePoolListNew = (props: any) => {
	console.log(`GamePoolListNew`);
	
    
    const data: IState = useSelector((state: any) => state.data);
    const address = data.address ? data.address : '';

	const now = getCurrentTimestamp();
	console.log(data.gamePools);
    // const pools = data.gamePools.filter( x => x.gameStartTime > now ).sort( (a, b) => a.gameStartTime == b.gameStartTime ? ( a.week == b.week ? (b.homeTokenPrice - a.homeTokenPrice) : a.week > b.week ? 1 : -1) : a.gameStartTime - b.gameStartTime );
    const pools = data.gamePools.filter( x => x.gameStartTime > now ).sort( (a, b) => a.gameStartTime == b.gameStartTime ? ( a.week == b.week ? (b.homeTokenPrice - a.homeTokenPrice) : a.week > b.week ? 1 : -1) : a.gameStartTime - b.gameStartTime ).map( (x, i) => <GamePoolRow address={address} key={i} idl={idl} gamePool={x} /> );
	console.log(`pools`);
	console.log(pools);
	
	
    return(
        <div className="row">
            { pools }
        </div>
    )
};

export default React.memo(GamePoolListNew);
