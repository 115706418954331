import { GamePoolWithDetail } from '../../models/GamePoolWithDetail';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ClockIcon, ExclamationCircleIcon } from "@heroicons/react/24/solid";
//import Router from 'next/router'
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom';
import LogoWrapper from "../../utils/LogoWrapper";
// import { useOddsStore } from "../../store/odds";
import OddsDisplay from "./OddsDisplay";
// import images from "../../util/images"
import { getTeamImg, convertToTokenName } from '../../utils/utils';
import { TokenObject } from '../../models/TokenObject';
import { Outcome } from '../../models/Outcome';
import { SwapObject } from '../../models/SwapObject';
import { setSwapObject } from '../../store/actions/actions';
import images from '../../static/images';
import { IState } from '../../store/interfaces/state';
import { ADDRESS_TO_TOKEN_MAP, MSOL_MINT, SOL_ADDRESS, USDH_MINT } from '../../constants';
import * as actions from '../../store/actions/actions';
import '../../App.css';

type PoolCardProps = {
    pool: GamePoolWithDetail;
    odds: any;
}


const getOrdinalNum = (number: number) => {
    let selector;

    if (number <= 0) {
        selector = 4;
    } else if ((number > 3 && number < 21) || number % 10 > 3) {
        selector = 0;
    } else {
        selector = number % 10;
    }

    return number + ['th', 'st', 'nd', 'rd', ''][selector];
};

const getFormattedTime = (millis: number) => {

    let curDate = new Date(millis * 1000)
    var month = curDate.toLocaleString('default', { month: 'short' })
    var day = getOrdinalNum(curDate.getDate());

    let dayLong = curDate.toLocaleString(
        'default', { weekday: 'short' }
    ) + ",";
    let time = curDate.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
    })

    let dateString = [dayLong, day, month, time].join(' ')

    return dateString
}

const getTokenLogo = (s: string) => {
    if (s.toLowerCase().includes("msol")) {
        return "../assets/mSol.png"
    } else if (s.toLowerCase().includes("usdh")) {
        return "../assets/usdh.png"
    }
    return "../assets/sol.png"
}
// This function renames the bet button to something more appropriate
const checkBetButton = (name: string) => {

    if (name.includes("Yes") || name.includes("Over")) {
        if (name.includes("Over")) {
            return "Over"
        } else {
            return "Yes"
        }
    }

    if (name.includes("No") || name.includes("Under")) {
        if (name.includes("Over") || name.includes("Under")) {
            return "Under"
        } else {
            return "No"
        }
    }

    return name
}

const PoolCard = ({ pool, odds }: PoolCardProps) => {

    const data: IState = useSelector((state: any) => state.data);

    const dispatch = useDispatch();
    
    const g = pool;

    const homeSrc = images[getTeamImg(g.sport, g.homeTeam)];
    const awaySrc = images[getTeamImg(g.sport, g.awayTeam)];

    const isMsol = g.wagerToken == MSOL_MINT.toString();
    const isUsdh = g.wagerToken == USDH_MINT.toString();

    return <div className="tw-bg-base-300 tw-flex tw-flex-col tw-justify-between tw-text-white tw-p-3 tw-w-full tw-max-w-[500px] tw-m-1 tw-rounded-lg tw-space-y-5">
        {/* Top Row */}
        <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
            <p className="tw-font-title tw-text-2xl">{pool.sport}</p>

            <div className="tw-flex tw-flex-row tw-space-x-1 md:tw-space-x-3">
                <div className="tw-flex tw-space-x-1 md:tw-space-x-2 tw-flex-row tw-items-center tw-bg-base-400 tw-p-2 tw-rounded-xl">
                    <ClockIcon className="tw-h-5" />
                    <p className="tw-text-sm md:tw-text-base tw-whitespace-nowrap">{getFormattedTime(pool.gameStartTime)}</p>
                </div>
                <div className="tw-bg-base-400 tw-p-1 tw-rounded-xl">
                    {
                        isMsol
                        ? <img className='tw-h-8' src={images['mSOL']} />
                        : isUsdh ? <img className='tw-h-8' src={images['USDH']} />
                        : <img className='tw-h-8' src={images['SOL']} />
					}
                </div>
            </div>

        </div>
        {/* Middle Row */}
        <div className="tw-bg-base-400 tw-p-2 tw-rounded-xl tw-h-full tw-flex tw-flex-row ">
            {/* Percent Bet / awway team info  */}
            <div className="tw-w-2/5 tw-text-left tw-flex tw-flex-col tw-space-y-3 tw-justify-between">

                <div className="  tw-w-full tw-flex tw-justify-center">
                    <img src={awaySrc} style={{height:`75px`}}></img>
                </div>
                
                <OddsDisplay price={pool.awayTokenPrice} type="away" odds={data.odds} />

                <p className="tw-text-lg">{pool.awayTeamName}</p>
                <p className="tw-text-sm tw-text-gray-400">${pool && pool.awayTokenPrice ? (pool.awayTokenPrice).toFixed(2) : 0} to win $1.00</p>
            </div>
            {/* VS with Images - may move images to have text */}
            <div className="tw-w-1/5 tw-text-center tw-flex tw-flex-row tw-space-x-2 tw-items-center tw-justify-center">


                <p className="tw-font-title tw-text-2xl">VS</p>

            </div>
            <div className="tw-w-2/5 tw-text-right tw-flex tw-flex-col tw-justify-between">

                <div className="  tw-w-full tw-flex tw-justify-center">
                    <img src={homeSrc} style={{height:`75px`}}></img>
                </div>

                <OddsDisplay price={pool.homeTokenPrice} type="home" odds={data.odds} />


                <p className="tw-text-lg">{pool.homeTeamName}</p>
                <p className="tw-text-sm tw-text-gray-400">${pool && pool.homeTokenPrice ? (pool.homeTokenPrice).toFixed(2) : 0} to win $1.00</p>
            </div>

        </div>

        {/* Button Row */}
        <div className="tw-flex tw-flex-row tw-items-center tw-space-x-1">
            <div className="tw-w-2/5 tw-h-full">
                <NavLink
                    exact
                    to="/trade"
                    activeClassName="selected"
                >
                    <button
                        onClick={() => {
                            const tokenName = convertToTokenName(g.awayTeam, g.week, g.sport);
                            const t = new TokenObject( tokenName, false, g, Outcome.Away, g.awayTeam, g.wagerToken, g.userAwayTokenBalance );
                            const s = new SwapObject(g, Outcome.Away, true, [t], t);
                            dispatch(actions.setSwapObject(s));
                        }}
                        className="tw-w-full tw-h-full tw-bg-gray-500 tw-text-gray-300  tw-px-1 tw-py-2 tw-rounded-lg tw-font-bold"
                    >Bet {checkBetButton(pool.awayTeam)}</button>
                </NavLink>
            </div>
            <div className="tw-w-1/5 tw-h-full">
                {pool.tiePoolAccount !== "" ? 
                <NavLink
                    exact
                    to="/trade"
                >
                    <button
                        onClick={() => {
                            const tokenName = convertToTokenName(`DRAW.${g.awayTeam}-${g.homeTeam}`, g.week, g.sport);
                            const t = new TokenObject( tokenName, false, g, Outcome.Tie, tokenName, g.wagerToken, g.userTieTokenBalance );
                            const s = new SwapObject(g, Outcome.Tie, true, [t], t);
                            dispatch(actions.setSwapObject(s));
                        }}
                        className="tw-w-full tw-h-full tw-bg-gradient-to-b  tw-from-gray-400 tw-to-gray-600 tw-px-1 tw-py-2 tw-rounded-lg tw-font-bold"
                    >Bet Draw</button></NavLink> : null}

            </div>

            <div className="tw-w-2/5 tw-h-full">
                <NavLink
                    exact
                    to="/trade"
                    activeClassName="selected"
				>
                    <button
                        onClick={() => {
                            const tokenName = convertToTokenName(g.homeTeam, g.week, g.sport);
                            const t = new TokenObject( tokenName, false, g, Outcome.Home, g.homeTeam, g.wagerToken, g.userHomeTokenBalance );
                            const s = new SwapObject(g, Outcome.Home, true, [t], t);
                            dispatch(actions.setSwapObject(s));
                        }}
                        className="tw-w-full tw-h-full tw-bg-gray-300 tw-text-gray-500 tw-px-1 tw-py-2 tw-rounded-lg tw-font-bold"
                    >Bet {checkBetButton(pool.homeTeam)}</button></NavLink>
            </div>
        </div>

    </div>

}

export default PoolCard;