import React ,{useState} from 'react';
import AWS from 'aws-sdk'

import { useWallet } from '@solana/wallet-adapter-react';
import { uploadFile } from 'react-s3';
import * as anchor from '@project-serum/anchor';
import { Connection, ConnectionConfig } from '@solana/web3.js';
import { NETWORK } from '../constants';
import { Wallet } from 'easy-spl';

const S3_BUCKET = 'props-avatars';
const REGION = 'us-east-1';


AWS.config.update({
    accessKeyId: 'AKIAULSMK74QTRRSAAPZ',
    secretAccessKey: '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'
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
})

const UploadImageToS3WithNativeSdk = () => {

    const [progress , setProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);

	const walletContext: any = useWallet();

    const handleFileInput = (e: any) => {
        setSelectedFile(e.target.files[0]);
    }

    const uploadFile = (file: any) => {

		// const walletContext: any = useWallet();
		const config: ConnectionConfig = {
			commitment: 'confirmed',
			disableRetryOnRateLimit: false,
			confirmTransactionInitialTimeout: 150000
		};
		const connection = new Connection(NETWORK, config);
		const provider = new anchor.Provider(connection, walletContext, config);
		const userAccount: Wallet = new Wallet(connection, provider.wallet);

		const params = {
            ACL: 'public-read',
            Body: file,
            Bucket: S3_BUCKET,
            // Key: 'test.png'
            Key: `${userAccount.publicKey.toString()}.png`
        };

        myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
				console.log(`${Math.round((evt.loaded / evt.total) * 100)}`);
				console.log(evt);
                setProgress(Math.round((evt.loaded / evt.total) * 100))
            })
            .send((err) => {
                if (err) console.log(err)
            })
    }


    return <div>
        <div>Native SDK File Upload Progress is {progress}%</div>
        <input type="file" onChange={handleFileInput}/>
        <button onClick={() => uploadFile(selectedFile)}> Upload to S3</button>
    </div>
}

export default UploadImageToS3WithNativeSdk;